import { isPresent } from "utils/helpers/array";

export const errorHandler = (response, action = "", additionalTitle = "") => {
  let message = "";

  if (response?.status === 500)
    message = {
      title: "Sorry!",
      body: "We ran into an error, please refresh the page and try your request again. If you need any assistance please start a live chat or email us at help@hungerhub.com",
    };
  else {
    message = response?.data
      ? {
          title: additionalTitle,
          body: response?.data?.message
            ? response?.data.message
            : parseErrors(
                response?.data && response?.data?.errors
                  ? Array.isArray(response.data.errors)
                    ? response?.data?.errors?.filter(
                        (error) => typeof error === "object"
                      )?.length > 0
                      ? getUniqueEntries(response)
                      : `${response.data.errors.join(",")}`
                    : response.data.errors
                  : response.data || response.data.statusText
              ).join(" "),
        }
      : response;
  }
  if (response?.status !== 401)
    window.store.setErrorAlert(true, message, action);
};

const getUniqueEntries = (response) => {
  let helperArray = [];

  response?.data?.errors?.map((error) => {
    for (const [key, value] of Object.entries(error)) {
      helperArray = [...helperArray, `${key} ${value}`];
    }
  });

  let unique = helperArray.filter(function (item, pos) {
    return helperArray.indexOf(item) == pos;
  });

  return unique.join(", ");
};

export const returnKeyValueError = (key, value) => {
  return `${key
    .replace(/\./g, " ")
    .replace(/_/g, " ")
    .replace(/(?: |\b)(\w)/g, (key) => {
      return key.toUpperCase();
    })} ${value}`;
};

export const parseErrors = (list) => {
  if (typeof list === "string") {
    list = [list];
  } else if (typeof list === "object") {
    let joinArray = [];

    list = Object.entries(list)?.map(([key, value]) => {
      return (
        isPresent(value) &&
        value?.map((err) => {
          if (
            Array.isArray(err) &&
            err?.filter((error) => typeof error === "object")?.length > 0
          ) {
            let helperArray = [];
            err?.map((error) => {
              for (const [key, value] of Object.entries(error)) {
                if (key !== "id") {
                  helperArray = [
                    ...helperArray,
                    `\n${key
                      .replace(/\./g, " ")
                      .replace(/_/g, " ")
                      .replace(/(?: |\b)(\w)/g, (key) => {
                        return key.toUpperCase();
                      })}: ${value}`,
                  ];
                }
              }
            });

            let unique = helperArray.filter(function (item, pos) {
              return helperArray.indexOf(item) == pos;
            });

            return key === "base" ? value : ` ${unique.join(" ")}\n\n`;
          } else if (typeof err == "string") {
            return joinArray.concat(`${returnKeyValueError(key, err)}\n`);
          }
        })
      );
    });
  } else {
    if (list && list.length > 0 && typeof list[0] === "string") {
      list = list;
    } else {
      list = Object.entries(list)?.map(([key, value]) => {
        return (
          isPresent(value) &&
          value?.map((err) => {
            return key === "base" ? value : returnKeyValueError(key, err);
          })
        );
      });
    }
  }
  return list;
};
