import React, { useState, useEffect, useContext, useReducer } from "react";
import ActiveStepIndicator from "./activeStepIndicator";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import Select from "react-select";
import { customStylesSubsidy } from "utils/helpers/styles";
import { formattedAddres } from "utils/helpers/address";
import "reactjs-floating-label-inputs/dist/index.css";
import AddressInput from "components/addressInput";

const Step5 = ({ disableButton }) => {
  const initialState = {
    locationAddresses: null,
    selectedAddress: null,
    nickname: "",
    streetName: "",
    streetNumber: "",
    city: "",
    province: "",
    postalCode: "",
    unit: "",
    comment: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);

  const { locationID } = userStore;
  const { locationAddresses, selectedAddress } = provider;
  const [newAddress, setNewAddress] = useState(false);
  const [mannualAddress, setMannualAddress] = useState(false);
  const [isNicknameFocused, setIsNicknameFocused] = useState(false);
  const [isCommentFocused, setIsCommentFocused] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    if (!newAddress && selectedAddress) {
      disableButton(false);
    } else {
      disableButton(
        !(
          provider.nickname &&
          provider.streetName &&
          provider.streetNumber &&
          provider.city &&
          provider.province &&
          provider.postalCode
        )
      );
    }
  }, [
    newAddress,
    selectedAddress,
    provider.nickname,
    provider.streetName,
    provider.streetNumber,
    provider.city,
    provider.province,
    provider.postalCode,
  ]);

  useEffect(() => {
    store.deliveryInfoStore
      ?.getLocationAddresses({ location_id: locationID })
      .then((response) => {
        dispatch({ field: "locationAddresses", value: response?.data });
      })
      .catch(() => store.userStore.setLoader(false));
  }, []);

  useEffect(() => {
    const address = {
      suite: provider.unit,
      street_number: provider.streetNumber,
      street_name: provider.streetName,
      city: provider.city,
      province: provider.province,
      postal_code: provider.postalCode,
      nickname: provider.nickname,
      delivery_instructions: provider.comment,
    };

    store.onboardingStore.setNewAddress(address);
  }, [
    provider.nickname,
    provider.streetName,
    provider.streetNumber,
    provider.city,
    provider.province,
    provider.postalCode,
    provider.comment,
  ]);

  const addressOptions =
    locationAddresses && Array.isArray(locationAddresses)
      ? [
          ...locationAddresses?.map((address) => ({
            label: formattedAddres(address.attributes),
            value: address.id,
          })),
          {
            label: "Enter new delivery address",
            value: "enter_new_delivery_address",
          },
        ]
      : [
          {
            label: "Enter new delivery address",
            value: "enter_new_delivery_address",
          },
        ];

  const handleAddressChange = (address) => {
    if (address.value === "enter_new_delivery_address") {
      setNewAddress(true);

      dispatch({
        field: "selectedAddress",
        value: {
          label: "Enter new delivery address",
          value: "enter_new_delivery_address",
        },
      });
    } else {
      setNewAddress(false);

      dispatch({ field: "selectedAddress", value: address });
      store.onboardingStore.setAddress(address);
    }
  };

  return (
    <div className="pb-4 mt-4">
      <div className="flex-col items-center content-center">
        <ActiveStepIndicator step={5} />

        <div className="expected-bag-text xs:w-full-235 font-inter-semibold">
          Where should the meal be delivered?
        </div>

        <div className="w-full mt-4">
          <Select
            styles={customStylesSubsidy}
            aria-labelledby="sort-by"
            aria-label="sort-by"
            placeholder="Select address"
            closeMenuOnSelect
            value={selectedAddress}
            onChange={handleAddressChange}
            options={addressOptions}
            className="w-[750px]"
          />
        </div>

        {newAddress && (
          <div className="flex-col flex w-full mt-6">
            <div className="text-black xs:w-full-235 font-inter-semibold mb-8">
              Add a new address
            </div>

            <div className="flex flex-col items-start w-full mb-8">
              <small className="text-dark-gray font-inter-regular text-xs">
                {provider.nickname || isNicknameFocused
                  ? "Address nickname*"
                  : ""}
              </small>

              <input
                className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                onChange={(e) => {
                  dispatch({
                    field: "nickname",
                    value: e.target?.value,
                  });
                }}
                onFocus={() => setIsNicknameFocused(true)}
                onBlur={() => setIsNicknameFocused(false)}
                placeholder={isNicknameFocused ? "" : "Address Nickname*"}
                value={provider.nickname}
              />
            </div>

            {!mannualAddress && (
              <AddressInput
                label={"Address"}
                dispatch={dispatch}
                setMannualAddress={() => {
                  setMannualAddress(true);
                  dispatch({ field: "addressError", value: false });
                }}
                isOnboarding={true}
              />
            )}

            {mannualAddress && (
              <>
                <div className="d-row">
                  <div className="flex flex-col items-start w-1/2 mr-[24px]">
                    <small className="text-dark-gray font-inter-regular text-xs">
                      Street number*
                    </small>

                    <input
                      className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                      required
                      type={"number"}
                      onChange={(e) => {
                        dispatch({
                          field: "streetNumber",
                          value: e.target?.value,
                        });
                      }}
                      value={provider.streetNumber}
                    />
                  </div>

                  <div className="flex flex-col items-start w-1/2">
                    <small className="text-dark-gray font-inter-regular text-xs">
                      Unit/suite (optional)
                    </small>

                    <input
                      className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                      type={"number"}
                      onChange={(e) => {
                        dispatch({
                          field: "unit",
                          value: e.target?.value,
                        });
                      }}
                      value={provider.unit}
                    />
                  </div>
                </div>

                <div className="flex flex-col items-start w-full mt-[24px] mr-4">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Street name*
                  </small>

                  <input
                    className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    required
                    type="text"
                    onChange={(e) => {
                      dispatch({
                        field: "streetName",
                        value: e.target?.value,
                      });
                    }}
                    value={provider.streetName}
                  />
                </div>

                <div className="d-row mt-[24px]">
                  <div className="flex flex-col items-start w-1/2 mr-[24px]">
                    <small className="text-dark-gray font-inter-regular text-xs">
                      City*
                    </small>

                    <input
                      className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                      required
                      onChange={(e) => {
                        dispatch({
                          field: "city",
                          value: e.target?.value,
                        });
                      }}
                      value={provider.city}
                    />
                  </div>

                  <div className="flex flex-col items-start w-1/2 mr-[24px]">
                    <small className="text-dark-gray font-inter-regular text-xs">
                      Province*
                    </small>

                    <input
                      className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                      required
                      onChange={(e) => {
                        dispatch({
                          field: "province",
                          value: e.target?.value,
                        });
                      }}
                      value={provider.province}
                    />
                  </div>

                  <div className="flex flex-col items-start w-1/2">
                    <small className="text-dark-gray font-inter-regular text-xs">
                      Postal code*
                    </small>

                    <input
                      className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                      required
                      onChange={(e) => {
                        dispatch({
                          field: "postalCode",
                          value: e.target?.value,
                        });
                      }}
                      value={provider.postalCode}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-col items-start w-full mt-8 mb-8">
              <small className="text-dark-gray font-inter-regular text-xs">
                {provider.comment || isCommentFocused
                  ? "Delivery instructions (optional)"
                  : ""}
              </small>

              <input
                className="bg-background relative mt-[4px] rounded-xl py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-normal focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                onChange={(e) => {
                  dispatch({
                    field: "comment",
                    value: e.target?.value,
                  });
                }}
                onFocus={() => setIsCommentFocused(true)}
                onBlur={() => setIsCommentFocused(false)}
                placeholder={
                  isCommentFocused ? "" : "Delivery Instructions (optional)"
                }
                value={provider.comment}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step5;
