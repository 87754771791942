export default {
  dietaryPreference: () => "/v2/company/onboarding_preferences",
  mealPlans: () => `/v2/uncatering_admin/meal_plans`,
  teams: () => `/v2/uncatering_admin/teams`,
  employees: () => `/v2/uncatering_admin/location_employees`,
  bulkSubsidyUpdate: () =>
    `/v2/uncatering_admin/location_employees/bulk_update`,
  groupOrders: () => `/v2/uncatering_admin/shipments`,
  adminPanelDietaryPreference: () =>
    `/v2/uncatering_admin/meal_plan_preferences`,
  meals: () => `/v2/uncatering_admin/meals`,
  billingAccounts: () => `/v2/uncatering_admin/location_billing_accounts`,
  locationAddress: () => `/v2/uncatering_admin/locations`,
  locationAddresses: () => `/v2/uncatering_admin/addresses`,
  uncateringInvoices: () => `/v2/uncatering_admin/invoices`,
  uncateringOrder: () => `/v2/uncatering_admin/orders`,
  mealTeams: () => `/v2/uncatering_admin/meal_teams`,

  //location credit cards
  cards: () => `/v2/uncatering_admin/credit_cards`,
  paymentIntent: () => `/v2/uncatering_admin/payment_intents`,

  availableDates: () => `/v2/odc/dates`,
  availableTimes: () => `/v2/odc/times`,

  locationMenus: () => `/v2/odc/menus`,
  settings: () => `/v2/odc/settings`,
  menuItems: () => `/v1/odc/menu_items`,
  unCheckedOutOrders: () => `/v2/odc/uncheckedout_orders`,
  cart: () => `/v2/odc/cart_items`,
  stripeSessions: () => `/v2/odc/stripe_sessions`,
  deliveryFee: (restaurantId) =>
    `/v1/odc/settings/${restaurantId}/delivery_fee`,
  odcOrder: () => `/v2/odc/orders`,
  groupOrder: () => `/v2/uncatering_admin/group_orders`,
  groupOrderDeadline: () => `/v2/guest/group_deadlines`,
  uncheckedOutOrder: () => `/v2/guest/uncheckedout_orders`,
  odcEmployees: () => `/v2/odc/employees`,
  orders: () => `/v2/odc/orders`,
};
