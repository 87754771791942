import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useReducer } from "react";
import { MobXProviderContext } from "mobx-react";
import ConfirmationBadge from "components/ConfirmationBadge";
import { CopyLinkWhite } from "assets/img";
import { NotificationBanner } from "components";
import { useParams } from "react-router-dom";

const GroupOrderConfirmation = () => {
  // const params = useParams();

  const { id } = useParams();

  const initialState = {
    link: "",
    showPassword: false,
    error: false,
    showCopyLinkBadge: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const paymentInfoStore = toJS(store?.paymentInfoStore);
  const mealManagementStore = toJS(store?.mealManagementStore);

  let { defaultLocation } = userStore ?? {};
  let { showCopyLinkBadge } = provider ?? {};
  let { defaultPaymentMethodId } = paymentInfoStore;
  let { orderDeadline, currentGroupOrder } = mealManagementStore;

  const navigate = useNavigate();

  useEffect(() => {
    store?.userStore?.setLoader(true);
    store.paymentInfoStore
      .getLocationCreditCards({ location_id: defaultLocation?.organizationId })
      .then(() => store?.userStore?.setLoader(false));

    store.mealManagementStore.fetchGroupOrder(id, {
      location_id: defaultLocation?.organizationId,
    });
  }, []);

  const getURL = () => {
    return `${process.env.REACT_APP_CATERING_APP}/group-order/user/restaurant/${currentGroupOrder?.attributes?.code}/${currentGroupOrder?.attributes?.invitation_link}`;
  };

  const copyLinkToDashboard = () => {
    if (currentGroupOrder) {
      const updatedURL = getURL();

      navigator.clipboard.writeText(updatedURL);

      dispatch({ field: "showCopyLinkBadge", value: true });

      setTimeout(() => {
        dispatch({ field: "showCopyLinkBadge", value: false });
      }, 2000);
    }
  };

  const submitButtons = (
    <Link>
      <div className="flex flex-col mt-[32px] items-center">
        <button
          type="button"
          className="btn-purple w-[232px] mt-5 h-16"
          onClick={() => {
            defaultPaymentMethodId
              ? navigate(
                  `/group-order/admin/orders/${currentGroupOrder?.id}/${defaultLocation?.organizationId}`
                )
              : navigate(`/payment-info`);
          }}
        >
          {defaultPaymentMethodId
            ? "Go To Group Order"
            : "Update Payment Method"}
        </button>
      </div>
    </Link>
  );

  return (
    <>
      <div
        className="flex justify-center relative bg-white min-h-[calc(100vh-200px)] mx-auto p-5 sm:p-10 md:p-8 md:mt-0"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-col items-center mt-4 w-full md:w-[434px]">
          <div className="flex flex-col text-center">
            {!defaultPaymentMethodId && (
              <NotificationBanner
                message="Please set up a payment method soo your order can be processes. You can do so by accessing your account through the last email we sent you."
                messageType="warning"
                visible={!defaultPaymentMethodId}
                bgColor="bg-[#f3f3f3]"
                infoWarningIcon={true}
                hideClose={true}
              />
            )}

            <span className="text-md font-inter-semibold text-hh-accent-light">
              Step 3 of {defaultPaymentMethodId ? 3 : 4}
            </span>

            <span className="text-[24px] font-inter-semibold text-black-light mt-1.5">
              {defaultPaymentMethodId
                ? "All set, share the link!"
                : "Almost there!"}
            </span>

            {defaultPaymentMethodId ? (
              <span className="text-md mt-3 font-inter-normal text-[#2d3958]">
                Your Group Order is ready to go! Share this invite link with
                your colleagues and friends so they can join the order and
                choose their own meals. The deadline for them to place their
                orders is {orderDeadline}. You can always access this link and
                information again from your admin panel.
              </span>
            ) : (
              <span className="text-md mt-3 font-inter-normal text-[#2d3958]">
                Your group order is almost ready! To complete it, please set up
                a payment method so your order can be processed. In the
                meantime, share the invite link with your colleagues and friends
                so they can join the order and select their meals. The deadline
                for placing orders is {orderDeadline}. Ensure your payment
                method is updated by asap to avoid order cancellation. You can
                always access this link and related information from your admin
                panel.
              </span>
            )}
          </div>

          <div className="flex flex-col justify-center items-center flex-wrap mt-12 w-full">
            <div className="flex flex-col w-full">
              <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background w-full overflow-hidden focus:outline-none h-12 mt-3">
                <input
                  required
                  className="flex w-full bg-background text-secondary-text focus:outline-none rounded-l-md text-md px-3"
                  value={getURL()}
                  disabled={true}
                />

                <button
                  type="button"
                  onClick={copyLinkToDashboard}
                  className="btn-style w-2/5 bg-primary-dark hover:bg-hh-accent-light"
                >
                  <div className="flex items-center justify-center">
                    <img src={CopyLinkWhite} layout="fixed" />
                    <span className="text-white font-inter-medium text-md ml-2">
                      Copy Link
                    </span>
                  </div>
                </button>
              </div>

              <div className="flex flex-col">{submitButtons}</div>
            </div>
          </div>
        </div>

        {showCopyLinkBadge && <ConfirmationBadge text={"Link Copied!"} />}
      </div>
    </>
  );
};

export default observer(GroupOrderConfirmation);
