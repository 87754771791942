import React, { useContext, useState, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import { observer } from "mobx-react";
import CuisineGrid from "./cuisineGrid";
import { toJS } from "mobx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import userStore from "mobXstore/userStore";

function MealPreference({ disableButton }) {
  const { onboardingStore } = useContext(MobXProviderContext);
  const { mealManagementStore } = useContext(MobXProviderContext);
  const mealPlan = mealManagementStore.mealPlan;
  const dietaryPreferences = mealManagementStore.dietaryPreferences;
  const cuisineTypes = mealManagementStore.cuisineTypes;
  const [disableEdit, setDisableEdit] = useState(false);
  const [allCuisineTypes, setAllCuisineTypes] = useState([]);
  const [selectedCuisineTypes, setSelectedCuisineTypes] = useState([]);
  const [dietPreference, setDietPreference] = useState([]);
  const [additionalPreference, setAdditionalPreference] = useState("");

  const locationID = userStore.locationID;

  useEffect(() => {
    mealManagementStore.fetchCuisineTypesData(locationID);
  }, []);

  useEffect(() => {
    const cuisines = selectedCuisineTypes.reduce((acc, { name, review }) => {
      acc[name] = review;
      return acc;
    }, {});

    mealManagementStore
      .updateMealPlan(locationID, mealPlan?.id, {
        meal_series: {
          meal_plan_preference_attributes: {
            dietary_preferences: dietPreference
              ?.map((pref) => toJS(pref?.name))
              ?.join(", "),
            cuisines: cuisines,
          },
        },
      })
      .then(() => {});
  }, [selectedCuisineTypes, dietPreference]);

  useEffect(() => {
    handleAllCuisineTypes();
  }, [selectedCuisineTypes]);

  useEffect(() => {
    setAdditionalPreference(
      mealPlan?.attributes?.meal_plan_preference?.additional_notes
    );
  }, [mealPlan]);

  const handleAllCuisineTypes = () => {
    const allCuisineTypes = cuisineTypes?.map((type) => {
      const foundCuisine = selectedCuisineTypes.find(
        (selectedType) => selectedType.name === type.name
      );

      if (foundCuisine) {
        const newObj = {};
        newObj[type.name] = foundCuisine.review;
        return newObj;
      } else {
        return {
          [type.name]: "neutral",
        };
      }
    });

    setAllCuisineTypes(allCuisineTypes);
  };

  const handleDietaryPreferenceClick = (value) => {
    if (dietPreference.includes(value)) {
      setDietPreference((prevDays) => prevDays.filter((day) => day !== value));
    } else {
      setDietPreference((prevDays) => [...prevDays, value]);
    }
  };

  const handleCuisineTypeClick = (name, review) => {
    setSelectedCuisineTypes((prevCuisines) => {
      const existingCuisineIndex = prevCuisines.findIndex(
        (cuisine) => cuisine.name === name
      );

      if (existingCuisineIndex !== -1) {
        if (prevCuisines[existingCuisineIndex].review === review) {
          return prevCuisines.filter((cuisine) => cuisine.name !== name);
        } else {
          return prevCuisines?.map((cuisine) =>
            cuisine.name === name ? { name, review } : cuisine
          );
        }
      } else {
        return [...prevCuisines, { name, review }];
      }
    });
  };

  const handleAdditionalPreference = (e) => {
    setAdditionalPreference(e?.target?.value);
  };

  const handleEdit = () => {
    setDisableEdit(!disableEdit);
    if (disableEdit) {
      mealManagementStore.updateMealPlan(locationID, mealPlan?.id, {
        meal_series: {
          meal_plan_preference_attributes: {
            additional_notes: additionalPreference,
          },
        },
      });
    }
  };

  return (
    <div className="d-col p-6 w-full relative">
      <h1 className="font-inter-bold text-heading text-[32px]">
        Restaurant & dietary preferences
      </h1>
      <small className="text-heading text-[16px] font-inter-normal">
        Your input helps shape your Uncatering meal plan
      </small>

      <h2 className="font-inter-semibold mt-[24px] text-heading text-[20px]">
        Dietary preferences
      </h2>
      <small className="text-heading text-[16px] font-inter-normal">
        Highlight if members of your team have any of the following dietary
        preferences
      </small>

      <div className="mb-0.5 w-full xs:w-full-215 mt-[24px]">
        <div className="lg:inline-flex md:inline-flex">
          {dietaryPreferences?.map((value, index) => (
            <div
              key={index}
              className={`lg:mr-3 md:mr-1 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                dietPreference.some((diet) => diet === value)
                  ? "bg-indigo-500 text-white "
                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
              } rounded-3xl justify-start lg:justify-center md:justify-center gap-2 flex`}
              onClick={() => handleDietaryPreferenceClick(value)}
            >
              {value?.name}
            </div>
          ))}
        </div>
      </div>

      <div className="w-full d-col bg-slate-50 w-[586px] items-start mt-[24px] py-2 px-4 rounded-lg">
        <div className="d-row w-full items-center justify-between">
          <label className="text-gray-800 text-xl font-inter-semibold leading-normal">
            Any additional preferences or dietary restrictions?
          </label>

          <button onClick={handleEdit}>
            <FontAwesomeIcon
              size="1x"
              className="icon"
              icon={!disableEdit ? faPenToSquare : faCheck}
            />
          </button>
        </div>

        <input
          type="text"
          autoFocus={true}
          id="otherMeal"
          className={classNames(
            "w-full lg:w-[512px] md:w-[512px] h-[46px] p-4 my-[10px] rounded-xl text-base font-inter-medium leading-7",
            "text-slate-700"
          )}
          placeholder={"ex. Halal Food"}
          disabled={!disableEdit}
          value={additionalPreference}
          onChange={handleAdditionalPreference}
        />
      </div>

      <h2 className="font-inter-semibold mt-[24px] text-heading text-[20px]">
        Cuisine preferences
      </h2>
      <small className="text-heading text-[16px] font-inter-normal mb-[33px]">
        How does your team feel about the following cuisines?
      </small>

      <CuisineGrid
        dietaryPreferences={cuisineTypes}
        handleReviewButton={handleCuisineTypeClick}
        selectedCuisines={selectedCuisineTypes}
      />

      <div className="lg:hidden md:hidden sm:flex mb-0.5 w-full xs:w-full-215">
        <div className="lg:inline-flex md:inline-flex">
          {cuisineTypes?.map((cuisine, index) => (
            <div
              key={index}
              className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                selectedCuisineTypes.some(
                  (cuisineType) => cuisineType.name === cuisine.name
                )
                  ? "bg-indigo-500 text-white "
                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
              } rounded-3xl justify-start lg:justify-center md:justify-center gap-2 flex  `}
              onClick={() => {
                handleCuisineTypeClick(cuisine.name, "like");
              }}
            >
              {cuisine?.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default observer(MealPreference);
