export const getOrderStatus = (status, shipmentAcceptanceStatus) => {
  switch (status) {
    case "completed":
      return "Delivered";
    case "preview":
    case "sent":
    case "confirmed":
      return "In Progress";
    case "pending":
      return !shipmentAcceptanceStatus ? "Order Placed" : "Confirmed";

    default:
      return status;
  }
};

export const getOrderStatusForAttendee = (attendeeStatus, orderStatus) => {
  if (orderStatus == "confirmed") {
    switch (attendeeStatus) {
      case "placed":
        return "Confirmed";
      default:
        return attendeeStatus;
    }
  } else return attendeeStatus;
};
