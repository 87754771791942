import React, { useState, useEffect } from "react";
import { NotFound } from "components";
import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import dateTime from "utils/helpers/dateTime";
import { isPresent } from "utils/helpers/array";

const ExpandableRow = ({ meal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  let userOrdersIds =
    (meal?.attributes?.orders?.length &&
      meal?.attributes?.orders?.map(
        (order) => order.attributes?.employee_id
      )) ||
    [];

  return (
    <div className="w-full">
      {/* Row Header */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="grid grid-cols-8 w-full bg-light-gray h-[68px] items-center px-[12px] mt-[12px] rounded-md">
          <span
            className={`font-inter-semibold text-[14px] ${
              meal.attributes?.service === "uncatering"
                ? "text-uncatering"
                : meal.attributes?.service === "catering"
                ? "text-catering"
                : "text-group-order"
            }`}
          >
            {meal.attributes?.service ?? "Not specified"}
          </span>
          <span
            className={`font-inter-semibold text-[14px] text-dark-gray normal-case`}
          >
            {meal?.attributes?.name}
          </span>
          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {meal?.attributes?.address?.attributes?.nickname ?? "-"} (
            {meal?.attributes?.address?.attributes?.destination_code})
          </span>
          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            Restaurant
          </span>

          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {new Set(userOrdersIds).size}
          </span>

          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {dateTime.formatDateWithTimzoneInfo(
              meal?.attributes?.cutoff,
              "ddd, MMMM Do, yyyy",
              meal?.attributes?.tzinfo
            )}{" "}
            {dateTime.formatTimeWithFormat(
              meal?.attributes?.cutoff,
              "hh:mm a",
              meal?.attributes?.tzinfo
            )}
          </span>

          <div className="d-row items-center justify-center md:px-1">
            <button
              onClick={() => navigate(`/home/uncatering-meal/${meal?.id}`)}
              className="btn-outline w-[93px] font-inter-medium border-2 text-catering px-0"
            >
              View order
            </button>
          </div>

          <div className="d-row items-center justify-center">
            <img
              src={isOpen ? ChevronLeftIcon : ChevronRightIcon}
              className="h-[20px] w-[20px] transform-left transform rotate-90"
            />
          </div>
        </div>
      </div>

      {/* Expandable Content */}
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {meal?.attributes?.shipments?.map((shipment) => {
          return (
            <div className="grid grid-cols-8 w-full bg-white h-[68px] items-center px-[12px] mt-[12px] rounded-md">
              <span></span>
              <span></span>
              <span></span>
              <span className={`font-inter-medium text-[14px] text-dark-gray`}>
                {shipment?.menu_name}
              </span>
              <span className={`font-inter-medium text-[14px] text-dark-gray`}>
                {shipment.users_ordered_count}
              </span>
              <span
                className={`font-inter-medium text-[14px] text-dark-gray capitalize`}
              >
                {shipment.status}
              </span>

              <div></div>

              <div></div>
            </div>
          );
        })}
      </Transition>
    </div>
  );
};

function ActiveMeals({ data }) {
  const [screenSize, setScreenSize] = useState("md");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenSize("sm");
      } else if (window.innerWidth < 1024) {
        setScreenSize("md");
      } else {
        setScreenSize("lg");
      }
    };

    handleResize(); // Initial call to set the initial screen size

    window.addEventListener("resize", handleResize); // Listen for window resize events

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isPresent(data)) {
    return (
      <div className="flex-col w-full items-center flex">
        <div className="grid grid-cols-8 w-full bg-light-gray h-[46px] items-center px-[12px] rounded-md mt-[24px]">
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Type
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Name
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Address
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Restaurant
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Users Ordered
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            {" "}
            Status{" "}
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray"></span>
          <span className="font-inter-bold text-[14px] text-dark-gray"></span>
        </div>

        <div className=" mx-auto w-full">
          <div className="w-full">
            {data.map((meal, index) => (
              <ExpandableRow key={index} meal={meal} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-col items-center">
      <NotFound data={data} message="No meals" />

      <button className="btn-blue-accent">Learn about our services</button>
    </div>
  );
}

export default ActiveMeals;
