import React, { Fragment, useContext, useEffect, useState } from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { toTitleCase } from "utils/helpers/string";

function TagsFilterModal({ provider, dispatch }) {
  const [activeTags, setActiveTags] = useState(provider.activeMenuItemTags);
  const store = useContext(MobXProviderContext);
  const restaurantStore = toJS(store?.restaurantStore);

  let { tagsFilterModal, activeRestaurant } = restaurantStore ?? {};

  useEffect(() => {
    setActiveTags(provider.activeMenuItemTags);
  }, [tagsFilterModal]);

  const applyFilters = () => {
    dispatch({
      field: "activeMenuItemTags",
      value: activeTags,
    });
    store?.restaurantStore?.setActiveTagsLength(activeTags?.length);
    store?.restaurantStore?.setTagsFilterModal(false);
  };

  const resetFilters = () => {
    store?.restaurantStore?.setActiveTagsLength(0);
    dispatch({ field: "activeMenuItemTags", value: [] });
    store?.restaurantStore?.setTagsFilterModal(false);
  };

  return (
    <Transition appear show={tagsFilterModal ?? false} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-0 right-0 left-0 sm:inset-0 overflow-y-auto">
          <div className="flex min-h-screen sm:min-h-full sm:items-center sm:justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex flex-col flex-1 md:flex-initial w-full md:w-5/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white pt-10 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col mb-3 px-8">
                  <button
                    type="button"
                    onClick={() =>
                      store?.restaurantStore?.setTagsFilterModal(false)
                    }
                    className="btn-style flex justify-end"
                  >
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#BBC0C5"
                    />
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="font-inter-semibold leading-6 text-black-light text-md2"
                  >
                    Cuisine
                  </Dialog.Title>
                </div>

                <div className="flex flex-col flex-1 justify-between">
                  <div className="flex flex-col flex-wrap mt-2 px-8">
                    {activeRestaurant?.attributes?.menu_items_badges?.map(
                      (badge, index) => (
                        <div key={badge + index}>
                          <label className="inline-flex items-center py-1.5 text-sm select-none">
                            <input
                              autoComplete="off"
                              type="checkbox"
                              className="form-checkbox"
                              onClick={(e) => {
                                if (e.target.checked)
                                  setActiveTags([
                                    ...activeTags,
                                    e.target.value,
                                  ]);
                                else
                                  setActiveTags(
                                    activeTags.filter(
                                      (_tag) => _tag !== e.target.value
                                    )
                                  );
                              }}
                              value={badge}
                              checked={activeTags.includes(badge)}
                            />

                            <span className="ml-1 font-inter-regular text-sm">
                              {toTitleCase(badge)}
                            </span>
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <Transition.Child
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="sm:hidden">
            <div className="fixed bg-white w-full py-5 bottom-0 z-[90] justify-center items-center mt-16 border-t-2 border-gray-200">
              <div className="flex justify-center px-10">
                <button
                  className="btn-light w-1/2 py-3 px-5"
                  onClick={resetFilters}
                >
                  Reset
                </button>

                <button
                  className="btn-purple w-1/2 py-3 px-6 ml-3"
                  onClick={applyFilters}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default observer(TagsFilterModal);
