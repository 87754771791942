import {
  FaqsIcon,
  GroupUsersIcon,
  Logo,
  LogoBlack,
  LogoHH,
  LogoutIcon,
  OrdersIcon,
  ChevronLeftIcon,
  hhLogo,
} from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { useContext, Fragment, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Disclosure, Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import {
  faAdd,
  faArrowRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Screen from "utils/helpers/ScreenSize";

import ConfirmationModal from "components/ConfirmationModal";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
// import LoggedInAvatar from "components/Shared/LoggedInAvatar";

function CheckoutLayout({ mainContent }) {
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const [isDrawerOpen, setDrawer] = useState(false);
  const [logoutAlert, setLogoutAlert] = useState(null);
  const screenType = Screen.ScreenType();

  const router = null;
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  let { locationID } = userStore;

  const logout = () => {
    store.userStore.setLoader(true);
    setDrawer(false);

    setTimeout(() => {
      setTimeout(() => {
        store.userStore.setLoader(false);
        setLogoutAlert(null);
        router.push("/");
        store.userStore.clearUser();
        store.cartStore.resetCart();
      }, 1000);
    }, 1000);
  };

  let drawerContent = (
    <div className="flex w-full flex-col h-full justify-between overflow-y-scroll">
      <div className="flex px-2 flex-col mt-[38.5px]">
        <Link to="/group-order/orders">
          <button
            onClick={() => {
              setDrawer(false);
            }}
            className={classNames({
              "flex p-4 rounded-md items-center btn-style": true,
              "bg-white w-full": location?.pathname?.includes(
                "/group-order/orders"
              ),
            })}
          >
            <img src={OrdersIcon} className="icon" />
            <span className="text-md font-inter-medium ml-2">Orders</span>
          </button>
        </Link>

        <Link to="/group-order/admin/group-order-new">
          <button
            onClick={() => setDrawer(false)}
            className={classNames({
              "flex p-4 rounded-md items-center btn-style mt-2": true,
              "bg-white w-full":
                location?.pathname == "/group-order/admin/group-order-new",
            })}
          >
            <img src={GroupUsersIcon} className="icon" />
            <span className="text-md font-inter-medium ml-2">Group Order</span>

            <div className="flex bg-hh-accent-light items-center justify-center rounded-[6px] px-3 h-6 py-[6px] ml-[25px]">
              <span className="font-inter-medium text-xs text-white">New</span>
            </div>
          </button>
        </Link>

        <Link to="/group-order/faqs">
          <button
            onClick={() => setDrawer(false)}
            className={classNames({
              "flex p-4 rounded-md items-center btn-style mt-2": true,
              "bg-white w-full": location?.pathname === "/group-order/faqs",
            })}
          >
            <img src={FaqsIcon} className="icon" />
            <span className="text-md font-inter-medium ml-2">FAQ</span>
          </button>
        </Link>

        <Link href="/">
          <button
            onClick={() => setDrawer(false)}
            className="btn-style cursor-pointer flex bg-primary-dark hover:bg-hh-accent-dark rounded-md p-4 m-4 mt-5 max-w-[200px]"
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faAdd}
                size="1x"
                className="icon text-white"
              />

              <span className="text-white text-md font-inter-medium ml-3">
                Place new order
              </span>
            </div>
          </button>
        </Link>
      </div>

      <div className="flex flex-col p-4">
        <Link href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/uncatering`}>
          <div className="flex flex-col cursor-pointer p-4 border items-start border-hh-grey rounded-[4px]">
            <img src={LogoHH} layout="fixed" className="h-[25px] w-[25px]" />

            <span className="text-md2 font-inter-semibold mt-2">
              Ordering Often?
            </span>

            <span className="text-md mt-3">
              Learn more about Uncatering™ - a meal program that connects your
              employees with the best restaurants for daily or weekly meals
            </span>

            <FontAwesomeIcon
              icon={faArrowRight}
              className="icon mt-2 text-un-accent-pink"
            />
          </div>
        </Link>

        <button
          type="button"
          onClick={() => setLogoutAlert(true)}
          className={"flex p-4 btn-style mt-6 rounded-md items-center"}
        >
          <img src={LogoutIcon} className="h-[16px] w-[16px]" />
          <span className="text-md font-inter-medium ml-2">Log out</span>
        </button>
      </div>

      <ConfirmationModal
        visible={logoutAlert}
        setModal={setLogoutAlert}
        title={"Are you sure you want to logout?"}
        body={""}
        rightButtonText={"Logout"}
        leftButtonText={"Cancel"}
        onClickLeftButton={() => setLogoutAlert(null)}
        onClickRightButton={logout}
      />
    </div>
  );

  return (
    <>
      <Disclosure as="nav" className="mx-auto w-full">
        <div className="flex min-h-screen">
          {/* Main content */}
          <div className="flex-1 flex flex-col">
            <header className="sticky top-0 z-50">
              <div className="sticky top-0 z-40 flex h-[92px] bg-white shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 d-row justify-between">
                <div class="grid grid-cols-3 w-full">
                  <div className="d-row items-center">
                    <img src={ChevronLeftIcon} className="h-4 w-4" />

                    <button
                      onClick={() => {
                        navigate(
                          `/group-order/admin/orders/${id}/${locationID}`
                        );
                      }}
                    >
                      Go back to Group Order
                    </button>
                  </div>
                  <div className="d-col justify-center items-center">
                    <img
                      className="h-[56px] w-[152px] m-[16px]"
                      src={hhLogo}
                      alt="hungerhub Company"
                    />
                  </div>
                </div>
              </div>
            </header>

            {/* Page content */}
            {mainContent}
          </div>
        </div>

        <Transition appear show={isDrawerOpen} as={Fragment}>
          <Dialog
            as="div"
            unmount={false}
            onClose={() => {}}
            className="relative z-[60] md:hidden"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 sm:inset-auto sm:bottom-0 sm:top-0 sm:right-0 flex w-full sm:w-1/2 justify-end h-screen">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-out-in duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="flex w-full justify-end h-screen">
                  <Dialog.Overlay className="z-[60] fixed inset-0 bg-black bg-opacity-30" />

                  <div
                    className={`z-[60] flex flex-col h-full justify-between bg-white w-full px-2 pb-6 text-left shadow-xl`}
                  >
                    <Dialog.Panel className="flex h-full overflow-scroll w-full">
                      <div
                        style={{ width: "100%" }}
                        className="absolute flex flex-col z-[60] top-0 left-0 pt-2 float-right bg-white"
                      >
                        <div className="">
                          <button
                            type="button"
                            onClick={() => setDrawer(!isDrawerOpen)}
                            className="flex btn-style bg-white justify-end p-3 mx-5 float-right"
                          >
                            <FontAwesomeIcon
                              style={{ width: 16, height: 26 }}
                              className="ml-2"
                              icon={faXmark}
                              color={"#DEE2E6"}
                              size="2x"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col justify-between bg-white mt-5">
                        <div className="flex flex-col h-full">
                          {drawerContent}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </Disclosure>
    </>
  );
}

export default observer(CheckoutLayout);
