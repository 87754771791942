import React, { useRef, useEffect, useState, useCallback } from "react";
import { TimepickerUI } from "timepicker-ui";

const AndroidTimepicker = ({ timeValue, onTimeSelected, mealName }) => {
  const tmRef = useRef(null);
  const [inputValue, setInputValue] = useState("12:00 PM");
  const [amPmValue, setAmPmValue] = useState("PM");

  const testHandler = useCallback((e) => {
    setInputValue(`${e.detail.hour}:${e.detail.minutes} ${e.detail.type} `);
    setAmPmValue(e.detail.type);
  }, []);

  useEffect(() => {
    const tm = tmRef.current;

    const newPicker = new TimepickerUI(tm, {
      enableSwitchIcon: true,
    });

    newPicker.create();

    tm.addEventListener("accept", testHandler);

    return () => {
      tm.removeEventListener("accept", testHandler);
    };
  }, [testHandler]);

  useEffect(() => {
    onTimeSelected(inputValue, mealName);
  }, [inputValue]);

  return (
    <div className="timepicker-ui w-full" ref={tmRef}>
      <div className="flex justify-between w-full">
        <input
          className="w-2/3 pl-6 text-slate-700 timepicker-ui-input bg-transparent outline-none items-start "
          value={inputValue}
        />
        <div
          className={`w-[49px] h-[52px] bg-indigo-500 flex items-center justify-center rounded-r-md`}
        >
          <span className="text-white font-inter-medium">{amPmValue}</span>
        </div>
      </div>
    </div>
  );
};

export default AndroidTimepicker;
