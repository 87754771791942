import { useContext, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

function InvoiceRequestCard({ visible, setModal, rightClick }) {
  const [invoiceRequest, changeInvoiceRequest] = useState("");

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-[667px] w-[320px] lg:h-[346px] md:h-[681px] d-col justify-between rounded-xl md:w-[620px] transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all gap-[24px]">
                <div className="d-col justify-between gap-[24px]">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-inter-bold">
                      Request To Be Invoiced For Orders
                    </h2>

                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <span className="font-inter-regular text-dark-gray text-[14px]">
                    Let us know more about your invoice needs like how often and
                    how much you plan on ordering.{" "}
                  </span>

                  <input
                    required
                    className="flex w-full h-[82px] rounded-xl bg-background text-dark-gray focus:outline-none text-md py-[9px] p-[19px]"
                    placeholder="Eg. I will be ordering catering for the following 3 months for meetings ..."
                    value={invoiceRequest}
                    onChange={(e) => changeInvoiceRequest(e.target.value)}
                    type="text"
                  />
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5 lg:w-[98px] md:w-[197.50px]"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3 lg:w-[98px] md:w-[197.50px]"
                    onClick={() => rightClick(invoiceRequest)}
                  >
                    Request
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default InvoiceRequestCard;
