import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useContext, useRef } from "react";
import Select, { components } from "react-select";
import {
  getTimeOptionsWithDisabledInDirecory,
  timeFormat,
} from "utils/helpers/dateTime";
import { selectStyles, selectStylesDirectory } from "utils/helpers/styles";
import { toJS } from "mobx";
import ValueContainer from "./ValueContainer";
import { MobXProviderContext } from "mobx-react";

const TimePickerDropdown = ({
  selectedTime,
  selectedDate,
  isCart,
  selectPicker,
  fromModal,
  labelName,
  isGroupOrder,
  setSelectedTime,
  isInternalSelection,
  backgroundColor,
  allTimes,
}) => {
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  let { addressTimezone, timeOptionsLoading } = userStore;

  const selectRef = useRef();
  const id = labelName ?? "time-picker";

  const allOptions = getTimeOptionsWithDisabledInDirecory(
    selectedDate,
    allTimes?.map((time) => ({
      label: `${moment(time, "HH:mm").format(timeFormat)} ${
        addressTimezone ?? ""
      }`,
      value: moment(time, "HH:mm").format(timeFormat),
    }))
  );

  const onMenuOpen = () => {
    setTimeout(() => {
      const { focusedOptionRef } = selectRef.current;
      focusedOptionRef && focusedOptionRef.scrollIntoView();
    }, 1);
  };

  const onChangeTime = (time) => {
    store.userStore.setTime(time?.value);
    setSelectedTime(time?.value);
  };

  const getCurrentValue = () =>
    allOptions?.find((time) => time?.value === selectedTime)
      ? allOptions?.find((time) => time?.value === selectedTime)
      : selectedTime
      ? {
          label: `${selectedTime} ${addressTimezone ?? ""}`,
          value: selectedTime,
        }
      : "";

  return (
    <>
      <Select
        aria-labelledby={labelName ?? "time-picker"}
        aria-label={labelName ?? "time-picker"}
        ref={selectRef}
        menuPosition={fromModal ? "relative" : "fixed"}
        isLoading={timeOptionsLoading}
        onMenuOpen={onMenuOpen}
        isSearchable={false}
        styles={selectStylesDirectory({ backgroundColor })}
        isDisabled={!selectedDate}
        placeholder="Time"
        closeMenuOnSelect
        value={getCurrentValue()}
        isOptionDisabled={(option) => option.disabled}
        onChange={onChangeTime}
        options={allOptions}
        components={{ ValueContainer }}
        menuPlacement="auto" // Ensure the menu opens in the best possible position
        instanceId={id}
      />
    </>
  );
};
export default observer(TimePickerDropdown);
