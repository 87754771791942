import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "components/shared/ModalBackground";
import { capitalizeFirstLetter } from "utils/helpers/string";
import classNames from "classnames";

function ErrorAlert({ visible, errorMessage, dismissAlert }) {
  return (
    <div
      className={classNames({
        "fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-50":
          visible,
        hidden: !visible,
      })}
    >
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        style={{ width: "500px" }}
        aria-labelledby="modal-headline"
      >
        <div className="flex bg-white p-6 flex-col">
          <button
            onClick={() => {
              dismissAlert();
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              color={"#ADB4C3"}
              size="sm"
              className="absolute top-4 right-4"
            />
          </button>

          <p className="font-inter-medium">
            {capitalizeFirstLetter(errorMessage?.title)}
          </p>

          <p className="pt-4 font-inter-regular whitespace-pre-line text-xs text-dark-gray">
            {errorMessage?.body}
          </p>

          <div className="self-end mt-3">
            <button
              className="btn-purple px-10 py-2 text-xs"
              onClick={() => {
                dismissAlert();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorAlert;
