import React, { useState, useEffect } from "react";
import { SearchIcon } from "assets/img";

function debounce(f, ms) {
  let timer = null;

  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
}

const SearchBar = ({
  onSearch,
  width,
  placeholderValue,
  handleOnChange,
  value,
}) => {
  return (
    <div
      className={`flex items-center border-1 rounded-lg overflow-hidden bg-light-gray h-[48px] ${
        width ?? "w-[274px] "
      }`}
    >
      <input
        type="text"
        value={value}
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder={placeholderValue}
        className="px-4 py-2 w-full border-none outline-none bg-light-gray"
      />

      <button type="submit" className="p-2" onClick={() => onSearch()}>
        <img className="h-4 w-4 object-contain" src={SearchIcon} />
      </button>
    </div>
  );
};

export default SearchBar;
