import { observable, action, makeObservable } from "mobx";
import axios from "axios";
import { API_URL } from "../utils/constants/api";
import LocalStorage from "../utils/helpers/localStorage";
import { makePersistable, clearPersistedStore } from "mobx-persist-store";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";
import { toJS } from "mobx";
import moment from "moment-timezone";
import { timeFormat } from "utils/helpers/dateTime";

const initialCart = {
  data: null,
  items: [],
  restaurant: null,
  cutlery: false,
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  comment: "",
  phone: "",
  billAs: "individual",
  grandTotal: 0,
  tipAmount: "",
  stripeSessionId: null,
  employeeId: "",
  shipmentId: "",
  cartDate: null,
  cartTime: null,
  suite: null,
  streetNumber: null,
  streetName: null,
  city: null,
  province: null,
  postalCode: null,
  tipPercentage: null,
};

class CartStore {
  cart = initialCart;
  unCheckedOutOrder = null;
  cartMobile = false;

  constructor() {
    makePersistable(this, {
      name: "CartStore",
      properties: ["cart", "cartDate", "cartTime", "unCheckedOutOrder"],
      storage: window.localStorage,
    });
    makeObservable(this, {
      cart: observable,
      unCheckedOutOrder: observable,
      cartMobile: observable,
    });
  }

  async saveCartDetails(updatedValue) {
    let cart = toJS(this.cart);

    this.cart = {
      ...cart,
      ...updatedValue,
    };
  }

  async saveUncheckedOutOrder(payload) {
    return client()
      .post(`${api.unCheckedOutOrders()}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.unCheckedOutOrder = data?.order;
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async resetCart() {
    this.cart = initialCart;
    this.unCheckedOutOrder = null;
  }

  async getCartItems(id, saveDetails) {
    return client()
      .get(`${api.cart()}?uncheckedout_order_id=${id}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.cart.data = data?.data;
          this.cart.items = data?.order_items;
          this.cart.restaurant = {
            attributes: {
              code: data?.data?.attributes?.menu_code,
              name: data?.data?.attributes?.menu_display_name_odc,
              menu_id: data?.data?.attributes?.menu_id,
              restaurant_id: data?.data?.attributes?.restaurant_id,
              zone_id: data?.data?.attributes?.zone_id,
              tzinfo: data?.data?.attributes?.tzinfo,
            },
          };

          if (saveDetails) {
            this.saveCartDetails({
              firstName: data?.data?.attributes?.user_name?.split(" ")[0],
              lastName: data?.data?.attributes?.user_name?.split(" ")[1],
              phone: data?.data?.attributes?.phone,
              email: data?.data?.attributes?.email,
              billAs: data?.data?.attributes?.billable_as,
              company: data?.data?.attributes?.company_name,
              suite: data?.data?.attributes?.suite,
              city: data?.data?.attributes?.city,
              province: data?.data?.attributes?.province,
              postalCode: data?.data?.attributes?.postal_code,
              streetNumber: data?.data?.attributes?.street_number,
              streetName: data?.data?.attributes?.street_name,
              cutlery: data?.data?.attributes?.cutlery_required,
              comment: data?.data?.attributes?.delivery_instructions,
              tipPercentage: data?.data?.attributes?.tip_in_percentage,
              tipAmount: data?.data?.attributes?.tip_in_fixed_amount,
            });
          }

          if (data?.data?.attributes) {
            this.unCheckedOutOrder = {
              ...data?.data?.attributes,
              id: data?.data?.id,
            };

            this.cart.cartDate = data?.data?.attributes?.order_date;

            this.cart.cartTime = moment(data?.data?.attributes?.order_time)
              .utc()
              .format(timeFormat);
          }

          return data;
        }),
        action("fetchError", (error) => {
          if (error.response?.status == 404) {
            this.resetCart();
          } else {
            errorHandler(error?.response);
          }

          return error;
        })
      );
  }

  async addItemToCart(payload, unCheckedOutOrderPayload) {
    return client()
      .post(`${api.cart()}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          if (error.response?.status == 404) {
            this.saveUncheckedOutOrder(unCheckedOutOrderPayload);
          } else errorHandler(error?.response);
          return error;
        })
      );
  }

  async expireSession(id) {
    return client()
      .delete(`${api.stripeSessions()}/${id}`)
      .then(
        action("fetchSuccess", ({ data }) => {}),
        action("fetchError", (error) => {
          this.saveCartDetails({ stripeSessionId: null });
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async removeAllCartItems(unCheckedOutOrderId) {
    return client()
      .delete(
        `${api.cart()}?uncheckedout_order_id=${unCheckedOutOrderId}&remove_all_cart_items=true`
      )
      .then(
        action("fetchSuccess", ({ data }) => {
          this.cart = { ...this.cart, restaurant: null, items: [], data: null };
          this.unCheckedOutOrder = null;

          return data;
        }),
        action("fetchError", (error) => {
          if (error.response?.data?.errors == "No cart items found!") {
            this.resetCart();
          } else {
            errorHandler(error?.response);
          }

          return error;
        })
      );
  }

  async updateCartItem(id, payload) {
    return client()
      .put(`${api.cart()}/${id}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.getCartItems(this.unCheckedOutOrder?.id);
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async removeCartItem(id) {
    return client()
      .delete(`${api.cart()}/${id}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async updateCutlery(value) {
    this.cart.cutlery = value;
  }

  async setCartMobile(value) {
    this.cartMobile = value;
  }

  async getDeliveryFee(restaurantId, params) {
    let paramsString = qs.stringify(params);

    if (restaurantId)
      return client()
        .get(`${api.deliveryFee(restaurantId)}?${paramsString}`)
        .then(
          action("fetchSuccess", ({ data }) => {
            return data?.data;
          }),
          action("fetchError", (error) => {
            errorHandler(error?.response);
            return error;
          })
        );
  }

  async updateUncheckedOutOrder(id, payload) {
    return client()
      .put(`${api.unCheckedOutOrders()}/${id}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async createEmployee(payload) {
    return client()
      .post(`${api.odcEmployees()}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          if (error?.response?.data?.price_updated)
            this.getCartItems(payload.uncheckedout_order_id);

          errorHandler(error?.response);
          return error;
        })
      );
  }
}

const cartStore = new CartStore();
export default cartStore;
