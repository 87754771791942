import { useState, useContext, useCallback, useEffect } from "react";
import {
  NotificationBanner,
  WeeklyOrders,
  WeekPicker,
  SearchBar,
} from "components";
import { refreshIcon } from "assets/img";
import { ActiveMeals } from "./index";
import { MobXProviderContext, observer } from "mobx-react";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { useLocation, useNavigate } from "react-router-dom";

function Home() {
  const { mealManagementStore, homeStore, userStore } =
    useContext(MobXProviderContext);
  const defaultLocation = userStore.defaultLocation;
  const activeMeals = homeStore.activeMeals;
  const mealsByWeek = homeStore.mealsByWeek;
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDailyMeals(dayjs());
  }, []);

  useEffect(() => {
    const startDate = selectedDate.startOf("week").format("YYYY-MM-DD");
    const endDate = selectedDate.endOf("week").format("YYYY-MM-DD");

    homeStore.fetchActiveMealsForWeek(
      { start_date: startDate, end_date: endDate },
      defaultLocation?.organizationId
    );
  }, [selectedDate]);

  const fetchDailyMeals = (date = selectedDate) => {
    const startDate = date.format("YYYY-MM-DD");
    const endDate = date.format("YYYY-MM-DD");
    homeStore.fetchActiveMeals(
      { start_date: startDate, end_date: endDate },
      defaultLocation?.organizationId
    );
  };

  const onDateChange = (selected) => {
    setSelectedDate(selected);
    fetchDailyMeals(selected);
  };

  let isUnderReview = location?.state?.underReview;

  return (
    <div className="rounded-lg p-5 flex flex-col min-w-full">
      <NotificationBanner
        message={
          "Your Uncatering meal request is under review we will contact you as soon as it’s active."
        }
        messageVideoLink={"Watch a video about how Uncatering works!"}
      />

      {isUnderReview && (
        <NotificationBanner
          message="Your uncatering request is under review."
          messageType="warning"
          visible={isUnderReview}
          hideClose={true}
          messageLink={isUnderReview && "Contact us"}
          handleLinkClick={() => userStore.setFreshChatOpen(true)}
        />
      )}

      <div className="hidden lg:flex mt-[32px] flex-row justify-between">
        <WeekPicker dayPicker={true} onDateChange={onDateChange} />

        <button
          onClick={() => fetchDailyMeals()}
          className="flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6"
        >
          <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
          <small className="text-dark-gray font-inter-regular text-sm">
            Refresh
          </small>
        </button>
      </div>

      <div className="hidden lg:hidden md:block mt-[32px]  ">
        <div className="flex flex-row w-full">
          <SearchBar placeholderValue="Search" width="w-10/12" />

          <button className="flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6">
            <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
            <small className="text-dark-gray font-inter-regular text-sm">
              Refresh
            </small>
          </button>
        </div>
        <div className="mt-[32px]">
          <WeekPicker width="w-full" />
        </div>
      </div>

      <ActiveMeals data={activeMeals} />

      <div className="mt-[30px] d-row justify-between">
        <h2 className="font-inter-bold text-dark-gray text-[20px]">
          This week orders
        </h2>
        <button className="btn-blue-accent rounded-lg">
          Manage meal Plans
        </button>
      </div>

      <WeeklyOrders data={mealsByWeek} />
    </div>
  );
}

export default observer(Home);
