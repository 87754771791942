import { useContext, useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import { weekDays } from "utils/constants/onboardingStep";
import MyCalendar from "components/calendar";
import { isPresent } from "utils/helpers/array";
import userStore from "mobXstore/userStore";

function ChangeEnabledDays({ visible, setModal }) {
  const [expectedBags, changeExpectedBags] = useState("");
  const { mealManagementStore } = useContext(MobXProviderContext);
  const [mealDeliveryOption, setMealDeliveryOption] = useState("every week");

  const [mealStartDate, setMealStartDate] = useState("");
  const [mealSelectedDates, setMealSelectedDates] = useState([]);
  const [mealAllowanceType, setMealAllowanceType] = useState("");
  const [mealAllowanceValue, setMealAllowanceValue] = useState(0);
  const [enableOverpayment, setEnableOverpayment] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [mealRecievingDays, setMealRecievingDays] = useState([]);
  const calendarRef = useRef(null);

  const mealPlan = mealManagementStore.mealPlan;
  const locationID = userStore.locationID;

  const handleRecievingDaysClick = (value) => {
    if (mealRecievingDays.includes(value)) {
      setMealRecievingDays((prevDays) =>
        prevDays.filter((day) => day !== value)
      );
    } else {
      setMealRecievingDays((prevDays) => [...prevDays, value]);
    }
  };

  const onSavePress = () => {
    if (mealDeliveryOption === "every week")
      mealManagementStore
        .updateMealPlan(locationID, mealPlan?.id, {
          meal_series: {
            days: mealRecievingDays?.map((day) => day?.toLowerCase()),
          },
        })
        .then(() => setModal(false));
    else if (isPresent(mealSelectedDates)) {
      let datesToAdd = [];
      mealSelectedDates?.forEach((date) => {
        datesToAdd = [...datesToAdd, ...Object.values(date)[0]];
      });

      mealManagementStore
        .updateMealPlan(locationID, mealPlan?.id, {
          meal_series: {
            dates_to_add: datesToAdd,
          },
        })
        .then(() => setModal(false));
    }
  };

  const handleMultipleDateChange = (newDate) => {
    const monthName = newDate[0]?.month.name;

    const updatedMonthsArray = [...mealSelectedDates];

    if (newDate.length > 0) {
      const monthIndex = updatedMonthsArray.findIndex(
        (month) => Object.keys(month)[0] === monthName
      );

      if (monthIndex !== -1) {
        newDate.forEach((date, index) => {
          const formattedDate = `${date.day}-${date.month.number}-${date.year}`;

          if (
            !updatedMonthsArray[monthIndex][monthName].includes(formattedDate)
          ) {
            updatedMonthsArray[monthIndex][monthName].push(formattedDate);
          }
        });
      } else {
        updatedMonthsArray.push({
          [monthName]: newDate.map(
            (date) => `${date.day}-${date.month.number}-${date.year}`
          ),
        });
      }
      setMealSelectedDates(updatedMonthsArray);
    }
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-between items-center">
                  <h2 className="text-2xl font-inter-bold">Meal Schedule</h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <div className="mb-0.5 w-full xs:w-full-215 mt-[24px]">
                  <div className="lg:inline-flex md:inline-flex">
                    <div
                      className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                        mealDeliveryOption === "every week"
                          ? "bg-indigo-500 text-white "
                          : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                      } rounded-3xl justify-center gap-2 flex`}
                      onClick={() => setMealDeliveryOption("every week")}
                    >
                      Every week
                    </div>

                    <div
                      className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                        mealDeliveryOption === "i will set my own dates"
                          ? "bg-indigo-500 text-white "
                          : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                      } rounded-3xl justify-center gap-2 flex`}
                      onClick={() =>
                        setMealDeliveryOption("i will set my own dates")
                      }
                    >
                      I will set my own dates
                    </div>
                  </div>
                </div>

                {mealDeliveryOption === "every week" && (
                  <>
                    <small className="input-label mt-[24px]">
                      Select dates
                    </small>
                    <div className="mb-0.5 w-full xs:w-full-215 mt-[8px]">
                      <div className="lg:inline-flex md:inline-flex">
                        {weekDays.map((value, index) => (
                          <div
                            key={index}
                            className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                              mealRecievingDays.some((day) => day === value)
                                ? "bg-indigo-500 text-white "
                                : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                            } rounded-md justify-center gap-2 flex`}
                            onClick={() => handleRecievingDaysClick(value)}
                          >
                            {value?.slice(0, 3)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {mealDeliveryOption === "i will set my own dates" && (
                  <strong className="mt-[24px] font-inter-semibold text-[16px] mb-[12px]">
                    Pick your dates
                  </strong>
                )}

                {mealDeliveryOption === "i will set my own dates" && (
                  <div className="flex inline-flex gap-5 overflow-x-auto ">
                    <MyCalendar
                      highlightToday={false}
                      disableMonthYearPicker={true}
                      isMultiple={true}
                      showButtons={false}
                      handleMultipleDates={handleMultipleDateChange}
                      value={1}
                    />

                    <MyCalendar
                      highlightToday={false}
                      disableMonthYearPicker={true}
                      isMultiple={true}
                      showButtons={false}
                      handleMultipleDates={handleMultipleDateChange}
                      value={2}
                    />

                    <MyCalendar
                      highlightToday={false}
                      disableMonthYearPicker={true}
                      isMultiple={true}
                      showButtons={false}
                      handleMultipleDates={handleMultipleDateChange}
                      value={3}
                    />
                  </div>
                )}

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={onSavePress}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ChangeEnabledDays;
