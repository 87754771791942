const NotFound = (props) => {
  return (
    <div className="w-full flex justify-center content-center items-center">
      {!props.data?.length > 0 ? (
        <div className="p-5">
          <span className="font-inter-medium text-secondary">
            {props.message}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NotFound;
