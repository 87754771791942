import { Fragment, useEffect, useRef, useState } from "react";
import { AddMeal } from "alerts";
import dateTime from "utils/helpers/dateTime";
import classNames from "classnames";
import { toJS } from "mobx";
import { isPresent } from "utils/helpers/array";

function MealCard({ type, meal }) {
  return (
    <div className="px-[8px]">
      <div
        className={classNames({
          "h-[11px] w-full": true,
          "bg-catering": type == "odc",
          "bg-uncatering": type == "uncatering",
          "bg-group": type == "grouped",
        })}
      />

      <div className="bg-light-gray w-full flex flex-col px-[8px] py-[12px] mb-[12px]">
        <small className="text-uncatering capitalize">
          {type == "odc"
            ? "catering"
            : type == "grouped"
            ? "Group order"
            : "Uncatering"}
        </small>

        <strong className="text-dark-gray font-inter-semibold text-[16px]">
          {meal?.name}
        </strong>

        <span className="text-dark-gray text-[12px] font-inter-medium">
          Deadline to order:
          <span className="font-inter-semibold ml-1">
            {dateTime.formatTimeWithFormat(
              meal?.cutoff,
              "hh:mm a",
              meal?.tzinfo
            )}
          </span>
        </span>
        <span className="text-dark-gray text-[12px] font-inter-medium">
          Delivery time:
          <span className="font-inter-semibold ml-1">
            {dateTime.formatTimeWithFormat(
              meal?.target_time,
              "hh:mm a",
              meal?.tzinfo
            )}
          </span>
        </span>

        {type == "uncatering" && (
          <span className="text-dark-gray text-[12px] font-inter-medium">
            Expected orders:
            <span className="font-inter-semibold ml-1">
              {meal.expected_bags}
            </span>
          </span>
        )}

        {meal?.under_review && (
          <small className="font-inter-medium text-[12px] text-[#E39F55] pb-[4px]">
            Under review
          </small>
        )}

        {isPresent(meal?.shipments) && (
          <div className="p-[8px] bg-white flex flex-col rounded-lg">
            {meal?.shipments?.map((shipment) => (
              <small className="font-inter-medium text-[12px] text-dark-gray pb-[4px]">
                {shipment?.menu_name}
              </small>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default function WeeklyOrders({ data }) {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [addMeal, setAddMeal] = useState(false);

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current?.offsetHeight -
        containerOffset.current?.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  return (
    <div className="flex h-full flex-col">
      <div
        ref={container}
        className="isolate flex flex-auto flex-col overflow-auto bg-white"
      >
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div
            ref={containerNav}
            className="sticky top-0 bg-white flex-col items-center justify-center content-center"
          >
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500">
              {data?.map((day) => (
                <div
                  //   type="button"
                  className="flex flex-col w-full items-center pb-3 pt-2 border-r pb-[12px]"
                >
                  <span className="mt-1 flex h-8 items-center justify-center font-semibold text-gray-900">
                    {day.date}
                  </span>

                  <button
                    onClick={() => setAddMeal(true)}
                    type="button"
                    className="flex flex-col items-center mb-[12px] justify-center bg-sidebar-gray w-[144px] h-[38px] rounded-sm hover:bg-gray-200 mx-auto"
                  >
                    <span className="font-inter-regular text-dark-gray">
                      + Add meal
                    </span>
                  </button>
                  {day.meal?.map((meal) => (
                    <MealCard
                      type={meal?.service ?? "uncatering"}
                      meal={meal}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <AddMeal visible={addMeal} />
    </div>
  );
}
