import React, { useState, useEffect, useContext, useReducer } from "react";
import { AddCard, RemoveCard } from "alerts";
import { MobXProviderContext } from "mobx-react";
import {
  MasterCardIcon,
  VisaCardIcon,
  FileInputIcon,
  RadioActive,
  RadioInActive,
  DisabledRadioButton,
} from "assets/img";
import { observer } from "mobx-react";
import { NotificationBanner } from "components";
import { toJS } from "mobx";
import classNames from "classnames";
import InvoiceRequestCard from "alerts/invoiceRequestCard";

function MarketplacePaymentInfo() {
  const [addCard, setAddCard] = useState(false);
  const [removeCard, setRemoveCard] = useState(false);
  const [showSuccessCardMsg, setShowSuccessCardMsg] = useState(false);
  const [removeSingleCard, setRemoveSingleCard] = useState(false);
  const [removeSelectedCard, setRemoveSelectedCard] = useState(false);
  const [invoiceCard, setInvoiceCard] = useState(false);
  const [showInvoiceNotification, setShowInvoiceNotification] = useState(false);
  const [showPaymentNotification, setShowPaymentNotification] = useState(false);
  const [showExpiredCardMsg, setShowExpiredCardMsg] = useState(false);

  const initialState = {
    defaultCard: "",
    locationCreditCards: "",
    cardId: "",
    billingAccountId: "",
    enableInvoice: "disabled",
    billingDetails: {},
    expiredCardIds: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);

  let { locationID, currentEmployee } = userStore;

  let {
    defaultCard,
    locationCreditCards,
    cardId,
    enableInvoice,
    billingDetails,
    expiredCardIds,
  } = provider;

  useEffect(() => {
    store.userStore.setLoader(true);

    store.paymentInfoStore
      .getLocationCreditCards({ location_id: locationID })
      .then((response) => {
        dispatch({
          field: "locationCreditCards",
          value: response?.payment_methods,
        });

        dispatch({
          field: "defaultCard",
          value: response?.customer?.invoice_settings?.default_payment_method,
        });

        store.userStore.setLoader(false);
      })
      .catch(() => store.userStore?.setLoader(false));
  }, [locationID, addCard, removeCard]);

  useEffect(() => {
    dispatch({
      field: "enableInvoice",
      value: currentEmployee?.attributes?.invoice_payments,
    });

    dispatch({
      field: "billingDetails",
      value: {
        account_billing_address:
          currentEmployee?.attributes?.account_billing_address,
        account_billing_emails:
          currentEmployee?.attributes?.account_billing_emails,
        account_billing_name: currentEmployee?.attributes?.account_billing_name,
      },
    });
  }, [currentEmployee]);

  useEffect(() => {
    setShowPaymentNotification(
      enableInvoice == "disabled" && locationCreditCards == ""
    );
    setShowInvoiceNotification(enableInvoice == "requested");
  }, [enableInvoice, locationCreditCards]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const expiredCards = [];
    let isCurrentDefaultExpired = false;

    const nonExpiredCards = locationCreditCards?.data?.filter((data) => {
      const expMonth = data.card.exp_month;
      const expYear = data.card.exp_year;

      const isExpired =
        expYear < currentYear ||
        (expYear === currentYear && expMonth < currentMonth);

      if (isExpired) {
        expiredCards.push(data.id);
        if (data?.id === defaultCard) {
          isCurrentDefaultExpired = true;
        }
        return false;
      }

      return true;
    });

    dispatch({
      field: "expiredCardIds",
      value: expiredCards,
    });

    if (isCurrentDefaultExpired && nonExpiredCards?.length > 0) {
      handleDefaultStripeCard(nonExpiredCards[0]?.id);

      dispatch({
        field: "defaultCard",
        value: nonExpiredCards[0]?.id,
      });
    }
  }, [locationCreditCards, defaultCard]);

  useEffect(() => {
    setShowExpiredCardMsg(expiredCardIds?.length > 0);
  }, [expiredCardIds]);

  const handleDefaultStripeCard = (paymentID) => {
    store.paymentInfoStore.updateDefaultLocationCreditCards(paymentID, {
      location_id: locationID,
      default: true,
    });
  };

  const handleCardRemove = () => {
    store.userStore.setLoader(true);

    store.paymentInfoStore
      .removeLocationCreditCard({
        location_id: locationID,
        card_id: cardId,
      })
      .then((response) => {
        dispatch({
          field: "locationCreditCards",
          value: { data: response?.data },
        });

        store.userStore.setLoader(false);
      })
      .catch(() => store.userStore?.setLoader(false));

    dispatch({ field: "cardId", value: "" });
    setRemoveCard(false);
  };

  const handleRequestInvoiceAvailability = (reasonText) => {
    const payload = {
      location: {
        invoice_payments: "requested",
        invoice_payments_reason: reasonText,
      },
    };

    store.paymentInfoStore
      .requestInvoiceAvailability(locationID, payload)
      .then((response) => {
        dispatch({
          field: "enableInvoice",
          value: response?.data?.attributes.invoice_payments,
        });

        setInvoiceCard(false);
      })
      .catch(() => store.userStore.setLoader(false));
  };

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <NotificationBanner
        message="Please add a card or enable invoicing for your payment to be able to finalize your group order"
        messageType="danger"
        visible={showPaymentNotification}
        setVisible={setShowPaymentNotification}
      />

      <NotificationBanner
        message="Credit card information was successfully updated"
        messageType="success"
        textColor="success"
        visible={showSuccessCardMsg}
        setVisible={setShowSuccessCardMsg}
      />

      <NotificationBanner
        message="Your request has been sent. This message will disappear once changes have taken effect."
        messageLink="Contact us"
        messageType="warning"
        visible={showInvoiceNotification}
        setVisible={setShowInvoiceNotification}
        handleLinkClick={() => store.userStore.setFreshChatOpen(true)}
      />

      <NotificationBanner
        message="Credit card on file has expired. Please update your payment information or select a different card to avoid any interruptions in service."
        messageType="danger"
        visible={showExpiredCardMsg}
        setVisible={setShowExpiredCardMsg}
      />

      <h1 className="font-inter-bold text-heading text-[32px] mb-2">
        Payment Info
      </h1>

      <div className="text-slate-700 text-base font-inter-normal leading-tight">
        These are your current payment methods for group orders and catering
      </div>

      <div className="d-row justify-between mt-[56px]">
        <div className="flex flex-col gap-[12px]">
          <div className="relative self-stretch mt-[-1.00px] font-web-h5 font-[number:var(--web-h5-font-weight)] text-plain text-[length:var(--web-h5-font-size)] tracking-[var(--web-h5-letter-spacing)] leading-[var(--web-h5-line-height)] [font-style:var(--web-h5-font-style)] text-[20px] font-inter-semibold">
            Credit Card on file
          </div>

          <p className="relative w-fit [font-family:'Inter',Helvetica] font-normal text-plain text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
            <span className="font-text-plain font-[number:var(--text-plain-font-weight)] text-[#2d3958] text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              The card(s) on file will be available to all individuals with
              administrative privileges when placing an order
            </span>
          </p>
        </div>

        <button
          className="btn-blue-accent flex items-center"
          onClick={() => setAddCard(true)}
        >
          Add new card
        </button>
      </div>

      {locationCreditCards ? (
        locationCreditCards?.data?.map((data, index) => {
          const isSingleCard = locationCreditCards?.data?.length == 1;
          const isDefaultCard = data?.id == defaultCard;
          const isExpiredCard = expiredCardIds?.includes(data?.id);

          return (
            <div
              className={classNames(
                "rounded-lg mt-[24px] px-[12px] py-[16px] d-row justify-between",
                data?.id == defaultCard || isExpiredCard
                  ? "bg-light-gray"
                  : "bg-white border border-gray-300"
              )}
              key={index}
            >
              <div key={"photo"} className="w-full flex items-center">
                <div className="w-full flex justify-between">
                  <div className="d-row items-center ml-3">
                    <img
                      className="relative w-[40px] h-[40px]"
                      alt="Frame"
                      src={
                        data?.card?.display_brand == "visa"
                          ? VisaCardIcon
                          : MasterCardIcon
                      }
                    />

                    <div className="d-col ml-3">
                      <small
                        className={classNames(
                          "text-sm font-inter-medium",
                          isExpiredCard ? "text-orange-400" : "text-slate-700"
                        )}
                      >
                        **** {data?.card?.last4}{" "}
                        {isExpiredCard && (
                          <small className="text-orange-400 text-sm font-inter-medium italic">
                            Expired
                          </small>
                        )}
                      </small>

                      <div className="inline-flex gap-3">
                        <button
                          className="text-red-600 text-sm hover:text-red-800"
                          onClick={() => {
                            dispatch({ field: "cardId", value: data?.id });

                            if (isSingleCard && isDefaultCard) {
                              setRemoveSingleCard(true);
                            } else if (isDefaultCard) {
                              setRemoveSelectedCard(true);
                            } else {
                              setRemoveCard(true);
                            }
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className={classNames("flex items-center", {
                      "cursor-pointer": !isExpiredCard,
                    })}
                    onClick={() => {
                      if (!isExpiredCard) {
                        handleDefaultStripeCard(data?.id);
                        dispatch({
                          field: "defaultCard",
                          value: data?.id,
                        });
                      }
                    }}
                  >
                    <label
                      htmlFor={"photo"}
                      className="mr-3 block text-sm font-medium leading-6 text-secondary"
                    >
                      {data?.id == defaultCard ? "Selected" : ""}
                    </label>

                    <img
                      alt="radio-button"
                      src={
                        isExpiredCard && data?.id !== defaultCard
                          ? DisabledRadioButton
                          : data?.id == defaultCard
                          ? RadioActive
                          : RadioInActive
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="w-full flex-col justify-center items-center gap-6 inline-flex mt-4">
          <small className="text-slate-700 text-base font-inter-normal leading-tight">
            No cards added
          </small>
        </div>
      )}

      <div className="d-col justify-between mt-[56px]">
        <div className="flex flex-col gap-[12px]">
          <div className="relative self-stretch mt-[-1.00px] font-web-h5 font-[number:var(--web-h5-font-weight)] text-plain text-[length:var(--web-h5-font-size)] tracking-[var(--web-h5-letter-spacing)] leading-[var(--web-h5-line-height)] [font-style:var(--web-h5-font-style)] text-[20px] font-inter-semibold">
            Invoicing
          </div>
          <p className="relative w-fit [font-family:'Inter',Helvetica] font-normal text-plain text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
            <span className="font-text-plain font-[number:var(--text-plain-font-weight)] text-[#2d3958] text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              Select option to enable invoicing for payments
            </span>
          </p>
        </div>

        {enableInvoice == "enabled" ? (
          <div className="border border-zinc-200 rounded-lg mt-[24px] px-[12px] py-[16px] d-row justify-between">
            <div className="d-row items-center">
              <img
                className="relative w-[40px] h-[40px]"
                alt="Frame"
                src={FileInputIcon}
              />
              <div className="d-col ml-2 gap-[4px]">
                <small className="text-sm text-dark-gray font-inter-medium">
                  Invoice
                </small>

                <small className="text-slate-500 text-sm font-inter-normal leading-[14px] tracking-tight">
                  {billingDetails?.account_billing_name}
                </small>

                <small className="text-slate-500 text-sm font-inter-normal leading-[14px] tracking-tight">
                  {billingDetails?.account_billing_address}
                </small>

                <small className="text-slate-500 text-sm font-inter-normal leading-[14px] tracking-tight">
                  {billingDetails?.account_billing_emails.map(
                    (email, index) => {
                      return <p className="mb-1">{email}</p>;
                    }
                  )}
                </small>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full flex-col justify-center items-center gap-6 inline-flex mt-4">
              <small className="text-slate-700 text-base font-inter-normal leading-tight">
                Your account does not currently have invoicing enabled and can
                only charge by credit card
              </small>
            </div>

            <div className="d-row flex-row-reverse mt-[24px]">
              <button
                className={classNames({
                  "btn-blue-accent": true,
                  "bg-zinc-200 text-white": enableInvoice == "requested",
                })}
                disabled={enableInvoice == "requested"}
                onClick={() => setInvoiceCard(true)}
              >
                Request invoice availibility
              </button>
            </div>
          </>
        )}
      </div>

      {addCard && (
        <AddCard
          visible={addCard}
          setModal={setAddCard}
          add={true}
          rightClick={() => {
            setAddCard(false);
            setShowSuccessCardMsg(true);
          }}
        />
      )}

      {invoiceCard && (
        <InvoiceRequestCard
          visible={invoiceCard}
          setModal={setInvoiceCard}
          rightClick={handleRequestInvoiceAvailability}
        />
      )}

      {removeCard && (
        <RemoveCard
          visible={removeCard}
          setModal={setRemoveCard}
          title="Remove card"
          message="Are you sure you want to delete this card?"
          leftButtonText="Cancel"
          rightButtonText="Delete"
          leftClick={setRemoveCard}
          rightClick={() => {
            handleCardRemove();
          }}
        />
      )}

      {removeSelectedCard && (
        <RemoveCard
          visible={removeSelectedCard}
          setModal={setRemoveSelectedCard}
          title="You can’t remove your selected card"
          message="Please select another card first"
          leftButtonText="Back"
          rightButtonText="Add new card"
          leftClick={setRemoveSelectedCard}
          rightClick={() => {
            setRemoveSelectedCard(false);
            setAddCard(true);
          }}
        />
      )}

      {removeSingleCard && (
        <RemoveCard
          visible={removeSingleCard}
          setModal={setRemoveSingleCard}
          title="You can’t remove your only payment method"
          message="Please add another card first"
          leftButtonText="Back"
          rightButtonText="Add new card"
          leftClick={setRemoveSingleCard}
          rightClick={() => {
            setRemoveSingleCard(false);
            setAddCard(true);
          }}
        />
      )}
    </div>
  );
}

export default observer(MarketplacePaymentInfo);
