import React, { useState } from "react";
import { OnboardingSteps } from "components";
import { NotFound } from "components";

function AddUncateringMeals() {
  let activeMeals = null;

  return (
    <div className="w-full">
      <OnboardingSteps />
    </div>
  );
}

export default AddUncateringMeals;
