import icon from "utils/helpers/icon";

function DietaryTag({ tag, styles }) {
  return (
    <div className="flex bg-white py-1 mr-2 my-1 px-2 whitespace-pre-line rounded-xl items-center justify-center">
      {icon.getBadgeIcon(tag) && (
        <img
          src={icon.getBadgeIcon(tag)}
          className="icon"
          layout="fixed"
          alt={`${tag}-dietary`}
        />
      )}

      <span
        className={`${styles.dietary_tag} font-inter-medium text-xs ${
          icon.getBadgeIcon(tag) && "ml-2"
        }`}
      >
        {tag}
      </span>
    </div>
  );
}

export default DietaryTag;
