import React, { useState, useEffect, useRef } from "react";
import DesktopTimepicker from "components/timepicker/DesktopTimepicker";
import AndroidTimepicker from "components/timepicker/AndroidTimepicker";
import IosPicker from "components/timepicker/IosTimepicker";

const TimePicker = ({ ideal_time, datetime, meal_name }) => {
  const [isIos, setIsIos] = useState(false);
  const [show, setShow] = useState(false);
  const [isDivClicked, setIsDivClicked] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
        setIsDivClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDivClicked) setShow(true);
  }, [isDivClicked]);

  const handleDivClicked = (value) => {
    setIsDivClicked(value);
  };

  return (
    <>
      <div className="hidden screen-lg:flex screen-md:flex">
        <DesktopTimepicker
          timeValue={ideal_time}
          onTimeSelected={datetime}
          mealName={meal_name}
        />
      </div>

      {isIos && (
        <div
          className="w-full screen-lg:hidden screen-md:hidden screen-sm:flex"
          ref={popupRef}
        >
          <IosPicker
            openModal={show}
            timeValue={ideal_time}
            onTimeSelected={datetime}
            mealName={meal_name}
            handleDivClick={handleDivClicked}
          />
        </div>
      )}

      {!isIos && (
        <div className="w-full screen-lg:hidden screen-md:hidden screen-sm:flex">
          <AndroidTimepicker
            timeValue={ideal_time}
            onTimeSelected={datetime}
            mealName={meal_name}
          />
        </div>
      )}
    </>
  );
};

export default TimePicker;
