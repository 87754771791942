import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";

function RemoveCard({
  visible,
  setModal,
  title,
  message,
  leftClick,
  rightClick,
  leftButtonText,
  rightButtonText,
  users,
  mealPlanId,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { mealManagementStore } = useContext(MobXProviderContext);

  const applyPressed = () => {};

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-2/5 md:w-[696px] w-[320px] lg:h-[260px] md:h-[481px] h-[260px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-col gap-3 px-8">
                  <div className="d-row flex justify-between items-center">
                    <div className="text-2xl font-inter-bold lg:hidden md:flex sm:flex">
                      <p>{title}</p>
                    </div>
                    <div className="lg:w-full flex flex-row-reverse">
                      <button
                        onClick={() => setModal(false)}
                        className="text-dark-gray"
                        aria-label="Close"
                      >
                        <span className="text-xl">&times;</span>
                      </button>
                    </div>
                  </div>

                  <div className="lg:flex flex-col lg:justify-center lg:items-center lg:align-middle gap-3">
                    <div className="text-slate-700 text-2xl font-inter-bold hidden lg:flex">
                      <h2>{title}</h2>
                    </div>
                    <div className="text-slate-700 text-base font-inter-normal leading-tight">
                      <p>{message}</p>
                    </div>
                  </div>
                </div>

                {/* <div className="hidden md:flex w-full h-[0px] border border-zinc-200 mt-72" /> */}

                <div className="d-row w-full items-center md:mt-[24px] justify-center px-8">
                  <button
                    className="btn-light btn-style py-3 px-5 lg:w-[98px] md:w-[197.50px]"
                    onClick={() => leftClick(false)}
                  >
                    {leftButtonText}
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3 lg:w-auto md:w-[197.50px]"
                    onClick={rightClick}
                  >
                    {rightButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RemoveCard;
