import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/assets/stylesheets/global.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MobXProviderContext } from "mobx-react";
import onboardingStore from "mobXstore/onboardingStore";
import mealManagementStore from "mobXstore/mealManagementStore";
import homeStore from "mobXstore/homeStore";
import userStore from "mobXstore/userStore";
import deliveryInfoStore from "mobXstore/deliveryInfoStore";
import paymentInfoStore from "mobXstore/paymentInfoStore";
import restaurantStore from "mobXstore/restaurantStore";
import cartStore from "mobXstore/cartStore";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const stores = {
  onboardingStore,
  mealManagementStore,
  userStore,
  deliveryInfoStore,
  paymentInfoStore,
  restaurantStore,
  cartStore,
  homeStore,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MobXProviderContext.Provider value={stores}>
        {/* <BrowserRouter> */}
        <App />
        {/* </BrowserRouter> */}
      </MobXProviderContext.Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
