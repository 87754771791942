import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowDownIcon } from "assets/img";

const DPicker = ({
  selectedDate,
  dateFormat = "MMMM d, yyyy",
  className,
  pickerClassName,
  minDate,
  activeRestaurant,
  availableDates,
  getAvailableDates,
  getAvailableTimes,
  fromModal,
  internalAddress,
  setSelectedDate,
  isGroupOrder,
  placeholderText = "Select Date",
  showIcon = true,
  iconPadding = true,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div
      className={classNames(className, { "cursor-pointer": true })}
      onClick={() => setOpened(true)}
    >
      <DatePicker
        // open={opened}
        forceShowMonthNavigation={true}
        includeDates={availableDates?.map((date) => moment(date).toDate())}
        onMonthChange={(date) => {
          getAvailableDates(internalAddress, date);
        }}
        onCalendarOpen={() => {
          if (selectedDate) getAvailableDates(internalAddress, selectedDate);
        }}
        placeholderText={placeholderText}
        selected={selectedDate ? new Date(moment(selectedDate)) : ""}
        dateFormat={dateFormat}
        onFocus={(e) => e.target.blur()}
        minDate={minDate}
        onClickOutside={() => setOpened(false)}
        onChange={(date) => {
          setSelectedDate(moment(date).format("YYYY-MM-DD"));
          getAvailableTimes(internalAddress, date);
        }}
        className={`placeholder-[#2D3958] text-[#2D3958] rounded-md pl-2 py-1 bg-light-gray cursor-pointer text-left focus:outline-none ${pickerClassName} ${
          pickerClassName ? "" : "font-inter-regular"
        }`}
        formatWeekDay={(nameOfDay) =>
          nameOfDay == "Thursday" ? nameOfDay.slice(0, 2) : nameOfDay.charAt(0)
        }
      />

      {!showIcon && (
        <img
          src={ArrowDownIcon}
          alt="arrow-down"
          className={`${iconPadding ? "pr-8" : ""}`}
        />
      )}

      {showIcon && (
        <div className="bg-light-gray overflow-hidden rounded-md">
          <FontAwesomeIcon
            icon={faCalendar}
            color={"#2D3958"}
            size="1x"
            className="m-1.5 small-icon overflow-hidden"
          />
        </div>
      )}
    </div>
  );
};

export default observer(DPicker);
