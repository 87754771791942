const ModalBackground = () => {
  return (
    <div className="fixed inset-0 transition-opacity pin">
      <div
        style={{ opacity: 0.5 }}
        className="modal-overlay absolute w-full h-full bg-black"
      />
    </div>
  );
};

export default ModalBackground;
