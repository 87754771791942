import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faUpload } from "@fortawesome/free-solid-svg-icons";
import { NotFound, SearchBar, DatePicker, RestaurantCard } from "components";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { UploadCSV, CreateTeam } from "alerts";

function UncateringTeam() {
  const [uploadCSV, setUploadCSV] = useState(false);
  const [createTeam, setCreateTeam] = useState(false);

  const teams = [
    {
      label: "All",
      selected: true,
    },

    {
      label: "Toronto Permanent Employee Dash",
      selected: false,
    },
    {
      label: "Team 1",
      selected: false,
    },
    {
      label: "Team 2",
      selected: false,
    },
  ];

  const locEmployees = [];

  return (
    <div class="flex-col w-full justify-start items-start py-[24px] px-[32px]">
      <div class="justify-start items-center">
        <div class="justify-start items-center gap-4 flex">
          {teams?.map((team) => (
            <button
              className={classNames({
                "text-heading font-inter-medium text-[16px] p-[12px] rounded-lg": true,
                "bg-background": team?.selected,
              })}
            >
              {team?.label}
            </button>
          ))}

          <button
            onClick={() => {
              setCreateTeam(true);
            }}
            class="btn-outline bg-background border-0"
          >
            + Create new team
          </button>
        </div>
      </div>

      <div class="flex-col justify-start items-start mt-[53px] w-full">
        <div class="d-row justify-between w-full">
          <div class="font-inter-semibold text-[20px] text-dark-gray">
            Users
          </div>

          <div class="justify-end items-center gap-3 flex">
            <div class="justify-end items-center gap-3 flex">
              <button
                class="btn-light-gray d-row justify-center items-center text-heading text-[16px]"
                onClick={() => setUploadCSV(true)}
              >
                <FontAwesomeIcon
                  icon={faFolderOpen}
                  size="1x"
                  className="mr-2"
                />
                Bulk upload users
              </button>

              <button class="btn-light-gray d-row justify-center items-center text-heading text-[16px]">
                <FontAwesomeIcon icon={faUpload} size="1x" className="mr-2" />
                Export user data
              </button>

              <button className="btn-light-gray text-[16px] text-white bg-catering">
                + Add users
              </button>
            </div>
          </div>
        </div>

        <div class="mt-[24px] flex-col items-start gap-6 flex">
          <div class="self-stretch justify-between items-center inline-flex">
            <div class="justify-end items-center gap-2 flex">
              <SearchBar width="w-[360px]" placeholderValue="Search" />
              <div class="text-heading font-inter-normal text-sm">
                Filter by:
              </div>
              <Select
                styles={customStylesSubsidy}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Meal"
                closeMenuOnSelect
                //   value={sortBy}
                //   onChange={onChange}
                //   options={sortByOptions}
                className="min-w-[106px]"
              />
              <Select
                styles={customStylesSubsidy}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Sent"
                closeMenuOnSelect
                //   value={sortBy}
                //   onChange={onChange}
                //   options={sortByOptions}
                className="min-w-[106px]"
              />

              <Select
                styles={customStylesSubsidy}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Role"
                closeMenuOnSelect
                //   value={sortBy}
                //   onChange={onChange}
                //   options={sortByOptions}
                className="min-w-[106px] text-sm font-inter-regular"
              />
            </div>

            <button class="btn-light-gray d-row justify-center items-center font-inter-regular w-auto px-[16px] text-heading text-[16px]">
              X Clear Filters
            </button>
          </div>

          <div className="mt-8 w-full flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr className="bg-light-gray">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                      ></th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                      >
                        First name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Last name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Meal
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Team
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Role
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white">
                    {locEmployees?.map((person) => (
                      <tr key={person?.id} className="even:bg-light-gray">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox text-blue-600 h-4 w-4"
                              onChange={(e) => {
                                // onEmployeeChecked(e, person);
                              }}
                            />
                          </label>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                          {person?.attributes?.first_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {person?.attributes?.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {person?.attributes?.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {person?.attributes?.team?.name ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {person?.attributes?.subsidy_type ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray"></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadCSV visible={uploadCSV} setModal={setUploadCSV} />
      <CreateTeam visible={createTeam} setModal={setCreateTeam} />
    </div>
  );
}

export default UncateringTeam;
