import { useEffect, useRef } from "react";

import { useMediaQuery } from "react-responsive";

function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
}

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1151 });
  const isMounted = useIsMounted();

  return isDesktop && isMounted ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1150 });
  const isMounted = useIsMounted();

  return isMounted && isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 481 });

  return isMobile ? children : null;
};

function ScreenType() {
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 482px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" });
  const isDesktop = useMediaQuery({
    query: "(min-width: 1151px)",
  });
  const isTablet = useMediaQuery({ minWidth: 482, maxWidth: 1150 });

  return { isTabletOrDesktop, isMobile, isTabletOrMobile, isDesktop, isTablet };
}

export default { Desktop, Mobile, Tablet, ScreenType };
