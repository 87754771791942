import { isEmpty, isPresent } from "utils/helpers/array";

export function useEarliestDate() {
  const earliestDate = (mealSelectedDates) => {
    if (isEmpty(mealSelectedDates)) return null;
    else {
      let earliest = Number.MAX_SAFE_INTEGER; // Set initial earliest date to a very large value
      for (const monthObj of mealSelectedDates) {
        if (monthObj) {
          const dates = Object.values(monthObj)[0]; // Get the array of dates for the current month
          if (dates && isPresent(dates)) {
            const monthEarliestDate = Math.min(
              ...dates.map((dateStr) => {
                let date = dateStr.split("-");

                const day = parseInt(date[0]);
                const month = parseInt(date[1]);
                const year = parseInt(date[2]);

                return new Date(year, month - 1, day).getTime();
              })
            );

            earliest = Math.min(earliest, monthEarliestDate);
          }
        }
      }
      if (earliest !== Number.MAX_SAFE_INTEGER) return new Date(earliest);
    }
  };
  return { earliestDate };
}
