const SubmitButtons = ({
  leftButtonText,
  rightButtonText,
  onClickLeft,
  leftButtonClassName = 'btn-light py-3 px-5',
  rightButtonClassName = 'btn-purple py-3 px-6 ml-3',
}) => (
  <div className="flex justify-center">
    <button type="button" className={leftButtonClassName} onClick={onClickLeft}>
      {leftButtonText}
    </button>

    <button type="submit" className={rightButtonClassName}>
      {rightButtonText}
    </button>
  </div>
);

export default SubmitButtons;
