import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { MobXProviderContext } from "mobx-react";
import "react-phone-number-input/style.css";
import { UncateringMealSuccess } from "assets/img";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { formattedAddres } from "utils/helpers/address";
import moment from "moment-timezone";

const Step6 = () => {
  const store = useContext(MobXProviderContext);
  const onboardingStore = toJS(store?.onboardingStore);
  const { mealPlan } = onboardingStore;

  let navigate = useNavigate();
  let address = formattedAddres(mealPlan?.address.attributes);

  function convertToAMPM(target_time) {
    const [hours, minutes] = target_time.split(":");
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minutes} ${ampm}`;
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className="pb-4 items-center justify-center flex d-col">
      <img src={UncateringMealSuccess} alt="uncatering-img" />

      <div className="w-1/2 text-center text-[#2d3958] text-2xl font-inter-semibold mt-[24px]">
        Thank you !<br />
        Your new Uncatering meal request has been submitted successfully. Our
        team will review the details and notify you as soon as it is activated.
      </div>

      <div className="w-[705px] px-4 pt-4 pb-6 bg-white rounded-xl border border-[#dee2e6] flex-col justify-start items-start gap-8 inline-flex mt-[24px]">
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch text-center text-[#3a3a3a] text-base font-inter-medium leading-tight">
            Uncatering meal details
          </div>

          <div className="text-[#3a3a3a] text-base font-inter-medium leading-tight capitalize">
            {mealPlan?.meal_name}
          </div>

          <div>
            <span className="text-[#3a3a3a] text-base font-inter-medium leading-tight">
              Delivery Address:
            </span>

            <span className="text-[#3a3a3a] text-base ffont-inter-normal leading-tight">
              {" "}
              {address}
            </span>
          </div>

          <div className="w-[476px]">
            <span className="text-[#3a3a3a] text-base font-inter-medium leading-tight">
              Start Date:
            </span>

            <span className="text-[#3a3a3a] text-base font-inter-normal leading-tight">
              {" "}
              {moment(mealPlan?.startDate).format("dddd, MMMM Do, YYYY")}
            </span>
          </div>

          <div className="w-[396px]">
            <span className="text-[#3a3a3a] text-base font-inter-medium leading-tight">
              Delivery Time:{" "}
            </span>

            <span className="text-[#3a3a3a] text-base font-inter-normal leading-tight">
              {convertToAMPM(mealPlan?.deliveryTime)}
            </span>
          </div>
        </div>
      </div>

      <button
        onClick={() =>
          navigate("/home", { state: { underReview: mealPlan?.underReview } })
        }
        className={classNames(
          "mt-[24px] bg-indigo-900 screen-lg:w-[232px] screen-md:w-[232px] w-full screen-lg:h-16 screen-md:h-16 h-[46px] rounded-md text-white text-sm screen-lg:left-11 screen-lg:bottom-12 screen-md:left-11 screen-md:bottom-12 font-inter-medium"
        )}
      >
        Go Back Home
      </button>
    </div>
  );
};

export default Step6;
