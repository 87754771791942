import { makeAutoObservable, observable, action } from "mobx";
import { client } from "../store";
import api from "store/api";
import userStore from "./userStore";
import qs from "qs";
import { generateId } from "../utils/helpers/validation";
import { errorHandler } from "utils/middlewares/errorHandler";
import moment from "moment-timezone";
import { makePersistable } from "mobx-persist-store";

class MealManagementStore {
  mealPlans = [];
  mealPlan = {};
  locationEmployees = [];
  teams = [];
  groupOrders = [];
  groupOrder = null;
  cuisineTypes = [];
  dietaryPreferences = [];
  meals = [];
  organisedMeals = [];
  cateringOrders = [];

  orderDeadline = null;
  orderDeadlineTime = null;
  currentGroupOrder = null;

  constructor() {
    makePersistable(this, {
      name: "MealManagementStore",
      properties: ["orderDeadline", "orderDeadlineTime", "currentGroupOrder"],
      storage: window.localStorage,
    });
    makeAutoObservable(this, {
      mealPlans: observable,
      mealPlan: observable,
      locationEmployees: observable,
      teams: observable,
      groupOrders: observable,
      groupOrder: observable,
      dietaryPreferences: observable,
      cuisineTypes: observable,
      meals: observable,
      organisedMeals: observable,
      cateringOrders: observable,
      orderDeadline: observable,
      orderDeadlineTime: observable,
      currentGroupOrder: observable,

      fetchMealPlans: action,
      fetchMealPlan: action,
      updateMealPlan: action,
      sendDeliveryTimeRequest: action,
      fetchTeams: action,
      fetchGroupOrders: action,
      fetchCateringOrders: action,
      fetchCateringOrder: action,
      cancelOrder: action,
      fetchGroupOrderDetails: action,
      updateUncheckedOutOrder: action,
      fetchEmployees: action,
      bulkSubsidyUpdate: action,
      fetchCuisineTypesData: action,
      getMeals: action,
      fetchGroupOrder: action,
      updateGroupOrder: action,
      groupOrderCheckout: action,
      fetchOrderDeadline: action,
      setOrderDeadline: action,
      setOrderDeadlineTime: action,
      // createGroupOrder: action,
      setCurrentGroupOrder: action,
    });
  }

  async fetchMealPlans(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.mealPlans()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.mealPlans = response?.data?.data;
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  async fetchMealPlan(locationId, id) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.mealPlans()}/${id}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.mealPlan = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateMealPlan(locationId, mealPlanId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.mealPlans()}/${mealPlanId}?location_id=${locationId}`,
        payload
      );
      userStore.setLoader(false);
      this.mealPlan = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async sendDeliveryTimeRequest(locationId, mealPlanId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.mealPlans()}/${mealPlanId}?location_id=${locationId}`,
        payload
      );
      userStore.setLoader(false);
      this.mealPlan = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async fetchTeams(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.teams()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.teams = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async fetchGroupOrders(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}?location_id=${locationId}&type=group`
      );
      userStore.setLoader(false);
      this.groupOrders = response?.data?.data;
    } catch (error) {}
  }

  async fetchCateringOrders(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}?location_id=${locationId}&type=odc`
      );

      userStore.setLoader(false);
      this.cateringOrders = response?.data?.data;
    } catch (error) {}
  }

  async fetchCateringOrder(orderSlug, locationId) {
    const response = await client().get(
      `${api.odcOrder()}/${orderSlug}?location_id=${locationId}`
    );
    return response?.data?.data;
  }

  async cancelOrder(orderId) {
    const response = await client().delete(`${api.odcOrder()}/${orderId}`);

    return response?.data?.data;
  }

  async fetchGroupOrderDetails(locationId, id) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}/${id}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.groupOrder = response?.data?.data;
      return response;
    } catch (error) {}
  }

  async updateUncheckedOutOrder(id, slug, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.uncheckedOutOrder()}/${id}?slug=${slug}`,
        payload
      );
      userStore.setLoader(false);
      return response;
    } catch (error) {}
  }

  async fetchEmployees(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.employees()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.locationEmployees = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async bulkSubsidyUpdate(locationId, mealPlanId, payload, bulkUsers) {
    let userIds = qs.stringify(
      {
        ids: bulkUsers,
      },
      { arrayFormat: "brackets" }
    );

    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.bulkSubsidyUpdate()}/?location_id=${locationId}&${userIds}`,
        payload
      );
      userStore.setLoader(false);
      this.fetchMealPlan(locationId, mealPlanId);
    } catch (error) {
      throw error;
    }
  }

  async fetchCuisineTypesData(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.adminPanelDietaryPreference()}?location_id=${locationId}`
      );

      const cuisineArray = response.data?.cuisines;
      const preferencesArray = response.data?.dietary_preferences;

      this.cuisineTypes = cuisineArray;
      this.dietaryPreferences = preferencesArray;
      userStore.setLoader(false);
    } catch (error) {
      throw error;
    }
  }

  async getMeals(params, locationId) {
    let _params = qs.stringify(params);
    try {
      userStore.setLoader(true);
      let sortedData = [];
      const data = await client().get(
        `${api.meals()}?location_id=${locationId}&${_params}`
      );
      this.meals = data?.data;

      data?.data?.data?.map(
        ({
          attributes: {
            id,
            name,
            meal_date,
            cutoff,
            expected_bags,
            meal_series_id,
            menus,
            shipments,
            target_time,
            meal_series_cutoff,
            meal_series_expected_bags,
            meal_series_target_time,
            disabled,
            restaurant_slots,
            manual_restaurant_slots,
            published,
            meal_series_manual_restaurant_slots,
            meal_series_slots,
            meal_series_restaurant_slots,
            slots,
            new_client,
            active,
          },
        }) => {
          let foundIndex = sortedData.findIndex((x) => x.date === meal_date);

          if (foundIndex > -1) {
            let meal = sortedData[foundIndex].meal;
            sortedData[foundIndex].meal = [
              ...meal,
              {
                id,
                tempId: id ? id : generateId(),
                name,
                meal_date,
                cutoff,
                expected_bags,
                meal_series_id,
                menus,
                shipments,
                target_time,
                meal_series_cutoff,
                meal_series_expected_bags,
                meal_series_target_time,
                disabled,
                restaurant_slots,
                manual_restaurant_slots,
                published,
                meal_series_manual_restaurant_slots,
                meal_series_slots,
                meal_series_restaurant_slots,
                slots,
                new_client,
                active,
              },
            ];
          } else {
            sortedData.push({
              date: meal_date,
              meal: [
                {
                  id,
                  tempId: id ? id : generateId(),
                  name,
                  meal_date,
                  cutoff,
                  expected_bags,
                  meal_series_id,
                  menus,
                  shipments,
                  target_time,
                  meal_series_cutoff,
                  meal_series_expected_bags,
                  meal_series_target_time,
                  disabled,
                  restaurant_slots,
                  manual_restaurant_slots,
                  published,
                  meal_series_manual_restaurant_slots,
                  meal_series_slots,
                  meal_series_restaurant_slots,
                  slots,
                  new_client,
                  active,
                },
              ],
            });
          }
        }
      );

      this.organisedMeals = sortedData.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });

      // this.setUpdatedResponse(
      //   sortedData.sort(function (a, b) {
      //     return new Date(a.date) - new Date(b.date);
      //   })
      // );

      userStore.setLoader(false);
      return data?.data?.data;
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  //home page actions

  // async fetchGroupOrder(groupOrderId, locationId) {
  //   const response = await client().get(
  //     `${api.groupOrder()}/${groupOrderId}?location_id=${locationId}`
  //   );
  //   return response?.data?.data;
  // }

  async updateGroupOrder(groupOrderId, payload, params) {
    let _params = qs.stringify(params);

    const response = await client().patch(
      `${api.groupOrder()}/${groupOrderId}?${_params}`,
      payload
    );
    return response?.data?.data;
  }

  async groupOrderCheckout(groupOrderId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.groupOrder()}/${groupOrderId}/checkout?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async fetchOrderDeadline(menuId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.groupOrderDeadline()}/${menuId}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.setOrderDeadline(
            moment
              .utc(data?.order_deadline)
              .format("h:mm A dddd, MMMM Do, YYYY")
          );
          this.setOrderDeadlineTime(data?.order_deadline);
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response, null, "Restaurant minimum not met");
          return error;
        })
      );
  }

  async generatePDF(orderSlug) {
    userStore.setLoader(true);

    return client()
      .get(`${api.odcOrder()}/${orderSlug}.pdf`, { responseType: "blob" })
      .then(
        action("fetchSuccess", (response) => {
          let newBlob = new Blob([response.data], { type: "application/pdf" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.download = `${orderSlug}.pdf`;
          link.click();
          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 100);

          userStore.setLoader(false);
          // this.setErrorAlert(true, {
          //   title: "Invoice generated successfully",
          // });
        }),
        action("fetchError", (error) => {
          // this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async setOrderDeadline(value) {
    this.orderDeadline = value;
  }

  async setOrderDeadlineTime(value) {
    this.orderDeadlineTime = value;
  }

  async createGroupOrder(payload, params) {
    let paramsString = qs.stringify(params);
    try {
      userStore.setLoader(true);
      const response = await client().post(
        `${api.groupOrder()}?${paramsString}`,
        payload
      );
      userStore.setLoader(false);
      this.currentGroupOrder = response?.data;
      return response?.data;
    } catch (error) {
      errorHandler(error?.response);
    }
  }

  async setCurrentGroupOrder(orderDetails) {
    this.currentGroupOrder = orderDetails;
  }

  async fetchGroupOrder(id, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.groupOrder()}/${id}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.currentGroupOrder = data?.data;

          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }
}

const mealManagementStore = new MealManagementStore();
export default mealManagementStore;
