import { useCallback } from "react";

export const useMenuItemSelection = () => {
  const initRequiredCheck = useCallback((menuItem, dispatch) => {
    let optionsTypes = []; //option types
    let options = menuItem?.option_categories;
    // Create optionTypes object so that options can separated into their own groups
    // to account for different limits of options per group

    options?.forEach((option) => {
      optionsTypes = Object.assign({}, optionsTypes, {
        [option.id]: [],
      });
    });

    // The following is to check a radio button if the option is required
    options?.forEach((optionType) => {
      // Set required for radio buttons
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        optionType.attributes.choose_upto === 1
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          (option) => option?.attributes?.availability === "active"
        );
        if (activeOptions?.length > 0)
          optionsTypes[optionType.id] = activeOptions[0].id;
      }
      // Set required for limited checkbox menu items
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        (optionType.attributes.choose_upto >= 2 ||
          optionType.attributes.choose_upto === 0)
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          (option) => option?.attributes?.availability === "active"
        );
        if (activeOptions?.length > 0)
          optionsTypes[optionType.id] = [activeOptions[0].id];
      }
    });

    dispatch({
      field: "optionTypes",
      value: optionsTypes,
    });
  });

  const setSelectedOptions = useCallback(
    (editItem, optionsField, dispatch, index) => {
      let tempOptionTypes = {};

      editItem?.attributes?.[optionsField]?.forEach((option) => {
        const categoryId = option.attributes.option_category_id;

        if (!tempOptionTypes[categoryId]) {
          tempOptionTypes[categoryId] = {
            option_ids: [],
            orderItemOptionId: option?.id,
          };
        }

        tempOptionTypes[categoryId].option_ids.push({
          option_id: option.attributes.option_id.toString(),
          option_group_id: categoryId,
          id: option?.id,
          _destroy: false,
        });

        tempOptionTypes[categoryId].orderItemOptionId = option?.id;

        dispatch({
          field: "optionTypes",
          value: tempOptionTypes,
          index,
        });
      });

      dispatch({
        field: "optionTypes",
        value: tempOptionTypes,
        index,
      });

      dispatch({
        field: "instructions",
        value: editItem?.attributes?.special_instructions,
        index,
      });

      if (optionsField == "order_item_options")
        dispatch({
          field: "nameLabel",
          value: editItem?.attributes?.notes,
          index,
        });

      dispatch({
        field: "quantity",
        value: editItem?.attributes?.quantity,
        index,
      });
    }
  );

  const updateQuantity = (e, dispatch) => {
    const value = e?.target?.value;

    if (value > 0)
      dispatch({
        field: "quantity",
        value: value ? Number(value) : value,
      });
  };

  return {
    initRequiredCheck,
    setSelectedOptions,
    updateQuantity,
  };
};
