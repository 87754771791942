export const isMediumOrLargeScreen = window.innerWidth >= 712;
export const peopleCount = ["<20", "20-39", "40-59", "60-79", "80-89", "100+"];
export const mealTimings = {
  breakfast: ["8:00 am", "9:00 am", "10:00 am"],
  lunch: ["11:30 am", "12:00 pm", "12:30 pm"],
  dinner: ["4:00 pm", "5:00 pm", "6:00 pm"],
};
export const defaultMealTimePickerValue = [
  { breakfast: "08:00 PM", predefinedTime: true },
  { lunch: "10:00 PM", predefinedTime: true },
  { dinner: "09:00 PM", predefinedTime: true },
];
export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const canadianProvinces = [
  "Alberta",
  "Manitoba",
  "New Brunswick",
  "Nova Scotia",
  "British Columbia",
  "Ontario",
  "Newfoundland and Labrador",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
  "Nunavut",
  "Northwest Territories",
];
export const TestimonialText = [
  {
    text: (
      <p>
        <span className="font-inter-medium">
          The customer service at hungerhub is phenomenal.
        </span>{" "}
        <br />
        They have always been{" "}
        <span className="font-inter-medium">
          very quick to respond and
        </span>{" "}
        <br /> very{" "}
        <span className="font-inter-semibold">
          willing to make things right
        </span>
        . Especially Nick,
        <br /> who is absolutely fantastic to deal with and always
        <br /> follows up!
      </p>
    ),
    username: "Tom Doe",
  },
];
