import React, { useState, useMemo } from "react";
import {
  breakfastIcon,
  breakfastWhiteIcon,
  lunchIcon,
  lunchWhiteIcon,
  dinnerIcon,
  dinnerWhiteIcon,
  otherIcon,
  otherWhiteIcon,
} from "assets/img";
import classNames from "classnames";

const MealSeries = ({ handleMealChange, mealSelected, activeMeal }) => {
  const isOtherSelected = useMemo(
    () => mealSelected.meal === "other",
    [mealSelected]
  );

  const isBreakfastSelected = useMemo(
    () => mealSelected.meal === "breakfast",
    [mealSelected]
  );

  const isLunchSelected = useMemo(
    () => mealSelected.meal === "lunch",
    [mealSelected]
  );

  const isDinnerSelected = useMemo(
    () => mealSelected.meal === "dinner",
    [mealSelected]
  );

  const [breakfastHover, setBreakfastHover] = useState(false);
  const [lunchHover, setLunchHover] = useState(false);
  const [dinnerHover, setDinnerHover] = useState(false);
  const [otherHover, setOtherHover] = useState(false);

  return (
    <>
      <div className="inline-flex screen-md:flex screen-lg:flex gap-6 py-4">
        <div
          className={classNames(
            "w-1/2 screen-md:w-32 screen-lg:w-32 px-6 pb-3 rounded-xl text-slate-700 font-inter-medium leading-tight text-center hover:cursor-pointer",
            {
              "bg-indigo-500 text-white": isBreakfastSelected,
              "bg-slate-50 hover:bg-indigo-500 hover:text-white":
                !isBreakfastSelected,
            }
          )}
          onClick={() => handleMealChange("breakfast")}
          onMouseEnter={() => setBreakfastHover(true)}
          onMouseLeave={() => setBreakfastHover(false)}
        >
          {isBreakfastSelected || breakfastHover ? (
            <img src={breakfastWhiteIcon} alt="Breakfast" />
          ) : (
            <img src={breakfastIcon} alt="Breakfast" />
          )}
          Breakfast
        </div>

        <div
          className={classNames(
            "w-1/2 screen-md:w-32 screen-lg:w-32 px-6 pb-3 rounded-xl text-slate-700 font-inter-medium leading-tight text-center hover:cursor-pointer",
            {
              "bg-indigo-500 text-white": isLunchSelected,
              "bg-slate-50 hover:bg-indigo-500 hover:text-white":
                !isLunchSelected,
            }
          )}
          onClick={() => handleMealChange("lunch")}
          onMouseEnter={() => setLunchHover(true)}
          onMouseLeave={() => setLunchHover(false)}
        >
          {isLunchSelected || lunchHover ? (
            <img src={lunchWhiteIcon} alt="Lunch" />
          ) : (
            <img src={lunchIcon} alt="Lunch" />
          )}
          Lunch
        </div>
      </div>

      <div className="inline-flex screen-md:flex screen-lg:flex gap-6 py-4">
        <div
          className={classNames(
            "w-1/2 screen-md:w-32 screen-lg:w-32 px-6 pb-3 rounded-xl text-slate-700 font-inter-medium leading-tight text-center hover:cursor-pointer",
            {
              "bg-indigo-500 text-white": isDinnerSelected,
              "bg-slate-50 hover:bg-indigo-500 hover:text-white":
                !isDinnerSelected,
            }
          )}
          onClick={() => handleMealChange("dinner")}
          onMouseEnter={() => setDinnerHover(true)}
          onMouseLeave={() => setDinnerHover(false)}
        >
          {isDinnerSelected || dinnerHover ? (
            <img src={dinnerWhiteIcon} alt="Dinner" />
          ) : (
            <img src={dinnerIcon} alt="Dinner" />
          )}
          Dinner
        </div>

        <div
          className={classNames(
            "w-1/2 screen-md:w-32 screen-lg:w-32 px-6 pb-3 rounded-xl text-slate-700 font-inter-medium leading-tight text-center hover:cursor-pointer ",
            {
              "bg-indigo-500 text-white": isOtherSelected,
              "bg-slate-50 hover:bg-indigo-500 hover:text-white":
                !isOtherSelected,
            }
          )}
          onClick={() => handleMealChange("other")}
          onMouseEnter={() => setOtherHover(true)}
          onMouseLeave={() => setOtherHover(false)}
        >
          {isOtherSelected || otherHover ? (
            <img src={otherWhiteIcon} alt="Other" />
          ) : (
            <img src={otherIcon} alt="Other" />
          )}
          Other
        </div>
      </div>
    </>
  );
};

export default MealSeries;
