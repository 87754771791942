import { HungerhubExclusive } from "assets/img";

const HungerhubExclusiveTag = () => (
  <div className="flex bg-un-accent-pink items-center justify-center rounded-[4px] h-6 px-3 py-[2px] m-[3px]">
    <img src={HungerhubExclusive} alt="hh-exclusive" />

    <span className="font-inter-medium text-sm ml-[5px] text-white">
      hungerhub exclusives
    </span>
  </div>
);
export default HungerhubExclusiveTag;
