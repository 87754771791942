import { ClockIcon } from "assets/img";
import React from "react";
import { components } from "react-select";

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <div className="flex inline-flex gap-2">
          <img src={ClockIcon} alt="clock-icon" />

          {children}
        </div>
      </components.ValueContainer>
    )
  );
};

export default ValueContainer;
