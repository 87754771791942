import { CrowdPleasers } from "assets/img";

const CrowdPleasersTag = () => (
  <div className="flex bg-hh-accent-dark items-center justify-center rounded-[4px] h-6 px-2 py-[3px] m-[3px]">
    <img src={CrowdPleasers} alt="crowd-pleasers" />

    <span className="font-inter-medium text-sm ml-[4px] text-white">
      crowd pleasers
    </span>
  </div>
);

export default CrowdPleasersTag;
