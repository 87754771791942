import { sortByOptions } from "utils/constants/filters";
import { customStyles } from "utils/helpers/styles";
import Select from "react-select";

const SortBySelect = ({ sortBy, onChange }) => (
  <Select
    styles={customStyles}
    aria-labelledby="sort-by"
    aria-label="sort-by"
    placeholder="Sort by"
    closeMenuOnSelect
    value={sortBy}
    onChange={onChange}
    options={sortByOptions}
    className="w-[195px]"
  />
);

export default SortBySelect;
