import { faComment, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Comment, User } from "assets/img";
import classNames from "classnames";
import EmbedHTML from "components/shared/embedHTML";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { MobXProviderContext } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { ORDER_ITEM_OPTIONS_COUNT } from "utils/constants/common";
import { priceFormatter } from "utils/helpers/validation";

function CartItem({
  cartItem,
  setRemoveItemAlert,
  setAttachLabelAlert,
  setEditItem,
  checkout,
  moreThanOneItemInCategory,
  markItemAsSoldOut,
}) {
  const [itemQuantity, setItemQuantity] = useState(
    cartItem?.attributes?.quantity
  );
  const [optionsShown, setOptionsShown] = useState(ORDER_ITEM_OPTIONS_COUNT);
  const store = useContext(MobXProviderContext);
  const cartStore = toJS(store?.cartStore);
  let { unCheckedOutOrder } = cartStore ?? {};

  useEffect(() => {
    setItemQuantity(cartItem?.attributes?.quantity);
  }, [cartItem?.attributes?.quantity]);

  const setQuantity = (value) => {
    let tempQuan = itemQuantity;

    if (cartItem?.attributes?.notes !== "") {
      setAttachLabelAlert(cartItem?.attributes?.menu_item?.attributes?.title);
      return;
    }

    if (value == "decrement") {
      tempQuan = tempQuan - 1;
      setItemQuantity(tempQuan);
    } else {
      tempQuan = tempQuan + 1;
      setItemQuantity(tempQuan);
    }

    updateItem(tempQuan);
  };

  const updateItem = (quantity) => {
    store?.cartStore
      ?.updateCartItem(cartItem?.id, {
        order_item: {
          quantity,
          menu_item_id: cartItem?.attributes?.menu_item?.id,
          special_instructions: cartItem?.attributes?.special_instructions,
          notes: cartItem?.attributes?.notes,
          menu_category_id: cartItem?.attributes?.menu_category_id,
          order_item_options_attributes:
            cartItem?.attributes?.order_item_options?.map((option) => {
              return {
                option_id: option?.attributes?.option_id,
                option_group_id: option?.attributes?.option_category_id,
              };
            }),
        },
      })
      .then((data) => {
        store?.cartStore?.getCartItems(unCheckedOutOrder?.id);

        if (data?.response?.data?.sold_out) {
          markItemAsSoldOut(
            cartItem?.attributes?.menu_category_id,
            cartItem?.attributes?.menu_item?.id
          );
        }
      });
  };

  const updateQuantity = (e) => {
    const value = e.target?.value;

    setItemQuantity(value);

    if (value > 0) {
      let quantity = value > 0 ? value : 1;
      updateItem(quantity);
    }
  };

  return (
    <div
      className={classNames(`flex flex-col last:border-b-0 mb-2 md:mb-0`, {
        "border-b pb-4 border-border-gray":
          checkout || moreThanOneItemInCategory,
        "border-b-0": !moreThanOneItemInCategory,
      })}
    >
      <div key={cartItem?.id} className="mb-3">
        <div className="my-3">
          <div className="flex justify-between">
            <span className="text-md text-primary-black font-inter-medium w-3/5">
              <EmbedHTML
                text={
                  checkout
                    ? `<div class="flex"><p class="mr-[5px]">${
                        cartItem?.attributes?.quantity
                      }x</p> ${
                        cartItem?.attributes?.menu_item?.attributes
                          ?.display_name_with_html ||
                        cartItem?.attributes?.menu_item?.display_name_with_html
                      }</div>`
                    : cartItem?.attributes?.menu_item?.attributes
                        ?.display_name_with_html
                }
                className="text-primary-black"
              />
            </span>

            <div className="flex flex-col w-2/5">
              <span
                className="text-primary-black font-inter-medium text-right"
                style={{ fontSize: 17 }}
              >
                {priceFormatter(cartItem?.attributes?.total_price)}
              </span>

              {cartItem?.attributes?.quantity > 1 && (
                <span
                  className="text-sm text-right"
                  style={{ color: "#8F979F" }}
                >
                  {priceFormatter(
                    cartItem?.attributes?.menu_item?.attributes?.price ||
                      cartItem?.attributes?.menu_item?.price
                  )}{" "}
                  per item
                </span>
              )}
            </div>
          </div>

          {cartItem?.attributes?.order_item_options
            ?.slice(0, optionsShown)
            ?.map((option, index) => (
              <div className="flex" key={option.id}>
                <span className="d-row text-sm">
                  <EmbedHTML
                    isRow
                    text={
                      option?.attributes?.option_group_display_name_with_html
                    }
                    append={": "}
                    appendClass="mr-1"
                    className="text-primary-black"
                  />
                  <EmbedHTML
                    text={option?.attributes?.option_display_name_with_html}
                    className="text-primary-black"
                  />
                </span>
              </div>
            ))}

          {cartItem?.attributes?.order_item_options?.length > 2 &&
            optionsShown == ORDER_ITEM_OPTIONS_COUNT && (
              <button
                className="text-sm underline btn-style"
                onClick={() =>
                  setOptionsShown(cartItem.attributes.order_item_options.length)
                }
              >
                +{cartItem?.attributes?.order_item_options.length - 2} Show all
              </button>
            )}
        </div>

        {(cartItem?.attributes?.special_instructions ||
          cartItem?.attributes?.notes) && (
          <div className="flex flex-col bg-background p-2 rounded-md">
            {cartItem?.attributes?.special_instructions && (
              <div className="flex items-center ">
                <div className="flex" style={{ width: 12, height: 12 }}>
                  <img src={Comment} alt="comment-icon" />
                </div>

                <span className="text-sm ml-2 text-[#2d3958]">
                  Instructions:{" "}
                  <span className="font-inter-medium">
                    {cartItem?.attributes?.special_instructions}
                  </span>
                </span>
              </div>
            )}

            {cartItem?.attributes?.notes && (
              <div className="flex items-center ">
                <div className="flex" style={{ width: 12, height: 12 }}>
                  <img src={User} alt="user-icon" />
                </div>

                <span className="text-sm ml-2 text-[#2d3958]">
                  Name attached:{" "}
                  <span className="font-inter-medium">
                    {cartItem?.attributes?.notes}
                  </span>
                </span>
              </div>
            )}
          </div>
        )}

        {!checkout && (
          <div className="flex justify-between items-center mt-3">
            <div className="flex">
              <div
                onClick={() => setEditItem(cartItem)}
                className="flex items-center cursor-pointer"
              >
                <span className="text-sm font-inter-medium text-[#2d3958]">
                  Edit
                </span>
              </div>

              <div
                className="flex items-center cursor-pointer ml-2"
                onClick={() => setRemoveItemAlert(cartItem.id)}
              >
                <span className="text-sm font-inter-medium text-[#2d3958]">
                  Remove
                </span>
              </div>
            </div>

            <div className="flex items-center justify-center border border-border-gray rounded-3xl px-2 py-[1px]">
              <div
                className="py-1 cursor-pointer px-1"
                onClick={() => {
                  if (itemQuantity > 1) setQuantity("decrement");
                }}
              >
                <div
                  className={classNames("mr-2 bg-primary-black w-3 h-0.5", {
                    "bg-secondary-gray": itemQuantity == 1,
                  })}
                />
              </div>

              <input
                className="px-2 w-8 text-black-light font-inter-medium text-center text-sm outline-none"
                type="number"
                min="1"
                value={itemQuantity}
                onChange={updateQuantity}
              />

              <div
                className="py-1 cursor-pointer"
                onClick={() => setQuantity("increment")}
              >
                <span
                  className="ml-2 text-2xl text-primary-black font-inter-light"
                  style={{ lineHeight: 0.8 }}
                >
                  +
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(CartItem);
