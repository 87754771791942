import React, { useState, useEffect } from "react";

const Rangeslider = ({
  minValue,
  maxValue,
  currentValue,
  setMealAllowance,
}) => {
  const [value, setValue] = useState(currentValue || 0);

  const getBackgroundSize = () => {
    const percentage = (value / maxValue) * 100;
    return { backgroundSize: `${percentage}% 100%` };
  };

  useEffect(() => {
    setMealAllowance(value);
  }, [value]);

  return (
    <input
      id="range"
      type="range"
      min={minValue}
      max={maxValue}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={getBackgroundSize()}
      className="w-full h-2"
    />
  );
};

export default Rangeslider;
