import CartItem from "components/Cart/CartItem";
import { toJS } from "mobx";
import { MobXProviderContext, observer } from "mobx-react";
import moment from "moment-timezone";
// import Image from "next/image";
// import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
  CalendarIcon,
  ChatIcon,
  ClockIcon,
  FaqIcon,
  FileDownloadIcon,
  GreyCalendarIcon,
  GreyClockIcon,
  InfoButton,
  InvoiceIcon,
  Logo,
  MapIcon,
  RadioActive,
  RadioInActive,
  SuccessPageBackgroundImage,
  WalletIcon,
} from "assets/img";
import {
  priceFormatter,
  validateEmail,
  preventMinus,
  preventPasteNegative,
  validatePostalCodeLength,
} from "utils/helpers/validation";
import Tooltip from "components/Tooltip";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import { groupBy, isPresent } from "utils/helpers/array";
import EmbedHTML from "components/shared/embedHTML";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import dateTime from "utils/helpers/dateTime";
import { useNavigate } from "react-router-dom";
// import Head from "next/head";
// import { StoreContext } from "pages/_app";
// import { setCookies } from "utils/cookies/cookieHandler";
// import { formattedAddres } from "utils/helpers/addresses";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_APP_STRIPE_API_KEY);

const GroupOrderThanks = () => {
  const initialState = {
    order: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const cartStore = toJS(store?.cartStore);
  const userStore = toJS(store?.userStore);
  const { groupOrderId } = useParams();
  const navigate = useNavigate();
  let { cart } = cartStore ?? {};

  const [showAll, setShowAll] = useState(true);
  let { order } = provider;
  let { isLoading, currentUser, employeeID, isAdmin, locationID } = userStore;

  let router = null;
  let interval = null;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const { isPending, error, data } = useQuery({
    queryKey: ["groupOrder", locationID, groupOrderId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.mealManagementStore.fetchGroupOrder(
        groupOrderId,
        locationID
      );
    },
  });

  useEffect(() => {
    dispatch({ field: "order", value: data });
  }, [data]);

  const getTotalQuantity = (items) => {
    let quantity = 0;
    items?.forEach((item) => {
      quantity += item?.attributes?.quantity;
    });

    return quantity;
  };

  const getOrder = () => {
    let slug = "";

    store.cartStore
      ?.getOrder(slug)
      .then((response) => {
        store.userStore?.setLoader(false);

        if (response?.data) dispatch({ field: "order", value: response?.data });
      })
      .catch(() => {
        store.userStore?.setLoader(false);
      });
  };

  useEffect(() => {
    let slug = "";

    if (slug) {
      store.userStore?.setLoader(true);

      store?.userStore.getUserProfile();
      getOrder();

      interval = setInterval(() => {
        getOrder();
      }, 120000);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [router?.isReady]);

  const handleAdminLogin = () => {
    navigate(`/group-order/admin/orders/${groupOrderId}/${locationID}`);
  };

  const getLocationAddress = async (locationId) => {
    if (locationId) {
      try {
        const response = await store?.userStore.getLocationAddresses(
          locationId
        );
        const address = response?.data;

        if (address) {
          const addressWithLocationId = address?.map((address) => ({
            ...address,
            locationId,
          }));

          store.userStore.setLocationAddresses(addressWithLocationId);
        }

        store.userStore?.setLoader(false);
      } catch (error) {
        store.userStore?.setLoader(false);
      }
    }
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   if (currentUser?.attributes && !isAdmin) {
    //     const activeEmployees = currentUser?.attributes?.employees.filter(
    //       (emp) => !emp?.attributes.soft_deleted
    //     );
    //     const adminUser = activeEmployees.find(
    //       (emp) => emp?.attributes.role == "admin"
    //     );
    //     // store.userStore.setLocationID(adminUser?.attributes?.organization_id);
    //     store.userStore.setAdminUser(true);
    //     store.userStore.setEmployeeID(adminUser?.id);
    //     store.userStore.setInvoiceDetails({
    //       invoice_payments: adminUser?.attributes?.invoice_payments,
    //       account_billing_address:
    //         adminUser?.attributes?.account_billing_address,
    //       account_billing_emails: adminUser?.attributes?.account_billing_emails,
    //       account_billing_name: adminUser?.attributes?.account_billing_name,
    //     });
    //     getLocationAddress(adminUser?.attributes?.organization_id);
    //   }
    // }, 5000);
    // return () => clearTimeout(timer);
  }, [order]);

  const getTotalAmount = () => {
    let total =
      parseFloat(order?.attributes?.grand_total) +
      parseFloat(order?.attributes?.discounted_amount);

    return total;
  };

  return (
    <>
      <div className="md:w-full pb-[86px] flex-col justify-start items-center inline-flex">
        <div className="self-stretch h-[117px] px-8 bg-transparent border-b border-slate-50 justify-center items-center gap-[370px] inline-flex">
          <img
            src={Logo}
            alt="hungerhub-logo"
            width={"150px"}
            height={"55px"}
          />
        </div>

        <div className="self-stretch flex-col justify-start items-center gap-8 inline-flex px-8 backgroundImage">
          <div className="px-2 opacity-90 bg-transparent/opacity-80 flex-col justify-start items-start gap-2 flex mt-8 mb-[32px]">
            <div className="flex-col justify-center gap-4 flex">
              <div className="text-center text-slate-700 text-[40px] font-inter-semibold leading-[56px]">
                Thank you for your order!{" "}
              </div>
              <div className="text-center text-neutral-700 text-xl font-inter-semibold leading-normal max-w-[678px]">
                We got your order! You will receive a confirmation email
                shortly. You can also view your order status and access all
                related information in your admin panel.
              </div>
            </div>
          </div>

          {order && (
            // {/* Desktop */}
            <div className="hidden md:flex flex flex-row justify-center md:w-full">
              <div className="w-[678px] flex-col mr-[8%] justify-start items-center gap-[88px] inline-flex">
                <div className="flex-col justify-start items-start gap-[54px] flex">
                  <div className="w-[678px] p-6 bg-white rounded-3xl border border-zinc-200 flex-col justify-start items-start gap-6 flex">
                    <div className="text-slate-700 text-[32px] font-inter-semibold leading-10">
                      Delivery details
                    </div>
                    <div className="self-stretch justify-between items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-3 inline-flex">
                        <div className="w-[153px] justify-start items-center gap-1 inline-flex">
                          <img src={MapIcon} alt="map-icon" />

                          <div className="text-slate-700 text-base font-inter-medium leading-tight">
                            Delivery address
                          </div>
                        </div>

                        <div className="w-[135px] pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                          {
                            order?.attributes?.address?.attributes
                              ?.complete_address
                          }
                        </div>
                      </div>

                      <div className="flex-col justify-start items-start gap-3 inline-flex">
                        <div className="w-[126px] justify-start items-center gap-1 inline-flex">
                          <img src={CalendarIcon} alt="calendar-icon" />

                          <div className="text-slate-700 text-base font-inter-medium leading-tight">
                            Delivery date
                          </div>
                        </div>

                        <div className="pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                          <span className="text-[14px] font-inter-medium">
                            {dateTime.formatDate(
                              order?.attributes?.meals[0]?.attributes
                                ?.meal_date,
                              "ddd, MMMM Do, yyyy"
                            )}{" "}
                            {dateTime.checkAndGetTimezone(
                              order?.attributes?.tzinfo
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="flex-col justify-start items-start gap-3 inline-flex">
                        <div className="w-[126px] justify-start items-center gap-1 inline-flex">
                          <img src={ClockIcon} alt="clock-icon" />

                          <div className="text-slate-700 text-base font-inter-medium leading-tight">
                            Delivery time
                          </div>
                        </div>

                        <div className="pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                          <span className="text-[14px] font-inter-medium">
                            {dateTime.formatTimeWithFormat(
                              order?.attributes?.meals[0]?.attributes
                                ?.target_time,
                              "hh:mm a",
                              order?.attributes?.tzinfo
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="self-stretch h-[72px] flex-col justify-start items-start gap-3 flex">
                      <div className="justify-start items-center gap-1 inline-flex">
                        <img src={ChatIcon} alt="chat-icon" />

                        <div className="w-[629px] text-slate-700 text-base font-inter-medium leading-tight">
                          Delivery instructions
                        </div>
                      </div>

                      <div className="grow shrink basis-0 pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                        {
                          order.attributes?.address?.attributes
                            ?.delivery_instructions
                        }
                      </div>
                    </div>

                    <div className="self-stretch justify-start items-start gap-[88px] inline-flex">
                      <div className="w-[153px] flex-col justify-start items-start gap-3 inline-flex">
                        <div className="w-[153px] justify-start items-center gap-1 inline-flex">
                          <img
                            src={WalletIcon}
                            alt="wallet-icon"
                            className="h-[16px] w-[16px]"
                          />

                          <div className="text-slate-700 text-base font-inter-medium leading-tight">
                            Payment method
                          </div>
                        </div>

                        <div className="pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                          {order?.attributes?.paid_by_card ? (
                            <>
                              {order?.attributes?.payment_method_details?.brand}{" "}
                              ending in{" "}
                              {order?.attributes?.payment_method_details?.last4}
                            </>
                          ) : (
                            <>
                              Billed to{" "}
                              {
                                order?.attributes?.location_billing_account
                                  ?.name
                              }
                            </>
                          )}
                        </div>
                      </div>

                      <div className="flex-col justify-start items-start gap-3 inline-flex">
                        <div className="w-[221px] justify-start items-center gap-1 inline-flex">
                          <img src={InvoiceIcon} alt="invoice-icon" />

                          <div className="text-slate-700 text-base font-inter-medium leading-tight">
                            Billing information
                          </div>
                        </div>

                        <div className="w-[221px] pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                          <div>
                            {order?.attributes?.location_billing_account?.name}
                          </div>
                          {order?.attributes?.location_billing_account?.emails?.map(
                            (email, index) => {
                              return <p>{email}</p>;
                            }
                          )}
                          <div>
                            {
                              order?.attributes?.location_billing_account
                                ?.address
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-[678px] p-6 bg-slate-50 rounded-3xl justify-start items-center gap-4 inline-flex">
                    <img
                      src={FaqIcon}
                      alt="faq-icon"
                      className="h-[16px] w-[16px]"
                    />

                    <div className="grow shrink basis-0">
                      <span className="text-slate-700 text-[18px] font-inter-semibold leading-tight">
                        Have a question, need help with your order, or want to
                        give some feedback?{" "}
                      </span>
                      <span className="text-indigo-500 text-[18px] font-inter-semibold leading-tight cursor-pointer">
                        Start a chat with us!
                      </span>
                    </div>
                  </div>
                </div>

                <div className="self-stretch h-16 flex-col justify-start items-center gap-2 flex">
                  <button
                    className="w-60 h-16 text-white bg-[#232777] text-sm font-inter-medium leading-[14px] rounded-md border border-indigo-900"
                    onClick={handleAdminLogin}
                  >
                    Back to Admin Panel
                  </button>
                </div>
              </div>

              <div className="w-[562px] py-6 bg-white rounded-3xl border border-zinc-200 flex-col justify-start items-start gap-6 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch flex-col justify-start items-start flex">
                      <div className="self-stretch px-4 bg-white flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretch justify-between items-start inline-flex">
                          <div className="text-slate-700 text-[32px] font-inter-semibold leading-10">
                            Order details
                          </div>

                          <button
                            className="px-3 py-2 rounded-md border border-indigo-900 justify-center items-center gap-1 flex text-indigo-900 text-sm font-inter-normal"
                            onClick={() =>
                              store.userStore?.generatePDF(
                                order?.attributes?.slug
                              )
                            }
                          >
                            <img src={FileDownloadIcon} alt="download-icon" />
                            Download invoice
                          </button>
                        </div>

                        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                          <div className="self-stretch justify-between items-start inline-flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex w-1/2">
                              <h2 className="text-neutral-700 text-2xl  font-inter-semibold">
                                {
                                  order?.attributes?.meals[0]?.attributes
                                    ?.restaurant_name
                                }
                              </h2>
                            </div>

                            <div className="flex flex-col items-end flex w-1/2">
                              <div className="text-black text-xl font-inter-semibold leading-normal">
                                Total:{" "}
                                {priceFormatter(
                                  order?.attributes?.meals[0]?.attributes
                                    ?.grand_total
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex w-full items-center justify-between">
                            <span className="text-md">Items</span>
                            <span className="text-md">
                              {priceFormatter(
                                order?.attributes?.meals[0]?.attributes
                                  ?.subtotal
                              )}
                            </span>
                          </div>

                          <div className="flex w-full items-center justify-between mt-3">
                            <div className="flex items-center">
                              <span className="text-md">Delivery</span>

                              <Tooltip
                                grayIcon
                                tooltipText={
                                  "Your delivery cost is dependent on restaurant and proximity to your delivery address"
                                }
                                id="delivery-tooltip"
                                backgroundColor={"white"}
                                textColor="black"
                                place={"bottom"}
                              />
                            </div>

                            <span className="text-md">
                              {priceFormatter(
                                order?.attributes?.meals[0]?.attributes
                                  ?.delivery_fee
                              )}
                            </span>
                          </div>

                          <div className="flex w-full items-center justify-between mt-3">
                            <div className="flex items-center">
                              <span className="text-md">Service Fee</span>

                              <Tooltip
                                grayIcon
                                tooltipText={
                                  "A service fee of seven percent of food total charges is inclusive of all hungerhub fees"
                                }
                                id="service-tooltip"
                                backgroundColor={"white"}
                                textColor="black"
                                place={"bottom"}
                              />
                            </div>

                            <span className="text-md">
                              {priceFormatter(
                                order?.attributes?.meals[0]?.attributes
                                  ?.service_fee
                              )}
                            </span>
                          </div>

                          <div className="flex w-full items-center justify-between mt-3">
                            <span className="text-md">Tax</span>

                            <span className="text-md">
                              {priceFormatter(
                                order?.attributes?.meals[0]?.attributes
                                  ?.tax_amount
                              )}
                            </span>
                          </div>

                          <div className="flex w-full items-center justify-between mt-3">
                            <span className="text-md">Tip</span>

                            <span className="text-md">
                              {priceFormatter(
                                order?.attributes?.meals[0]?.attributes?.tip
                              )}
                            </span>
                          </div>

                          {(!currentUser || !isAdmin) &&
                            parseFloat(order?.attributes?.discounted_amount) >
                              0 && (
                              <>
                                <div className="flex w-full items-center justify-between mt-3">
                                  <span className="text-md">
                                    Total before promotion
                                  </span>

                                  <span className="text-md">
                                    {priceFormatter(getTotalAmount())}
                                  </span>
                                </div>

                                <div className="flex w-full items-center justify-between mt-3">
                                  <span className="text-slate-500 font-inter-semibold text-md">
                                    Promotion
                                  </span>

                                  <span className="text-md text-slate-500 font-inter-semibold">
                                    -
                                    {priceFormatter(
                                      order?.attributes?.discounted_amount
                                    )}
                                  </span>
                                </div>

                                <div className="flex w-full items-center justify-between mt-3">
                                  <span className="text-base font-inter-semibold text-primary-black">
                                    Total
                                  </span>

                                  <span className="text-base font-inter-semibold text-primary-black">
                                    {priceFormatter(
                                      order?.attributes?.grand_total
                                    )}
                                  </span>
                                </div>
                              </>
                            )}

                          <div className="w-full h-[0px] border border-zinc-200"></div>
                        </div>
                      </div>

                      <div className="flex-col justify-start items-start gap-4 flex w-full">
                        <div className="px-6 py-4 bg-transparent rounded-3xl flex-col justify-start items-start gap-6 flex w-full">
                          <div className="self-stretch justify-between items-start inline-flex">
                            <div className="text-[#2D3958] text-lg font-inter-semibold leading-tight">
                              Guest meals
                            </div>

                            <div className="text-[#2D3958] text-base font-inter-normal leading-tight">
                              Total guests:{" "}
                              {
                                order?.attributes?.meals[0]?.attributes?.orders
                                  ?.length
                              }
                            </div>
                          </div>

                          <div className="mt-4 w-full">
                            {true
                              ? order?.attributes?.meals[0]?.attributes?.orders?.map(
                                  (entry, index) => (
                                    <div key={`${index}-cartItem`}>
                                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                                        <span className="text-md font-inter-medium text-primary-black">
                                          <EmbedHTML
                                            text={entry?.attributes?.user_name}
                                            className="text-primary-black"
                                          />
                                        </span>
                                        <span className="text-sm">
                                          Total Qty:{" "}
                                          {getTotalQuantity(
                                            entry?.attributes?.order_items
                                          )}
                                        </span>
                                      </div>

                                      {entry?.attributes?.order_items?.map(
                                        (cartItem) => (
                                          <div
                                            className="px-3"
                                            key={cartItem?.id}
                                          >
                                            <CartItem
                                              checkout
                                              cartItem={cartItem}
                                              moreThanOneItemInCategory={
                                                entry?.length > 1
                                              }
                                            />
                                          </div>
                                        )
                                      )}

                                      {index !==
                                        order?.attributes?.meals[0]?.attributes
                                          ?.orders?.length -
                                          1 && (
                                        <div className="w-full h-[0px] border border-zinc-200 mb-6"></div>
                                      )}
                                    </div>
                                  )
                                )
                              : order?.attributes?.meals[0]?.attributes?.orders
                                  ?.slice(0, 3)
                                  ?.map((entry, index) => (
                                    <div key={`${index}-cartItem`}>
                                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                                        <span className="text-md font-inter-medium text-primary-black">
                                          <EmbedHTML
                                            text={
                                              entry?.category_display_name_with_html ||
                                              entry?.category_name
                                            }
                                            className="text-primary-black"
                                          />
                                        </span>
                                        <span className="text-sm">
                                          Total Qty:{" "}
                                          {getTotalQuantity(entry?.order_items)}
                                        </span>
                                      </div>

                                      {entry?.order_items?.map((cartItem) => (
                                        <div
                                          className="px-3"
                                          key={cartItem?.id}
                                        >
                                          <CartItem
                                            checkout
                                            cartItem={cartItem}
                                            moreThanOneItemInCategory={
                                              entry?.length > 1
                                            }
                                          />
                                        </div>
                                      ))}

                                      {index !== 2 &&
                                        order?.attributes?.meals[0]?.attributes
                                          ?.orders?.length -
                                          1 !==
                                          index && (
                                          <div className="w-full h-[0px] border border-zinc-200 mb-6"></div>
                                        )}
                                    </div>
                                  ))}

                            {!showAll &&
                              order?.attributes?.meals[0]?.attributes?.orders
                                ?.length > 3 && (
                                <div className="self-stretch justify-center items-center gap-2 flex inline-flex mt-2 w-full">
                                  <div
                                    className="text-indigo-500 text-sm font-inter-medium underline leading-none cursor-pointer"
                                    onClick={toggleShowAll}
                                  >
                                    See more...
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Tablet */}
          <div className="flex md:hidden flex-col w-full gap-6">
            <div className="w-full flex-col justify-start items-center gap-[88px] inline-flex">
              <div className="w-full p-6 bg-white rounded-3xl border border-zinc-200 flex-col justify-start items-start gap-6 flex">
                <div className="text-slate-700 text-[32px] font-inter-semibold leading-10">
                  Delivery details
                </div>
                <div className="self-stretch justify-between items-start inline-flex">
                  <div className="flex-col justify-start items-start gap-3 inline-flex">
                    <div className="w-[153px] justify-start items-center gap-1 inline-flex">
                      <img src={MapIcon} alt="map-icon" />

                      <div className="text-slate-700 text-base font-inter-medium leading-tight">
                        Delivery address
                      </div>
                    </div>

                    <div className="w-[135px] pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                      {order?.attributes?.address?.attributes?.complete_address}
                    </div>
                  </div>

                  <div className="flex-col justify-start items-start gap-3 inline-flex">
                    <div className="w-[126px] justify-start items-center gap-1 inline-flex">
                      <img src={CalendarIcon} alt="calendar-icon" />

                      <div className="text-slate-700 text-base font-inter-medium leading-tight">
                        Delivery date
                      </div>
                    </div>

                    <div className="pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                      {moment(order?.attributes?.order_date).format(
                        "MMMM D, YYYY"
                      )}
                    </div>
                  </div>

                  <div className="flex-col justify-start items-start gap-3 inline-flex">
                    <div className="w-[126px] justify-start items-center gap-1 inline-flex">
                      <img src={ClockIcon} alt="clock-icon" />

                      <div className="text-slate-700 text-base font-inter-medium leading-tight">
                        Delivery time
                      </div>
                    </div>

                    <div className="pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                      {order?.attributes?.delivery_time}
                    </div>
                  </div>
                </div>

                <div className="self-stretch h-[72px] flex-col justify-start items-start gap-3 flex">
                  <div className="justify-start items-center gap-1 inline-flex">
                    <img src={ChatIcon} alt="chat-icon" />

                    <div className="w-[629px] text-slate-700 text-base font-inter-medium leading-tight">
                      Delivery instructions
                    </div>
                  </div>

                  <div className="grow shrink basis-0 pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                    {
                      order?.attributes?.delivery_details?.attributes
                        ?.delivery_instructions
                    }
                  </div>
                </div>

                <div className="self-stretch justify-start items-start gap-[88px] inline-flex">
                  <div className="w-[153px] flex-col justify-start items-start gap-3 inline-flex">
                    <div className="w-[153px] justify-start items-center gap-1 inline-flex">
                      <img
                        src={WalletIcon}
                        alt="wallet-icon"
                        className="h-[16px] w-[16px]"
                      />

                      <div className="text-slate-700 text-base font-inter-medium leading-tight">
                        Payment method
                      </div>
                    </div>

                    <div className="pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                      {order?.attributes?.paid_by_card ? (
                        <>
                          {order?.attributes?.payment_method_details?.brand}{" "}
                          ending in{" "}
                          {order?.attributes?.payment_method_details?.last4}
                        </>
                      ) : (
                        <>
                          Billed to{" "}
                          {order?.attributes?.location_billing_account?.name}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex-col justify-start items-start gap-3 inline-flex">
                    <div className="w-[221px] justify-start items-center gap-1 inline-flex">
                      <img src={InvoiceIcon} alt="invoice-icon" />

                      <div className="text-slate-700 text-base font-inter-medium leading-tight">
                        Billing information
                      </div>
                    </div>

                    <div className="w-[221px] pl-5 justify-start items-start text-slate-700 text-base font-inter-normal leading-tight">
                      <div>
                        {order?.attributes?.location_billing_account?.name}
                      </div>
                      {order?.attributes?.location_billing_account?.emails?.map(
                        (email, index) => {
                          return <p>{email}</p>;
                        }
                      )}
                      <div>
                        {order?.attributes?.location_billing_account?.address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full py-6 bg-white rounded-3xl border border-zinc-200 flex-col justify-start items-start gap-6 inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                  <div className="self-stretch flex-col justify-start items-start flex">
                    <div className="self-stretch px-4 bg-white flex-col justify-start items-start gap-4 flex">
                      <div className="self-stretch justify-between items-start inline-flex">
                        <div className="text-slate-700 text-[32px] font-inter-semibold leading-10">
                          Order details
                        </div>

                        <button
                          className="px-3 py-2 rounded-md border border-indigo-900 justify-center items-center gap-1 flex text-indigo-900 text-sm font-inter-normal"
                          onClick={() =>
                            store.userStore?.generatePDF(
                              order?.attributes?.slug
                            )
                          }
                        >
                          <img src={FileDownloadIcon} alt="download-icon" />
                          Download invoice
                        </button>
                      </div>

                      <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <div className="self-stretch justify-between items-start inline-flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="text-neutral-700 text-2xl font-inter-semibold leading-loose">
                              {order?.attributes?.restaurant_name}
                            </div>
                          </div>

                          <div className="justify-between items-end flex">
                            <div className="text-black text-xl font-inter-semibold leading-normal">
                              Total:{" "}
                              {priceFormatter(order?.attributes?.grand_total)}
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <span className="text-md">Items</span>
                          <span className="text-md">
                            {priceFormatter(order?.attributes?.subtotal)}
                          </span>
                        </div>

                        <div className="flex w-full items-center justify-between mt-3">
                          <div className="flex items-center">
                            <span className="text-md">Delivery</span>

                            <Tooltip
                              grayIcon
                              tooltipText={
                                "Your delivery cost is dependent on restaurant and proximity to your delivery address"
                              }
                              id="delivery-tooltip"
                              backgroundColor={"white"}
                              textColor="black"
                              place={"bottom"}
                            />
                          </div>

                          <span className="text-md">
                            {priceFormatter(order?.attributes?.delivery_fee)}
                          </span>
                        </div>

                        <div className="flex w-full items-center justify-between mt-3">
                          <div className="flex items-center">
                            <span className="text-md">Service Fee</span>

                            <Tooltip
                              grayIcon
                              tooltipText={
                                "A service fee of seven percent of food total charges is inclusive of all hungerhub fees"
                              }
                              id="service-tooltip"
                              backgroundColor={"white"}
                              textColor="black"
                              place={"bottom"}
                            />
                          </div>

                          <span className="text-md">
                            {priceFormatter(order?.attributes?.service_fee)}
                          </span>
                        </div>

                        <div className="flex w-full items-center justify-between mt-3">
                          <span className="text-md">Tax</span>

                          <span className="text-md">
                            {priceFormatter(order?.attributes?.hst)}
                          </span>
                        </div>

                        <div className="flex w-full items-center justify-between mt-3">
                          <span className="text-md">Tip</span>

                          <span className="text-md">
                            {priceFormatter(order?.attributes?.tip)}
                          </span>
                        </div>

                        {(!currentUser || !isAdmin) &&
                          parseFloat(order?.attributes?.discounted_amount) >
                            0 && (
                            <>
                              <div className="flex w-full items-center justify-between mt-3">
                                <span className="text-md">
                                  Total before promotion
                                </span>

                                <span className="text-md">
                                  {priceFormatter(getTotalAmount())}
                                </span>
                              </div>

                              <div className="flex w-full items-center justify-between mt-3">
                                <span className="text-slate-500 font-inter-semibold text-md">
                                  Promotion
                                </span>

                                <span className="text-md text-slate-500 font-inter-semibold">
                                  -
                                  {priceFormatter(
                                    order?.attributes?.discounted_amount
                                  )}
                                </span>
                              </div>

                              <div className="flex w-full items-center justify-between mt-3">
                                <span className="text-base font-inter-semibold text-primary-black">
                                  Total
                                </span>

                                <span className="text-base font-inter-semibold text-primary-black">
                                  {priceFormatter(
                                    order?.attributes?.grand_total
                                  )}
                                </span>
                              </div>
                            </>
                          )}

                        <div className="w-full h-[0px] border border-zinc-200"></div>
                      </div>
                    </div>

                    <div className="flex-col justify-start items-start gap-4 flex w-full">
                      <div className="px-6 py-4 bg-transparent rounded-3xl flex-col justify-start items-start gap-6 flex w-full">
                        <div className="self-stretch justify-between items-start inline-flex">
                          <div className="text-slate-500 text-lg font-inter-semibold leading-tight">
                            Items
                          </div>

                          <div className="text-slate-500 text-base font-inter-normal leading-tight">
                            Total items:{" "}
                            {order?.attributes?.menu_categories?.length}
                          </div>
                        </div>

                        {
                          <div className="mt-4 w-full">
                            {showAll
                              ? order?.attributes?.menu_categories?.map(
                                  (entry, index) => (
                                    <div key={`${index}-cartItem`}>
                                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                                        <span className="text-md font-inter-medium text-primary-black">
                                          <EmbedHTML
                                            text={
                                              entry?.category_display_name_with_html ||
                                              entry?.category_name
                                            }
                                            className="text-primary-black"
                                          />
                                        </span>
                                        <span className="text-sm">
                                          Total Qty:{" "}
                                          {getTotalQuantity(entry?.order_items)}
                                        </span>
                                      </div>

                                      {entry?.order_items?.map((cartItem) => (
                                        <div
                                          className="px-3"
                                          key={cartItem?.id}
                                        >
                                          <CartItem
                                            checkout
                                            cartItem={cartItem}
                                            moreThanOneItemInCategory={
                                              entry?.length > 1
                                            }
                                          />
                                        </div>
                                      ))}

                                      {index !==
                                        order?.attributes?.menu_categories
                                          ?.length -
                                          1 && (
                                        <div className="w-full h-[0px] border border-zinc-200 mb-6"></div>
                                      )}
                                    </div>
                                  )
                                )
                              : order?.attributes?.menu_categories
                                  ?.slice(0, 3)
                                  ?.map((entry, index) => (
                                    <div key={`${index}-cartItem`}>
                                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                                        <span className="text-md font-inter-medium text-primary-black">
                                          <EmbedHTML
                                            text={
                                              entry?.category_display_name_with_html ||
                                              entry?.category_name
                                            }
                                            className="text-primary-black"
                                          />
                                        </span>
                                        <span className="text-sm">
                                          Total Qty:{" "}
                                          {getTotalQuantity(entry?.order_items)}
                                        </span>
                                      </div>

                                      {entry?.order_items?.map((cartItem) => (
                                        <div
                                          className="px-3"
                                          key={cartItem?.id}
                                        >
                                          <CartItem
                                            checkout
                                            cartItem={cartItem}
                                            moreThanOneItemInCategory={
                                              entry?.length > 1
                                            }
                                          />
                                        </div>
                                      ))}

                                      {index !== 2 &&
                                        order?.attributes?.menu_categories
                                          ?.length -
                                          1 !==
                                          index && (
                                          <div className="w-full h-[0px] border border-zinc-200 mb-6"></div>
                                        )}
                                    </div>
                                  ))}

                            {!showAll &&
                              order?.attributes?.menu_categories?.length >
                                3 && (
                                <div className="self-stretch justify-center items-center gap-2 flex inline-flex mt-2 w-full">
                                  <div
                                    className="text-indigo-500 text-sm font-inter-medium underline leading-none cursor-pointer"
                                    onClick={toggleShowAll}
                                  >
                                    See more...
                                  </div>
                                </div>
                              )}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-col justify-start items-start gap-[54px] flex mt-4">
              <div className="w-full p-6 bg-slate-50 rounded-3xl justify-start items-center gap-4 inline-flex">
                <img
                  src={FaqIcon}
                  alt="faq-icon"
                  className="h-[16px] w-[16px]"
                />

                <div className="grow shrink basis-0">
                  <span className="text-slate-700 text-lg font-inter-semibold leading-tight">
                    Have a question, need help with your order, or want to give
                    some feedback?{" "}
                  </span>
                  <span className="text-indigo-500 text-lg font-inter-semibold leading-tight cursor-pointer">
                    Start a chat with us!
                  </span>
                </div>
              </div>

              <div className="self-stretch h-16 flex-col justify-start items-center gap-2 flex">
                <button
                  className="w-60 h-16 text-white bg-[#232777] text-sm font-inter-medium leading-[14px] rounded-md border border-indigo-900"
                  onClick={handleAdminLogin}
                >
                  Back to Admin Panel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(GroupOrderThanks);
