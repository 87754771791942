import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toJS } from "mobx";
import { MobXProviderContext, observer } from "mobx-react";
import moment from "moment-timezone";
import { dateFormat } from "utils/helpers/dateTime";
import SubmitButtons from "components/SubmitButtons";
import { useMutation } from "@tanstack/react-query";

function ManageOrderDetailsModal({ visible, setModal, order, getOrder }) {
  const screenType = Screen.ScreenType();
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  let { address, completeAddress } = userStore;
  const [internalAddress, setAddress] = useState(null);
  const [isAddressUpdated, setAddressUpdated] = useState(null);
  const [completeAddressError, setCompleteAddressError] = useState(false);

  const initialState = {
    groupOrderName: null,
    perPersonBudget: null,
    expectedAttendees: null,
    selectedDate: null,
    selectedTime: null,
    selectedDateUser: null,
    selectedTimeUser: null,
    deliveryDate: null,
    deliveryTime: null,
    times: [],
    userTimes: [],
    dateError: false,
    timeError: false,
    userDateError: false,
    userTimeError: false,
    deliveryDateError: false,
    deliveryTimeError: false,
    deliveryInstructions: "",
    deliveryInstructionsLevel: "no",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  let {
    groupOrderName,
    perPersonBudget,
    expectedAttendees,
    selectedDate,
    selectedTime,
    selectedDateUser,
    selectedTimeUser,
    deliveryDate,
    deliveryTime,
    deliveryInstructionsLevel,
  } = provider;

  let currentGroupOrder = null;

  const notificationMethods = [
    { id: "no", title: "No, only use these instructions for this order" },
    {
      id: "yes",
      title: "Yes, use for this and all future orders with this address",
    },
  ];

  useEffect(() => {
    if (visible) {
      dispatch({ field: "groupOrderName", value: order?.attributes?.name });
      dispatch({
        field: "perPersonBudget",
        value: order?.attributes?.subsidy,
      });
      dispatch({
        field: "expectedAttendees",
        value: order?.attributes?.expected_bags,
      });

      dispatch({
        field: "deliveryInstructions",
        value: order.attributes?.address?.attributes?.delivery_instructions,
      });
    } else dispatch({ action: "reset" });
  }, [visible, order?.attributes]);

  const requestForDates = () => {};

  const updateGroupOrder = ({ payload, params }) => {
    store?.mealManagementStore?.updateGroupOrder(order?.id, payload, params);
  };

  const mutation = useMutation({
    mutationFn: updateGroupOrder,
    onSuccess: () => {
      getOrder();
      setModal(false);
    },
    onError: (error) => {
      console.error("Error updating record:", error);
      // Optionally show error message
    },
  });

  const submit = async (e) => {
    e.preventDefault();

    fetchOrderDeadline();
  };

  const fetchOrderDeadline = () => {
    if (provider.expectedAttendees && provider.perPersonBudget) {
      const params = {
        budget: provider.expectedAttendees * provider.perPersonBudget,
        target_time: order?.attributes?.meals[0]?.attributes?.target_time,
        meal_date: order?.attributes?.meals[0]?.attributes?.meal_date,
      };

      store?.mealManagementStore
        .fetchOrderDeadline(
          order?.attributes?.meals[0]?.attributes?.menu_id,
          params
        )
        .then((response) => {
          if (response.status === 200) setPayloads(response?.order_deadline);
          else setModal(false);
        });
    }
  };

  const setPayloads = (orderDeadline) => {
    let payload = {
      meal_series: {
        service: "grouped",
        name: groupOrderName,
        subsidy: perPersonBudget,
        expected_bags: expectedAttendees,
        // tip: provider?.tip === 'other' ? provider?.otherTip : provider?.tip,
        cutoff: orderDeadline,
        meals_attributes: [
          {
            cutoff: orderDeadline,
            id: order?.attributes?.meals[0]?.attributes?.id,
          },
        ],
      },
    };

    if (provider.deliveryInstructionsLevel == "no") {
      payload.meal_series.delivery_instructions = provider.deliveryInstructions;
    }

    let params = {
      location_id: order?.attributes?.location_id,
    };

    if (provider.deliveryInstructionsLevel == "yes")
      params.delivery_instructions = provider.deliveryInstructions;

    mutation.mutate({ payload, params });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[70]"
        onClose={() => {
          screenType?.isTabletOrDesktop && setModal(false);
        }}
      >
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col flex-1 md:flex-initial w-full md:w-5/12 transform sm:rounded-2xl md:rounded-2xl bg-white py-9 px-[37px] text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => setModal(false)}
                      className="btn-style"
                    >
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>

                  <div className="flex w-full">
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                      }}
                      className="font-inter-semibold leading-6 text-black-light"
                    >
                      <span className="text-[32px]">
                        Manage Your Group order
                      </span>
                    </Dialog.Title>
                  </div>

                  <div className="flex flex-col flex-wrap mt-6 md:w-full sm:pb-0">
                    <form onSubmit={submit} autoComplete="off">
                      <div className="flex flex-col">
                        <span className="text-md2 font-inter-semibold">
                          Group order name*
                        </span>

                        <input
                          required
                          name="groupOrderNameField"
                          className="input-gray-bg mt-3 focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background pl-4"
                          placeholder="ex. Friday lunch & Learn"
                          value={groupOrderName}
                          onChange={(e) =>
                            dispatch({
                              field: "groupOrderName",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="flex flex-col mt-9">
                        <span className="text-md2 font-inter-semibold">
                          Budget and attendees*
                        </span>

                        <span className="text-sm mt-[6px]">
                          The number of expected attendees affects the ensuring
                          deadline to orders
                        </span>

                        <div className="mt-3 flex flex-col sm:grid sm:grid-cols-2 sm:gap-3">
                          <div className="flex mb-3 sm:mb-0 items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12">
                            <span className="mt-[1px]">$</span>

                            <input
                              required
                              name="perPersonBudgetField"
                              className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background"
                              placeholder="Per person budget"
                              onWheelCapture={(e) => {
                                e.target.blur();
                              }}
                              value={perPersonBudget}
                              onBlur={requestForDates}
                              onChange={(e) =>
                                dispatch({
                                  field: "perPersonBudget",
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>

                          <input
                            required
                            name="expectedAttendeesField"
                            className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background pl-4"
                            placeholder="Expected attendees"
                            value={expectedAttendees}
                            onWheelCapture={(e) => {
                              e.target.blur();
                            }}
                            type="number"
                            onBlur={requestForDates}
                            onChange={(e) =>
                              dispatch({
                                field: "expectedAttendees",
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="flex flex-col mt-9">
                        <span className="text-md2 font-inter-semibold">
                          Edit delivery instructions
                        </span>

                        <textarea
                          placeholder="Ex. Park in the back, go in and take the elevator on the right to the 4th and...."
                          className="input-gray-bg resize mt-3 focus:ring-0 address-inputs my-0 h-[130px] rounded-xl text-md w-full bg-background pl-4"
                          type="text"
                          value={provider.deliveryInstructions}
                          onChange={(e) => {
                            dispatch({
                              field: "deliveryInstructions",
                              value: e.target.value,
                            });
                          }}
                        />

                        <fieldset>
                          <p className="text-md2 font-inter-semibold mt-[16px]">
                            Should we apply these instructions to all deliveries
                            with this address?
                          </p>

                          <div className="mt-[12px] space-y-2">
                            {notificationMethods.map((notificationMethod) => (
                              <div
                                key={notificationMethod.id}
                                className="flex items-center"
                              >
                                <input
                                  id={notificationMethod.id}
                                  name="notification-method"
                                  type="radio"
                                  defaultChecked={
                                    deliveryInstructionsLevel ===
                                    notificationMethod.id
                                  }
                                  onChange={(e) => {
                                    dispatch({
                                      field: "deliveryInstructionsLevel",
                                      value: e.target.id,
                                    });
                                  }}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label
                                  htmlFor={notificationMethod.id}
                                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {notificationMethod.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>

                      <div className="mt-6 w-full">
                        <div className="hidden sm:flex justify-center items-center mt-6">
                          <SubmitButtons
                            onClickLeft={() => setModal(false)}
                            leftButtonText={"Cancel"}
                            rightButtonText={"Apply"}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(ManageOrderDetailsModal);
