import React from "react";

const SmallTeamMessage = () => {
  return (
    <>
      <div className="text-gray-800 font-inter-semibold">
        Our meal programs are designed for teams of 20 or more
      </div>

      <div className="lg:w-[480px] md:w-[480px] w-full">
        <span className="text-gray-800 font-inter-regular">
          For teams smaller than 20 please use{" "}
        </span>

        <a
          href={process.env.REACT_APP_CATERING_APP}
          className="text-indigo-500 font-inter-semibold hover:cursor-pointer"
        >
          <span>Group Orders</span>
        </a>
      </div>
    </>
  );
};

export default SmallTeamMessage;
