import React, { useContext, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Loader from "components/shared/loader";
import { MobXProviderContext } from "mobx-react";
import { observer } from "mobx-react";
import Cookies from "js-cookie";
import { useIsFetching } from "@tanstack/react-query";
const FreshChatComponent = React.lazy(() =>
  import("components/FreshChat/index")
);

function App() {
  const { userStore } = useContext(MobXProviderContext);
  const { isLoading } = userStore;
  const currentUser = userStore.currentUser;
  const [showFreshChat, setShowFreshChat] = useState(false);

  if (!window.store) window.store = userStore;

  // Check for user session info in cookies
  const cookiesInfoPresent =
    !!Cookies.get("user-token") &&
    !!Cookies.get("employee-id") &&
    !!Cookies.get("email");

  // Fetch user profile if cookie info is present
  useEffect(() => {
    if (cookiesInfoPresent)
      userStore.getProfile(
        Cookies.get("employee-id"),
        Cookies.get("user-token"),
        Cookies.get("email")
      );
  }, [cookiesInfoPresent, userStore]);

  // Set up routes conditionally based on whether user is authenticated
  const routes =
    cookiesInfoPresent && currentUser?.id
      ? PrivateRoute() // Only add private routes if the user is authenticated
      : PublicRoute(); // Always include public routes

  // Create the router
  const router = createBrowserRouter(routes);

  // Show loader during data fetching or loading
  const isFetching = useIsFetching();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFreshChat(true);
    }, 4000); // 4 seconds delay

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {(isFetching || isLoading) && <Loader />}

      <RouterProvider router={router} />
      {showFreshChat && <FreshChatComponent />}
    </>
  );
}

export default observer(App);
