import { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  GroupIcon,
  calendarIcon,
  CategoryIcon,
  Document,
  FaqIcon,
  WalletIcon,
  ToolsIcon,
  LocationIcon,
  hhIcon,
  hhLogo,
  arrowDown,
  uncateringIcon,
  LogoutIcon,
  ChevronLeftIcon,
  LogoHH,
  UserIcon,
  CheckMark,
  ChevronRight,
  NewBadge,
  SettingsIcon,
  BusinessIcon,
} from "assets/img";
import { Outlet, useLocation, NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { MobXProviderContext, observer } from "mobx-react";
import { toJS } from "mobx";
import {
  getLocationEmployees,
  getLocationEmployeesWithAdmin,
  getUncateringLocation,
  getLocationsEligibleForUncatering,
} from "utils/helpers/array";
import Cookies from "js-cookie";
import LocalStorage from "utils/helpers/localStorage";
import { Disclosure } from "@headlessui/react";
import ErrorAlert from "components/Alerts/ErrorAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "components/ConfirmationModal";
import restaurantStore from "mobXstore/restaurantStore";

const navigation = [
  { name: "Home", href: "/home", icon: CategoryIcon, current: true },
  {
    name: "Meal Management",
    href: "/meal-management",
    icon: calendarIcon,
    current: false,
  },
  {
    name: "Delivery Info",
    href: "/delivery-info",
    icon: LocationIcon,
    current: false,
  },
  {
    name: "Payment Info",
    href: "/payment-info",
    icon: WalletIcon,
    current: false,
  },
  {
    name: "Uncatering",
    href: "/uncatering",
    icon: hhIcon,
    current: false,
    children: [
      { name: "Team", href: "/uncatering/team" },
      { name: "Place orders", href: "/uncatering/place-orders" },
      { name: "Invoices", href: "/uncatering/invoices" },
      { name: "Team Preferences", href: "uncatering/team-preferences" },
    ],
  },
  {
    name: "Order History",
    href: "/order-history",
    icon: Document,
    current: false,
  },
  {
    name: "Our Services",
    href: "/our-services",
    icon: ToolsIcon,
    current: false,
  },
  { name: "FAQs", href: "/faq", icon: FaqIcon, current: false },
  { name: "Support", href: "/support", icon: GroupIcon, current: false },
];

const userNavigation = [{ name: "Log Out", href: "#", icon: LogoutIcon }];
const userAccountNavigation = [
  { name: "Switch to user account", href: "/user-account", icon: UserIcon },
  { name: "Log Out", href: "/logout", icon: LogoutIcon },
];

const hungerhubOffices = [
  { isChecked: true, name: "Tik Tok", code: "TIK121" },
  { isChecked: true, name: "hungerhub Office", code: "HUB180" },
  { isChecked: false, name: "EY", code: "EY1921" },
  {
    isChecked: false,
    name: "Super long office name to display here",
    code: "GHJ921",
  },
];

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hideSideBar, setHideSidebar] = useState(false);
  const location = useLocation();
  const { userStore } = useContext(MobXProviderContext);
  const store = useContext(MobXProviderContext);

  const currentUser = userStore.currentUser;
  const defaultRegion = userStore.defaultRegion;
  const locationEmployees = getLocationEmployees(currentUser);
  const isUncateringEnabled = getUncateringLocation(currentUser);
  const adminEmployees = getLocationEmployeesWithAdmin(currentUser);
  const locationsEligibleForUncatering =
    getLocationsEligibleForUncatering(currentUser);
  const isVisible = userStore.error;
  let { activeRestaurant } = restaurantStore ?? {};
  let { defaultLocation, errorMessage, allMenus } = userStore;

  const [showExpandedSidebar, setShowExpandedSidebar] = useState(false);
  const [expandedSidebarOpen, setExpandedSidebarOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentMeal, setCurrentMeal] = useState("Catering order");

  const currentURL = new URL(window.location.href);
  const [isMarketplaceURl, setIsMarketplaceUrl] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsMarketplaceUrl(
      currentURL.pathname?.startsWith("/marketplace/payment-info")
    );
  }, [currentURL.pathname]);

  useEffect(() => {
    setShowDropdown(false);

    if (
      location.pathname === "/get-started-uncatering" ||
      location.pathname === "/get-started-catering" ||
      location.pathname.startsWith("/get-started-catering/restaurant/") ||
      location.pathname.startsWith("/get-started-catering/checkout") ||
      location.pathname.startsWith("/get-started-group") ||
      // location.pathname.startsWith("/group-order/new") ||
      location.pathname.startsWith("/group-order/confirmation/") ||
      location.pathname.startsWith("/group-order/orders") ||
      location.pathname.startsWith("/group-order/admin/group-order-new") ||
      location.pathname === "/group-order/faqs"
    ) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }

    if (location.pathname.startsWith("/get-started-uncatering"))
      setCurrentMeal("Uncatering meal");
    if (location.pathname.startsWith("/get-started-catering"))
      setCurrentMeal("Catering order");
    if (location.pathname.startsWith("/get-started-group"))
      setCurrentMeal("Group Order");
  }, [location.pathname]);

  const onUserNavigationClick = useCallback((item) => {
    if (item?.name === "Log Out") {
      const source = Cookies.get("source");
      const domains =
        process.env.NODE_ENV === "development"
          ? ["localhost"]
          : [".hungerhub.com", "localhost"];

      domains.forEach((domain) => {
        Cookies.remove("employee-id", { path: "/", domain });
        Cookies.remove("email", { path: "/", domain });
        Cookies.remove("user-token", { path: "/", domain });
        Cookies.remove("source", { path: "/", domain });
      });

      LocalStorage.removeKey("auth_token");
      userStore.clearStoredData();

      window.location.href =
        source === "uncatering"
          ? process.env.REACT_APP_UNCATERING_APP_LINK
          : process.env.REACT_APP_CATERING_APP_LINK;
    } else if (item === "uncatering-portal") {
      window.location.href = process.env.REACT_APP_UNCATERING_APP_LINK;
    }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.querySelector(".expanded-sidebar");

      if (
        expandedSidebarOpen &&
        !buttonClicked &&
        sidebar &&
        !sidebar.contains(event.target)
      ) {
        setShowExpandedSidebar(false);
        setExpandedSidebarOpen(false);
      }
      setButtonClicked(false);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [expandedSidebarOpen, buttonClicked]);

  const afterCloseCallBack = () => {
    store.userStore.setErrorAlert(false, "");
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-blue-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        {/* <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        /> */}
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-sidebar-gray px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                      />
                    </div>

                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={({ isActive }) => {
                                    const isItemActive =
                                      isActive ||
                                      location.pathname.startsWith(item.href);
                                    return `group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-inter-normal py-[16px] text-secondary hover:text-white hover:bg-gray-300 ${
                                      isItemActive
                                        ? "bg-white text-#2D3958 font-bold"
                                        : "text-#566985"
                                    }`;
                                  }}
                                >
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        {!hideSideBar && (
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-sidebar-gray px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center mt-[12px]">
                <img
                  className="h-[56px] w-[152px] m-[16px]"
                  src={hhLogo}
                  alt="hungerhub Company"
                />
              </div>

              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          {!item.children ? (
                            <NavLink
                              to={item.href}
                              className="group opacity-50 items-center flex gap-x-3 rounded-md p-2 text-sm leading-6 font-inter-normal py-[16px] text-secondary hover:text-white hover:bg-gray-300"
                              style={({
                                isActive,
                                isPending,
                                isTransitioning,
                              }) => {
                                const isPaymentInfoActive =
                                  item.name == "Payment Info" &&
                                  isMarketplaceURl;

                                return {
                                  backgroundColor:
                                    isPaymentInfoActive || isActive
                                      ? "white"
                                      : "transparent",
                                  color:
                                    isPaymentInfoActive || isActive
                                      ? "#2D3958"
                                      : "#566985",
                                  fontWeight:
                                    isPaymentInfoActive || isActive
                                      ? "bold"
                                      : "normal",
                                };
                              }}
                            >
                              <img
                                src={item.icon}
                                alt={item.name}
                                className="h-[20px] w-[20px]"
                              />
                              {item.name}
                            </NavLink>
                          ) : (
                            <Disclosure as="div">
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      "group flex gap-x-3 items-center rounded-md p-2 text-sm leading-6 font-inter-normal py-[16px] text-secondary hover:text-gray-900"
                                    )}
                                  >
                                    <img
                                      src={item.icon}
                                      alt={item.name}
                                      className="h-[20px] w-[20px]"
                                    />

                                    {item.name}

                                    <FontAwesomeIcon
                                      style={{ width: 12, height: 12 }}
                                      icon={open ? faChevronUp : faChevronDown}
                                      color="#000"
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel
                                    as="ul"
                                    className="mt-1 px-2"
                                  >
                                    {item.children.map((subItem) => (
                                      <li key={subItem.name}>
                                        <NavLink
                                          to={subItem.href}
                                          className="group pl-[41px] flex gap-x-3 rounded-md p-2 text-sm leading-6 font-inter-normal py-[16px] text-secondary hover:text-white hover:bg-gray-300"
                                          style={({
                                            isActive,
                                            isPending,
                                            isTransitioning,
                                          }) => {
                                            return {
                                              backgroundColor: isActive
                                                ? "white"
                                                : "transparent",
                                              color: isActive
                                                ? "#2D3958"
                                                : "#566985",
                                              fontWeight: isActive
                                                ? "bold"
                                                : "normal",
                                            };
                                          }}
                                        >
                                          {subItem.name}
                                        </NavLink>
                                      </li>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
        {/* Static sidebar for tablet */}{" "}
        {showExpandedSidebar && expandedSidebarOpen && (
          <div className="hidden lg:hidden md:fixed md:inset-y-0 md:z-50 flex w-60 flex-col expanded-sidebar">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <Transition.Root show={showExpandedSidebar} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50 lg:hidden md:fixed md:inset-y-0 md:z-50 md:flex md:w-60 md:flex-col"
                open={showExpandedSidebar}
                onClose={setShowExpandedSidebar}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0 z-50 bg-black bg-opacity-50 lg:hidden"
                    style={{
                      left: "240px",
                      width: "calc(100vw - 240px)",
                    }}
                  />
                </Transition.Child>

                <div
                  className="flex grow flex-col gap-y-5 bg-sidebar-gray px-6 pb-4"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex h-16 shrink-0 mt-[12px]">
                    <img
                      className="h-[56px] w-[152px] mt-[16px] mb-[16px]"
                      src={hhLogo}
                      alt="hungerhub Company"
                    />
                  </div>

                  <Menu as="div" className="relative hidden lg:hidden md:flex">
                    <Menu.Button className="-m-1.5 flex flex-col p-1.5">
                      <small className="font-inter-regular text-secondary text-xs">
                        Destination code: HUB180
                      </small>

                      <span className="hidden lg:hidden md:flex">
                        <span
                          className="text-sm font-semibold leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          hungerhub Office
                        </span>

                        <img className="h-4 w-4 ml-2 mt-1" src={arrowDown} />
                      </span>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-16 origin-top-right rounded-xl bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none w-52">
                        <div className="text-slate-500 text-xs font-inter-medium h-12 justify-center items-center flex">
                          List of offices
                        </div>

                        {hungerhubOffices.map((item) => (
                          <Menu.Item key={item.name}>
                            <div className="grid grid-cols-3 text-sm leading-6 text-gray-900 justify-center items-center border-t border-slate-50 py-3">
                              <div className="flex justify-center items-center">
                                {item.isChecked ? (
                                  <img
                                    className="h-4 w-4 justify-center items-center"
                                    src={CheckMark}
                                    alt="check-mark"
                                  />
                                ) : (
                                  <div className="h-4 w-4"></div>
                                )}
                              </div>
                              <div className="w-24">
                                <div className="font-inetr-medium text-base leading-tight text-slate-700">
                                  {item.name}
                                </div>

                                <div className="text-xs text-gray-500 items-start">
                                  {item.code}
                                </div>
                              </div>
                            </div>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1 mt-[20px]">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-inter-normal py-[16px] text-secondary hover:text-white hover:bg-gray-300"
                                style={({
                                  isActive,
                                  isPending,
                                  isTransitioning,
                                }) => {
                                  return {
                                    backgroundColor: isActive
                                      ? "white"
                                      : "transparent",
                                    color: isActive ? "#2D3958" : "#566985",
                                    fontWeight: isActive ? "bold" : "normal",
                                  };
                                }}
                              >
                                <img
                                  src={item.icon}
                                  alt={item.name}
                                  className="h-[20px] w-[20px]"
                                />
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>

                  <nav className="flex flex-1 flex-col absolute bottom-8">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1 mt-[20px]">
                          {userAccountNavigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-inter-medium py-[16px] text-secondary hover:text-white hover:bg-gray-300"
                                style={({
                                  isActive,
                                  isPending,
                                  isTransitioning,
                                }) => {
                                  return {
                                    backgroundColor: isActive
                                      ? "white"
                                      : "transparent",
                                    color: isActive ? "#2D3958" : "#2D3958",
                                    fontWeight: isActive ? "bold" : "normal",
                                  };
                                }}
                              >
                                <img
                                  src={item.icon}
                                  alt={item.name}
                                  className="h-[20px] w-[20px]"
                                />
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        )}
        {!showExpandedSidebar && !expandedSidebarOpen && (
          <div className="hidden lg:hidden md:fixed md:inset-y-0 md:z-50 md:flex w-20 flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 bg-sidebar-gray px-4 pb-4">
              <div className="flex h-16 shrink-0 mt-[12px]">
                <img
                  className="h-[56px] w-[152px] mt-[16px] mb-[16px]"
                  src={LogoHH}
                  alt="hungerhub Company"
                />
              </div>
              <small className="font-inter-regular text-secondary text-xs">
                HUB180
              </small>

              <nav className="flex flex-1 flex-col justify-center items-center">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1 mt-[20px]">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <NavLink
                            to={item.href}
                            className="group flex gap-x-3 rounded-md p-4 text-sm leading-6 font-inter-normal py-[16px] text-secondary hover:text-white hover:bg-gray-300"
                            style={({
                              isActive,
                              isPending,
                              isTransitioning,
                            }) => {
                              return {
                                backgroundColor: isActive
                                  ? "white"
                                  : "transparent",
                                color: isActive ? "#2D3958" : "#566985",
                                fontWeight: isActive ? "bold" : "normal",
                              };
                            }}
                          >
                            <img
                              src={item.icon}
                              alt={item.name}
                              // className="h-[20px] w-[20px]"
                              className="h-6 w-6"
                            />
                            {/* {item.name} */}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>

              <div className="flex flex-col justify-center items-center b-0">
                <button
                  className="w-8 h-8 bg-indigo-500 rounded-xl flex justify-center items-center"
                  onClick={() => {
                    setShowExpandedSidebar(true);
                    setExpandedSidebarOpen(true);
                    setButtonClicked(true);
                  }}
                >
                  <img
                    src={ChevronRight}
                    alt="right-chevron"
                    className="w-4 h-4"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="lg:hidden md:hidden sm:flex inset-x-0 z-50 bottom-0 fixed h-26 flex-row overflow-x-auto bg-sidebar-gray">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-row gap-5 px-6 py-4">
            <nav className="flex flex-row justify-center items-center">
              <ul role="list" className="flex flex-1 flex-row gap-x-7">
                {navigation.map((item) => (
                  <li
                    key={item.name}
                    className="-mx-2 space-x-1 w-[80px] items-center"
                  >
                    <NavLink
                      to={item.href}
                      className="group flex flex-col gap-2 rounded-md text-sm leading-6 font-inter-normal text-secondary items-center"
                      style={({ isActive, isPending, isTransitioning }) => {
                        return {
                          // backgroundColor: isActive ? "white" : "transparent",
                          color: isActive ? "#2D3958" : "#566985",
                          fontWeight: isActive ? "bold" : "normal",
                          "items-center": true,
                        };
                      }}
                    >
                      <img
                        src={item.icon}
                        alt={item.name}
                        // className="h-[20px] w-[20px]"
                        className="h-7 w-7"
                      />
                      {item.name.split(" ")[0]}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        <div
          className={classNames({
            "pl-0": hideSideBar,
            "lg:pl-72": !hideSideBar,
            // "md:pl-20": !showExpandedSidebar,
            "sm:pl-0": true,
          })}
        >
          {!hideSideBar && (
            <div className="hidden lg:flex md:flex sticky top-0 z-40 flex h-[92px] bg-white shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-gray-900/10 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 gap-x-4 h-full self-stretch lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-6 w-full flex justify-between">
                  <div />

                  <Menu as="div" className="relative hidden lg:flex">
                    <Menu.Button className="-m-1.5 flex flex-col items-center p-1.5">
                      <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          {defaultLocation?.label}
                        </span>

                        <img
                          className="ml-2 h-[12px] w-[12px]"
                          src={arrowDown}
                        />
                      </span>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none w-[300px]">
                        {adminEmployees
                          .concat(locationsEligibleForUncatering)
                          .map((employee, ind, arr) => (
                            <Menu.Item
                              key={employee.label}
                              onClick={() => {
                                if (
                                  employee.uncatering &&
                                  employee.role == "full_time"
                                )
                                  window.location.href =
                                    process.env.REACT_APP_UNCATERING_APP_LINK;
                                else {
                                  userStore.setdefaultLocation(employee);
                                  // navigate("/");
                                }
                              }}
                            >
                              {({ active }) => (
                                <div
                                  className={classNames({
                                    "bg-gray-50": active,
                                    "px-[12px] py-[16px] border items-center border-light-gray border-b border-t-0 border-l-0 border-r-0 leading-6 d-row justify-between": true,
                                    "border-none": ind == arr.length - 1,
                                    "bg-[#F8F9FF]":
                                      employee?.label == defaultLocation?.label,
                                  })}
                                >
                                  <small className="text-dark-gray font-inter-medium text-base">
                                    {employee.label}
                                  </small>
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <Menu
                    as="div"
                    className="relative w-[342px] flex justify-end"
                  >
                    <Menu.Button className="-m-1.5 flex p-1.5">
                      <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          {currentUser?.attributes?.first_name}{" "}
                          {currentUser?.attributes?.last_name}
                        </span>

                        <img
                          className="ml-2 h-[12px] w-[12px]"
                          src={arrowDown}
                        />
                      </span>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute top-5 right-0 z-10 mt-2.5 w-full origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {isUncateringEnabled && defaultLocation?.uncatering && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() =>
                                  onUserNavigationClick("uncatering-portal")
                                }
                                className={classNames({
                                  "bg-gray-50": active,
                                  "px-[12px] w-full py-[16px] border items-center border-light-gray border-b border-t-0 border-l-0 border-r-0 leading-6 d-row": true,
                                })}
                              >
                                <img
                                  className="h-4 w-4 object-contain mr-[16px]"
                                  src={uncateringIcon}
                                />
                                Go to Uncatering Ordering Portals
                              </button>
                            )}
                          </Menu.Item>
                        )}

                        {userNavigation.map((item, ind, arr) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <button
                                onClick={() => onUserNavigationClick(item)}
                                className={classNames({
                                  "bg-gray-50": active,
                                  "px-[12px] w-full py-[16px] border items-center border-light-gray border-b border-t-0 border-l-0 border-r-0 leading-6 d-row": true,
                                  "border-none": ind == arr.length - 1,
                                })}
                              >
                                <img
                                  className="h-4 w-4 object-contain mr-[16px]"
                                  src={item.icon}
                                />

                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          )}

          {hideSideBar &&
            (!location.pathname.startsWith("/group-order/orders") ||
              !location.pathname.startsWith(
                "/group-order/admin/group-order-new"
              ) ||
              !location.pathname.startsWith("/group-order/faqs")) && (
              <div className="sticky top-0 z-40 flex h-[92px] bg-white shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 d-row justify-between">
                <div class="grid grid-cols-3 w-full">
                  {location.pathname.startsWith("/get-started-uncatering") ||
                  (!location.pathname.includes(
                    "/get-started-catering/checkout/"
                  ) &&
                    location.pathname !== "/group-order/new" &&
                    !location.pathname.includes(
                      "/group-order/confirmation"
                    )) ? (
                    <div className="d-row items-center">
                      <img src={ChevronLeftIcon} className="h-4 w-4" />

                      <button
                        onClick={() => {
                          if (allMenus?.length > 0)
                            setShowConfirmationModal(true);
                          else {
                            setHideSidebar(false);
                            navigate("/meal-management");
                          }
                        }}
                      >
                        {" "}
                        Return to admin panel
                      </button>
                    </div>
                  ) : location.pathname !== "/group-order/new" &&
                    !location.pathname.includes("/group-order/confirmation") ? (
                    <div className="d-row items-center">
                      <img src={ChevronLeftIcon} className="h-4 w-4" />

                      <button
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        {" "}
                        Go back to {
                          activeRestaurant?.attributes?.display_name
                        }{" "}
                        menu
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div className="d-col justify-center items-center">
                    <img
                      className="h-[56px] w-[152px] m-[16px]"
                      src={hhLogo}
                      alt="hungerhub Company"
                    />
                  </div>

                  {!location.pathname.startsWith("/get-started-uncatering") &&
                    !location.pathname.startsWith(
                      "/get-started-catering/checkout/"
                    ) && (
                      <div className="d-col justify-center items-end">
                        <div
                          className="d-row cursor-pointer"
                          onClick={() => setShowDropdown(true)}
                        >
                          <img
                            src={BusinessIcon}
                            alt="business-icon"
                            className="w-[15px] h-[15px] mt-0.5 mr-2"
                          />

                          <span className="text-slate-700 font-inter-medium text-base leading-tight capitalize">
                            {currentUser?.attributes?.first_name}{" "}
                            {currentUser?.attributes?.last_name}
                          </span>

                          <span className="text-slate-700 font-inter-medium text-base leading-tight ml-1">
                            (admin)
                          </span>

                          <span className="text-slate-700 font-inter-medium text-base leading-tight">
                            <img
                              src={arrowDown}
                              alt="arrow-down"
                              className="w-[13px] h-[13px] mt-1 ml-4"
                            />
                          </span>
                        </div>

                        {showDropdown && (
                          <Transition appear show={showDropdown} as={Fragment}>
                            <Dialog
                              as="div"
                              unmount={false}
                              onClose={() => {
                                setShowDropdown(false);
                              }}
                              className="relative z-[90]"
                            >
                              <Transition.Child
                                as={Fragment}
                                enter="ease-in duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-out duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div
                                  onClick={() => setShowDropdown(false)}
                                  className="fixed inset-0 bg-black bg-opacity-25"
                                />
                              </Transition.Child>

                              <div className="fixed sm:top-[65px] sm:right-[65px]">
                                <Transition.Child
                                  as={Fragment}
                                  enter="transition ease-in-out duration-300 transform"
                                  enterFrom="translate-x-full"
                                  enterTo="translate-x-0"
                                  leave="transition ease-out-in duration-300 transform"
                                  leaveFrom="translate-x-0"
                                  leaveTo="translate-x-full"
                                >
                                  <div className="flex w-full">
                                    <div
                                      className={`z-[60] flex flex-col bg-white rounded-xl w-full shadow-xl`}
                                    >
                                      <Dialog.Panel className="overflow-scroll w-full">
                                        <div className="flex flex-col justify-between bg-white rounded-xl">
                                          <div className="flex flex-col items-start">
                                            <div
                                              className="px-8 pt-4 pb-2 hover:bg-slate-50 hover:rounded-t-xl text-md btn-style w-full cursor-pointer inline-flex"
                                              onClick={() => navigate("/home")}
                                            >
                                              <img
                                                src={SettingsIcon}
                                                alt="settings-icon"
                                              />
                                              <span className="ml-4">
                                                Go to admin panel
                                              </span>
                                            </div>

                                            {isUncateringEnabled &&
                                              defaultLocation?.uncatering && (
                                                <div
                                                  className="px-8 pt-4 pb-2 hover:bg-slate-50 text-md btn-style w-full cursor-pointer inline-flex"
                                                  onClick={() =>
                                                    onUserNavigationClick(
                                                      "uncatering-portal"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={UserIcon}
                                                    alt="user-icon"
                                                  />
                                                  <span className="ml-4">
                                                    Switch to user account
                                                  </span>
                                                </div>
                                              )}

                                            <div
                                              className="px-8 pt-2 pb-2 hover:bg-slate-50 hover:rounded-b-xl text-md btn-style w-full cursor-pointer inline-flex"
                                              onClick={() =>
                                                onUserNavigationClick({
                                                  name: "Log Out",
                                                })
                                              }
                                            >
                                              <img
                                                src={LogoutIcon}
                                                alt="logout-icon"
                                                className="w-[15px] h-[15px] mt-0.5 ml-0.5"
                                              />
                                              <span className="ml-4">
                                                Logout
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </Dialog.Panel>
                                    </div>
                                  </div>
                                </Transition.Child>
                              </div>
                            </Dialog>
                          </Transition>
                        )}
                      </div>
                    )}
                </div>
              </div>
            )}

          <main className="pb-10 flex min-h-screen min-w-screen">
            <Outlet />
          </main>
        </div>
        <ErrorAlert
          visible={isVisible}
          errorMessage={errorMessage}
          dismissAlert={afterCloseCallBack}
        />
        {showConfirmationModal && (
          <ConfirmationModal
            visible={showConfirmationModal}
            setModal={setShowConfirmationModal}
            title={`Are you sure you want to stop creating your ${currentMeal}?`}
            body="Your progress will not be saved."
            rightButtonText="Stay on Page"
            onClickRightButton={() => {
              setShowConfirmationModal(false);
            }}
            leftButtonText="Go back to admin panel"
            onClickLeftButton={() => {
              setHideSidebar(false);
              setShowConfirmationModal(false);
              navigate("/meal-management");
            }}
          />
        )}
      </div>
    </>
  );
}

export default observer(Layout);
