import React, { useState, useEffect, useContext } from "react";
import SmallTeamMessage from "./teamMessage";
import ActiveStepIndicator from "./activeStepIndicator";
import { peopleCount } from "utils/constants/onboardingStep";
import classNames from "classnames";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";

const Step1 = ({ disableButton }) => {
  const store = useContext(MobXProviderContext);
  const onboardingStore = toJS(store?.onboardingStore);
  const { expectedBag } = onboardingStore;

  const [expectedBags, setExpectedBag] = useState(() => {
    if (expectedBag == 20) {
      return "20-39";
    } else if (expectedBag == 40) {
      return "40-59";
    } else if (expectedBag == 60) {
      return "60-79";
    } else if (expectedBag == 80) {
      return "80-99";
    } else if (expectedBag == 100) {
      return "100+";
    } else {
      return "";
    }
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    disableButton(expectedBag === "" || expectedBag === "<20");
  }, []);

  const handleExpectedBags = (value) => {
    setExpectedBag(value);
    store.onboardingStore.handleExpectedBagData(value);
    disableButton(value === "" || value === "<20");
  };

  return (
    <div className="pb-4 mt-4">
      <ActiveStepIndicator step={1} />

      <div className="flex-col flex gap-6">
        <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
          <h1>How many people do you expect to order on your meal plan?</h1>
        </div>

        <div className="mb-0.5 w-full screen-xs:w-full-215">
          <div className="screen-lg:inline-flex screen-md:inline-flex">
            {peopleCount?.map((value) => (
              <React.Fragment key={value}>
                <div
                  className={classNames(
                    "screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer font-inter-medium text-sm leading-[14px] rounded-3xl justify-start gap-2 flex",
                    {
                      "bg-indigo-500 text-white": expectedBags === value,
                      "bg-slate-50 text-slate-700 screen-lg:hover:bg-indigo-500 screen-lg:hover:text-white":
                        expectedBags !== value,
                    }
                  )}
                  onClick={() => handleExpectedBags(value)}
                >
                  {value}
                </div>

                {value === "<20" && expectedBags === value && (
                  <div className="hidden screen-lg:hidden screen-md:hidden screen-sm:flex link-div my-4">
                    <SmallTeamMessage />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {expectedBags === "<20" && (
          <div className="hidden screen-lg:flex screen-md:flex my-4">
            <div className="link-div">
              <SmallTeamMessage />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
