export const formattedAddres = (address) => {
  const nickname = address?.nickname ? `${address.nickname} ` : "";
  const destinationCode = address?.destination_code
    ? `(${address.destination_code}) `
    : "";
  const suite = address?.suite ? `${address.suite} - ` : "";
  const streetNumber = address?.street_number
    ? `${address.street_number} `
    : "";
  const streetName = address?.street_name ? `${address.street_name}, ` : "";
  const city = address?.city ? `${address.city}, ` : "";
  const province = address?.province ? `${address.province} ` : "";
  const postalCode = address?.postal_code ? `${address.postal_code}` : "";

  let addres =
    `${nickname}${destinationCode}- ${suite}${streetNumber}${streetName}${city}${province}${postalCode}`.trim();

  //to remove extra spaces
  addres = addres.replace(/\s+/g, " ");

  return Object.values(address || {}).every((value) => value === "")
    ? "-"
    : addres;
};
