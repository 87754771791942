import classNames from "classnames";

const { faCircleCheck } = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

const ConfirmationBadge = ({ text, applyLeft }) => (
  <div
    className={classNames({
      "fixed flex z-[100] w-fit top-[100px] animate-fade-in-down bg-hh-accent-light items-center justify-center py-2 px-4 rounded-[7px]": true,
      "left-1/2 top-[10px]": applyLeft,
    })}
  >
    <FontAwesomeIcon icon={faCircleCheck} className="icon text-white mr-3" />
    <span className="text-white text-sm">{text}</span>
  </div>
);

export default ConfirmationBadge;
