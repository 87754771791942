import React from "react";
import classNames from "classnames";

const ActiveStepIndicator = ({ step }) => {
  const totalSteps = 6;
  const stepsArray = Array.from(
    { length: totalSteps },
    (_, index) => index + 1
  );

  return (
    <div className="d-row gap-1.5 mb-6">
      {stepsArray.map((currentStep) => (
        <div
          key={currentStep}
          className={classNames({
            "active-step": currentStep <= step,
            "inactive-step": currentStep > step,
          })}
        ></div>
      ))}
    </div>
  );
};

export default ActiveStepIndicator;
