import { observer } from "mobx-react";
import { useReducer } from "react";
import classNames from "classnames";
import { Disclosure, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import EmbedHTML from "components/shared/embedHTML";

const Faqs = () => {
  const initialState = {
    activeCategory: "On-Demand Catering Vs. Group Order",
    faqCategories: [
      "On-Demand Catering Vs. Group Order",
      "Contracts and Agreements",
      "Placing & managing orders",
      "Group Orders",
      "Deliveries",
      "Issues with food quality or accuracy",
      "Uncatering",
    ],
    chosenCategory: [],
    faqs: {
      "On-Demand Catering Vs. Group Order": [
        {
          question:
            "What’s the difference between Group orders and On-Demand Catering?",
          answer:
            "On-Demand Catering means one person chooses, orders and pays for the entire catering order. Group Order is the process of initiating a shared shopping cart, where you invite others (via sharing a link) and allow others to customize and place their orders, within a budget set - when the order closes, it is sent to the restaurant and your credit card is charged.",
        },
        {
          question: "Who chooses the restaurant?",
          answer:
            "You can browse and choose all available restaurants to select which one you’d like to select for either a Group Order or On-Demand Delivery.",
        },
      ],
      "Contracts and Agreements": [
        {
          question:
            "Do these services require any account creations, contracts or agreements in place?",
          answer:
            "No. On-Demand Catering and Group Orders do not require the creation of any accounts, no agreements in place and no contracts to sign, you can even check-out as a guest.",
        },
      ],
      "Placing & managing orders": [
        {
          question: "How do I place an order?",
          answer:
            "Based on the date, time and location you will be able to select a restaurant that is available for that day. Open a restaurant’s menu to view and select your items, add to your cart, and checkout!",
        },
        {
          question: " How far in advance do I need to place my order?",
          answer:
            "Restaurants set their own required lead time - and based on volume. Most restaurants are able to fulfill orders within 16 hours if the order is between 150 - $500 and 48 hours for orders above $500 - need to order with less time than minimums set by restaurants? Contact Us!",
        },
        {
          question:
            "What is the minimum order requirement for me to place an order?",
          answer:
            "Restaurants set their own minimum order for catering orders, while there are some variations, the majority of restaurants have a $150 minimum order requirement.",
        },
        {
          question: "I placed my order, what next?",
          answer:
            "The order is sent to the restaurant for confirmation and once confirmed (usually 1-2 hours) you will receive an email with your confirmation.",
        },
        {
          question: "Do I get a confirmation and invoice for my order?",
          answer:
            "Yes - you will receive an email with your confirmation and you can access the details and  invoice at any time through the email or your `order history page` if you are logged in with an active account.",
        },
        {
          question: "How will the items be labeled?",
          answer:
            "By default Items will be labeled with item name (e.g. Salad Bowl) and modifications to the item will be present on the label.",
        },
        {
          question:
            "What does the <strong>“Attach a name to this item”</strong> mean?",
          answer:
            "You can change the label on an item as needed, i.e. if you’re ordering something highly customized for a certain person, with a peanut allergy  you can label it with that person’s name.) — If Jerry needs a Gluten Free bowl that is Peanut Free and with no Dairy, then you can select that item and attach the name 'Jerry' to it, so that it is easy for them to find",
        },
        {
          question: "What are the delivery fees and how are they charged?",
          answer:
            "Deliveries fees are based on distance, generally they are around $20 but can increase incrementally depending on distance. You will be able to see delivery fees once you input your address.",
        },
      ],
      "Group Orders": [
        {
          question: "What is a Group Order",
          answer:
            "Select a restaurant, time and day for your next catering requirement - and create a <strong>Shared Cart</strong> - share a link with your teammates and attendees, set a budget for them to us on food - and they will be able to customize their meals and add them to the shared cart.",
        },
        {
          question:
            "When is the deadline for my invitees to place individual orders?",
          answer:
            "The deadline is based on the delivery date and time, and usually 24 hours prior to delivery.",
        },
        {
          question: "What happens when all my invitees place their orders?",
          answer:
            "You will receive an email to review the order and confirm-to-send to the restaurant.",
        },
        {
          question: "How can I see what everyone has ordered?",
          answer:
            "On the <strong>Orders</strong> page on your admin panel, open any active Group Order to see all details.",
        },
        {
          question: "Can I remove an item or cancel an entire order?",
          answer:
            "You can cancel any/all items placed in a Group Order by opening an active order.",
        },
        {
          question: "Can I add items to a Group Order?",
          answer:
            "Yes - as the admin initiating a Group Order, you can open an active order and click on add items, if the deadline has passed, please contact us to see if it’s possible to add any additional orders ",
        },
        {
          question: "How will the items be labeled?",
          answer:
            "Items that have been selected by invitees will be labeled with their name by default, items placed by you without a name attached will be labeled with the item name (e.g. Chicken Salad)",
        },
      ],
      Deliveries: [
        {
          question: "When will my order arrive?",
          answer:
            "Your food should arrive within 15 minutes of the delivery time you specified on checkout.",
        },
        {
          question: "Can I track the status or ETA of my meal?",
          answer:
            "Yes - navigate to the Orders page and you can see the status of your meal as well as the ETA countdown timer showing you when to expect your meal to arrive.",
        },
        {
          question: "Do I receive any text message notifications?",
          answer:
            "If you provide a cell phone on checkout you will receive an SMS with a link to track your delivery in real time.",
        },
        {
          question: "How do I know when my meal has arrived?",
          answer: "You will receive a text message.",
        },
      ],
      "Issues with food quality or accuracy": [
        {
          question: "Part of my order is missing",
          answer:
            "Contact us <strong>immediately</strong> by live chat. We’ll respond to you in seconds, and we’ll do our best to rectify any issues you have.",
        },
        {
          question:
            "My order is damaged, or the restaurant did not prepare as requested:",
          answer:
            "Please take a picture, which we will later forward to the restaurant to raise an incident and contact us <strong>immediately</strong> by live chat. We’ll respond to you in seconds, and we’ll do our best to rectify any issues you have..",
        },
        {
          question:
            "I have a favorite restaurant that is not available on hungerhub, can I recommend/suggest it?",
          answer:
            "Absolutely, send us an email to help@hungerhub.com and let us know.",
        },
      ],
      Uncatering: [
        {
          question: "What is Uncatering™?",
          answer:
            "Uncatering™ was developed by hungerhub as a solution to companies that order frequently and for large groups. Uncatering is a meal program that simplifies frequent ordering, Uncatering accounts are self-managed by you, you create users for your company or team and manage their spend and subsidy. Uncatering works best when you order for the same group and at least once a week.",
        },
        {
          question: "What if I have a question not covered here?",
          answer:
            "Please ask it by live chat or by email to help@hungerhub.com.",
        },
      ],
    },
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  let { faqCategories, faqs, activeCategory } = provider;

  return (
    <>
      <div className="flex justify-center bg-white min-h-[calc(100vh-200px)] mx-auto p-5 sm:p-10 md:p-8 md:mt-0 w-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-col md:flex-row mt-6 w-full">
            <div className="flex flex-col h-fit bg-white border border-divider w-full md:w-[40%] rounded-xl p-6">
              {faqCategories?.map((category, index) => (
                <button
                  key={`category-${index}`}
                  className="flex flex-col my-2"
                  onClick={() =>
                    dispatch({ field: "activeCategory", value: category })
                  }
                >
                  <span
                    className={classNames({
                      "text-md": true,
                      "!text-primary-color": category == activeCategory,
                    })}
                  >
                    {category}
                  </span>
                </button>
              ))}
            </div>

            <div className="flex flex-col bg-white w-full mt-4 md:mt-0 md:w-[60%] md:px-6 md:ml-5">
              {faqs[activeCategory]?.map((faq) => (
                <Disclosure defaultOpen={false}>
                  {({ open }) => (
                    /* Use the `open` state to conditionally change the direction of an icon. */
                    <>
                      <Disclosure.Button className="btn-style">
                        <div className="flex justify-between items-center py-[11px] mb-1">
                          <EmbedHTML
                            text={faq?.question}
                            className="text-md text-left"
                          />

                          <FontAwesomeIcon
                            size="1x"
                            icon={open ? faAngleUp : faAngleDown}
                            className="icon"
                          />
                        </div>
                      </Disclosure.Button>

                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel>
                          <div>
                            <div>
                              <EmbedHTML
                                text={faq?.answer}
                                className={"text-xs text-left font-inter-light"}
                              />
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Faqs);
