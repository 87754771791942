import EmbedHTML from "components/shared/embedHTML";
import { useEffect, useRef } from "react";
import Screen from "utils/helpers/ScreenSize";
import { useIntersection } from "utils/hooks/useIntersection";

const CategoryTitle = ({ category, setSelectedCategory }) => {
  const itemRef = useRef(null);
  const screenType = Screen.ScreenType();
  const inViewport = useIntersection(itemRef, {
    rootMargin: screenType?.isTabletOrMobile ? "-200px" : "-300px",
  });

  useEffect(() => {
    if (inViewport) {
      setSelectedCategory(category.id);
    }
  }, [inViewport]);

  return (
    <span
      ref={itemRef}
      id={`cat-${category?.id}`}
      className="flex px-4 py-3 sm:p-0 text-primary-black sm:mt-4 sm:mb-2 md:mb-0 font-inter-semibold text-xl"
    >
      <EmbedHTML
        text={category?.attributes?.display_name_with_html}
        className={"text-primary-black text-xl"}
      />
    </span>
  );
};

export default CategoryTitle;
