import React, { useState } from "react";
import classNames from "classnames";

const TimePicker = ({
  text,
  placeholder,
  otherMealName,
  mealName,
  isCompulsary,
  flexibleWidth = false,
}) => {
  const [isInputClicked, setIsInputClicked] = useState(false);
  const asteric = <span style={{ color: "red" }}>*</span>;

  const labelText =
    isInputClicked && !mealName
      ? `${placeholder}${asteric.props.children}`
      : `${placeholder}*`;

  const handleInputChange = (event) => {
    if (isCompulsary) setIsInputClicked(true);
    otherMealName(event.target.value);
  };

  return (
    <div
      className={`w-full ${
        !flexibleWidth ? "lg:w-[586px] md:w-[586px]" : ""
      }  h-full px-2 py-4 bg-slate-50 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex`}
    >
      <label className="text-gray-800 text-xl font-inter-semibold leading-normal">
        {text}
      </label>

      <input
        type="text"
        id="otherMeal"
        className={classNames(
          "w-full lg:w-[512px] md:w-[512px] h-[46px] p-4 rounded-xl text-base font-inter-medium leading-7",
          mealName ? "text-slate-700" : "text-zinc-200",
          isCompulsary && isInputClicked && !mealName ? "outline-red-400" : ""
        )}
        placeholder={isCompulsary ? labelText : placeholder}
        value={mealName}
        onChange={handleInputChange}
      />

      {isCompulsary && isInputClicked && !mealName && (
        <div className="px-4 text-red-400 text-xs font-inter-medium leading-[14px]">
          This field needs to be filled
        </div>
      )}
    </div>
  );
};

export default TimePicker;
