import React from "react";
import {
  CateringServices,
  CateringStep1,
  CateringStep2,
  CateringStep3,
  GroupOrderServices,
  GroupOrderStep1,
  GroupOrderStep2,
  GroupOrderStep3,
  UncateringServices,
  UncateringStep1,
  UncateringStep2,
  UncateringStep3,
} from "assets/img";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";

function OurServices() {
  const navigate = useNavigate();

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <div className="lg:hidden md:flex flex-col justify-start items-start gap-[18px] flex mb-[34px]">
        <div className="text-slate-700 text-[32px] font-inter-semibold capitalize leading-[48px]">
          Compare our Services
        </div>

        <div className="text-slate-700 text-base font-inter-normal leading-tight">
          Select the meal that works best for you and your team. You can also
          use multiple services Uncatering for lunch & On Demand for your next
          meeting!
        </div>
      </div>

      <div className="justify-start items-start gap-4 grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1">
        <div className="lg:w-60 md:w-[120px] flex-col justify-start items-start gap-[65px] inline-flex">
          <div className="flex-col justify-start items-start gap-[18px] flex">
            <div className="hidden lg:flex lg:w-[242px] text-slate-700 text-[32px] font-inter-semibold capitalize leading-[48px]">
              Compare our Services
            </div>

            <div className="hidden lg:flex lg:w-[242px] text-slate-700 text-base font-inter-normal leading-tight">
              Select the meal that works best for you and your team. You can
              also use multiple services Uncatering for lunch & On Demand for
              your next meeting!
            </div>
          </div>

          <div className="hidden lg:flex md:flex lg:pt-4 md:pt-72 flex-col justify-start items-start gap-[76px] flex">
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by
            </div>
          </div>
        </div>

        <div className="lg:w-60 md:w-[151.31px] w-full lg:px-10 md:px-2 pb-8 bg-slate-50 rounded-xl flex-col justify-start items-center gap-[65px] inline-flex">
          <div className="lg:w-[239px] md:w-[151.31px] w-full lg:h-56 md:h-64 p-3 bg-indigo-500 rounded-xl flex-col justify-start items-center gap-3 inline-flex">
            <img className="w-[72px] h-[72px]" src={UncateringServices} />
            <div className="text-center text-white text-xl font-inter-semibold leading-normal">
              Uncatering
            </div>
            <div className="text-center text-white text-base font-inter-medium leading-tight">
              Individuals ordering on a set schedule{" "}
            </div>
          </div>

          <div className="lg:w-[190px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for:
            </div>
            <span className="ml-2">
              20+ employees ordering on a recurring basis
            </span>
          </div>

          <div className="text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend:
            </div>
            <span className="ml-2">$350 per meal</span>
          </div>

          <div className="lg:w-[182px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants:
            </div>
            <span className="ml-2">
              Multiple restaurants to choose from per meal{" "}
            </span>
          </div>

          <div className="lg:w-[182px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order:
            </div>
            <span className="ml-2">
              2 hours <br />
              e.g. 10:30am for lunch
            </span>
          </div>

          <div className="lg:w-[227px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by:
            </div>
            <span className="ml-2">Customized and placed by individuals</span>
          </div>

          <div className="text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by:
            </div>
            <span className="ml-2">hungerhub team</span>
          </div>
        </div>

        <div className="lg:w-60 md:w-[151.31px] w-full lg:px-10 md:px-2 pb-8 bg-slate-50 rounded-xl flex-col justify-start items-center lg:gap-[75px] md:gap-[78px] gap-[68px] inline-flex">
          <div className="lg:w-[239px] md:w-[151.31px] w-full lg:h-56 md:h-64 p-3 bg-indigo-500 rounded-xl flex-col justify-start items-center gap-3 inline-flex">
            <img className="w-[72px] h-[72px]" src={GroupOrderServices} />
            <div className="text-center text-white text-xl font-inter-semibold leading-normal">
              Group ordering
            </div>
            <div className="text-center text-white text-base font-inter-medium leading-tight">
              Group orders indivilually customized{" "}
            </div>
          </div>

          <div className="lg:w-[230px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for:
            </div>
            <span className="ml-2">
              Events, meetings or with external invitees
            </span>
          </div>

          <div className="text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend:
            </div>
            <span className="ml-2">$150 per meal</span>
          </div>

          <div className="lg:w-[201px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants:
            </div>
            <span className="ml-2">One restaurant, selected by you</span>
          </div>

          <div className="text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order:
            </div>
            <span className="ml-2">16 hours</span>
          </div>

          <div className="lg:w-[227px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by:
            </div>
            <span className="ml-2">Customized and placed by individuals</span>
          </div>

          <div className="text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by:
            </div>
            <span className="ml-2">hungerhub team</span>
          </div>
        </div>

        <div className="lg:w-60 md:w-[151.31px] w-full lg:px-10 md:px-2 pb-8 bg-slate-50 rounded-xl flex-col justify-start items-center lg:gap-[75px] md:gap-[78px] gap-[68px] inline-flex">
          <div className="lg:w-[239px] md:w-[151.31px] w-full lg:h-56 md:h-64 p-3 bg-indigo-500 rounded-xl flex-col justify-start items-center gap-3 inline-flex">
            <img className="w-[72px] h-[72px]" src={CateringServices} />
            <div className="text-center text-white text-xl font-inter-semibold leading-normal">
              On Demand Catering
            </div>
            <div className="text-center text-white text-base font-inter-medium leading-tight">
              Catering ordered placed by one on behalf of many
            </div>
          </div>

          <div className="lg:w-[230px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for:
            </div>
            <span className="ml-2">
              Large scale events or family style catering
            </span>
          </div>

          <div className="text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend:
            </div>
            <span className="ml-2">$150 per meal</span>
          </div>

          <div className="lg:w-[201px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants:
            </div>
            <span className="ml-2">Selected by one individual (you)</span>
          </div>

          <div className="text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order:
            </div>
            <span className="ml-2">16 hours</span>
          </div>

          <div className="lg:w-[201px] text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by:
            </div>
            <span className="ml-2">
              Selected by one (you) on behalf of group
            </span>
          </div>

          <div className="text-center text-slate-700 text-base font-inter-semibold leading-tight lg:px-0 md:px-0 px-2">
            <div className="lg:hidden md:hidden sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by:
            </div>
            <span className="ml-2">hungerhub team</span>
          </div>
        </div>
      </div>

      {/* For Uncatering  */}
      <div className="flex-col justify-start items-start flex mt-32">
        <div className="flex-col justify-start items-start flex">
          <div className="text-neutral-700 text-[32px] font-inter-semibold capitalize leading-[48px] mb-[12px]">
            Uncatering
          </div>

          <div className="lg:w-2/3 md:w-full text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            Empower your employees with the best possible way to order lunches
            as part of a meal plan solution for your office.
          </div>

          <button
            className="btn-blue-accent rounded-[32px] h-10 w-[204px] px-6 py-2.5"
            // onClick={() => navigate("/get-started-uncatering")}
          >
            Enable meal plan
          </button>
        </div>

        <div className="flex-col justify-start items-start flex mt-[48px]">
          <div className="text-gray-800 text-2xl font-inter-semibold leading-loose mb-[12px]">
            How does it work?
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            How the meal plan of the future works...
          </div>

          <div className="justify-start items-start gap-[23px] grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={UncateringStep1}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step1
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                <p className="font-inter-medium">
                  Create a meal plan for your workplace and add your team
                  member.
                </p>{" "}
                Set a daily, weekly, or meeting/event specific budgets.
              </div>
            </div>

            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={UncateringStep2}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step2
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Team members individually choose and customize meals <br /> From
                a rotation of restaurants, there’s something for everyone.From
                diets such as Gluten-free or Vegan to taste preferences like "No
                onions", "Extra cheese" and "dressing on the side".
              </div>
            </div>

            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={UncateringStep3}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step3
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Meals delivered, each in a sealed package labelled with a name
                hungerhub’s own team members deliver meals in easily
                identifiable packaging, meaning less headache for you and peace
                of mind for the rest of the team.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For GroupOrder  */}
      <div className="flex-col justify-start items-start flex mt-32">
        <div className="flex-col justify-start items-start flex">
          <div className="text-neutral-700 text-[32px] font-inter-semibold capitalize leading-[48px] mb-[12px]">
            Group ordering
          </div>

          <div className="lg:w-2/3 md:w-full text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            Perfect for meetings and events... Group Orders combine the peace of
            mind of a catered meal with the flexibility allowing invitees to
            select their meals
          </div>

          <button
            className="btn-blue-accent rounded-[32px] h-10 w-[204px] px-6 py-2.5"
            // onClick={() => navigate("/get-started-group")}
          >
            Start ordering
          </button>
        </div>

        <div className="flex-col justify-start items-start flex mt-[48px]">
          <div className="text-gray-800 text-2xl font-inter-semibold leading-loose mb-[12px]">
            How does it work?
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            You will have full visibility of all orders within a group order and
            can edit it along the way{" "}
          </div>

          <div className="justify-start items-start gap-[23px] grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={GroupOrderStep1}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step1
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                The restaurant of your choice - set a date, time, and per person
                budget
              </div>
            </div>

            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={GroupOrderStep2}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step2
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Share a unique link with your invitees that allows them to
                select and customize their items
              </div>
            </div>

            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={GroupOrderStep3}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step3
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Everything delivered together, labelled with names.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For Catering  */}
      <div className="flex-col justify-start items-start flex mt-32">
        <div className="flex-col justify-start items-start flex">
          <div className="text-neutral-700 text-[32px] font-inter-semibold capitalize leading-[48px] mb-[12px]">
            On Demand Catering
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            Need catering for an event or a meeting and want to order for the
            whole group?
          </div>

          <button
            className="btn-blue-accent rounded-[32px] h-10 w-[204px] px-6 py-2.5"
            // onClick={() => navigate("/get-started-catering")}
          >
            Start ordering
          </button>
        </div>

        <div className="flex-col justify-start items-start flex mt-[48px]">
          <div className="text-gray-800 text-2xl font-inter-semibold leading-loose mb-[12px]">
            How does it work?
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            You will have full visibility of all orders within a group order and
            can edit it along the way{" "}
          </div>

          <div className="justify-start items-start gap-[23px] grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={CateringStep1}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step1
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Set your date and time and browse through available restaurants
              </div>
            </div>

            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={CateringStep2}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step2
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Select the quantity and items you need
              </div>
            </div>

            <div className="lg:w-72 md:w-52 px-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={CateringStep3}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step3
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Everything delivered together, family style or individual meals,
                it’s up to you
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(OurServices);
