import {
  Halal,
  Kosher,
  GlutenFree,
  Vegan,
  Vegetarian,
  DairyFree,
  ContainsNuts,
  NutFree,
  Spicy,
  Reusable,
  Recycleable,
  IndPackage,
  Exclusive,
  BoxedMeal,
} from "assets/img";

import { toTitleCase } from "./string";

const getBadgeIcon = (badge) => {
  switch (getParentTag(toTitleCase(badge))) {
    case "Halal":
      return Halal;
    case "Kosher":
      return Kosher;
    case "Gluten Free":
      return GlutenFree;
    case "Vegetarian":
      return Vegetarian;
    case "Vegan":
      return Vegan;
    case "Dairy Free":
      return DairyFree;
    case "Spicy":
      return Spicy;
    case "Nut Free":
      return NutFree;
    case "Contains Nuts":
      return ContainsNuts;
    case "Reusable":
      return Reusable;
    case "Recycleable":
      return Recycleable;
    case "IndPackage":
      return IndPackage;
    case "Exclusive":
      return Exclusive;
    case "Boxed Meal":
      return BoxedMeal;

    default:
      return null;
  }
};

const getParentTag = (badge) => {
  switch (badge) {
    case "Gluten Free":
    case "Gluten Free Friendly":
    case "Gluten Free Kitchen":
      return "Gluten Free";

    case "Vegan":
    case "Vegan Friendly":
    case "100% Vegan":
    case "100% Plant Based":
    case "Plant Based":
      return "Vegan";

    case "Vegetarian":
    case "Vegetarian Friendly":
    case "Vegan":
    case "Vegan Friendly":
    case "100% Vegan":
    case "100% Plant Based":
    case "Plant Based":
      return "Vegetarian";

    case "hungerhub exclusive menu":
    case "hungerhub exclusive catering":
    case "Exclusive":
      return "Exclusive";

    case "Halal":
      return "Halal";

    case "Nut free":
    case "Peanut free kitchen":
    case "Nut free kitchen":
    case "nut free friendly":
      return "Nut Free";

    case "Eco-friendly Packaging":
    case "Biodegradable Packaging":
    case "Sustainable Packaging":
    case "Reusable Containers":
      return "Recycleable";

    case "Reusable Packaging":
      return "Reusable";

    case "Boxed Meal":
      return "Boxed Meal";

    case "Individually Packaged":
      return "IndPackage";

    default:
      return badge;
  }
};

export default {
  getBadgeIcon,
};
