import { faAdd, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartItem from "components/Cart/CartItem";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import {
  FileInputIcon,
  GreyCalendarIcon,
  GreyClockIcon,
  MasterCardIcon,
  RadioActive,
  RadioInActive,
  VisaCardIcon,
} from "assets/img";
import {
  priceFormatter,
  validateEmail,
  preventMinus,
  preventPasteNegative,
} from "utils/helpers/validation";
import Tooltip from "components/Tooltip";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import { groupBy, isPresent } from "utils/helpers/array";
import EmbedHTML from "components/shared/embedHTML";
import TipButton from "components/TipButton";
import { TIP_VALUES_DESKTOP, TIP_VALUES_MOBILE } from "utils/constants/common";
import ConfirmationBadge from "components/ConfirmationBadge";
import { getByPassLocationCheckText } from "utils/helpers/string";
import { AddCard } from "alerts";
import { useNavigate, useLocation, useParams } from "react-router";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const notificationMethods = [
  { id: "no", title: "No, only use these instructions for this order" },
  {
    id: "yes",
    title: "Yes, change for this and all future orders with this address",
  },
];

const CheckoutPage = () => {
  const initialState = {
    comment: "",
    firstName: "",
    lastName: "",
    billAs: "company",
    company: "",
    phone: "",
    email: "",
    tip: "15",
    otherTip: "",
    customTip: 0,
    isInvalidAddress: false,
    phoneError: "",
    taxAmount: 0,
    internalAddress: null,
    deliveryFee: 0,
    deliveryInstructionsLevel: "no",
    temporaryCard: null,
    showTemporaryCard: false,
    temporaryCardSelected: false,
    paidByInvoice: false,
    defaultCard: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [addNewCard, setAddNewCard] = useState(false);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
  const [navigationResponse, setNavigationResponse] = useState(null);

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [addressValidationAlert, setAddressValidationAlert] = useState(null);
  const [bypassPlaceOrder, setBypassPlaceOrder] = useState(null);
  const [copiedAlert, showCopiedAlert] = useState(false);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  let { selectedAddress, currentUser, defaultLocation } = userStore ?? {};
  let { unCheckedOutOrder, cart } = cartStore ?? {};
  const paymentInfoStore = toJS(store?.paymentInfoStore);
  let {
    stripeCreditCards,
    defaultPaymentMethodId,
    defaultSelectedCard,
    cardElement,
    newCard,
  } = paymentInfoStore;
  const deliveryInfoStore = toJS(store?.deliveryInfoStore);
  const { completeAddress } = deliveryInfoStore;
  let invoiceExist = defaultLocation?.invoicePayments == "enabled";
  let accountPhone = defaultLocation?.accountPhone;

  let {
    tip,
    customTip,
    otherTip,
    firstName,
    lastName,
    phone,
    email,
    company,
    comment,
    billAs,
    taxAmount,
    deliveryFee,
    deliveryInstructionsLevel,
    temporaryCard,
    showTemporaryCard,
    temporaryCardSelected,
    paidByInvoice,
    defaultCard,
  } = provider;

  let tipAmount,
    grandTotalAmount = 0;

  let navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });

    store.paymentInfoStore.getLocationCreditCards({
      location_id: defaultLocation.organizationId,
    });
  }, [defaultLocation.organizationId]);

  useEffect(() => {
    if (unCheckedOutOrder?.id)
      store.cartStore?.getCartItems(unCheckedOutOrder?.id);
  }, [unCheckedOutOrder?.id]);

  useEffect(() => {
    if (cart?.firstName && cart?.lastName && cart?.phone && cart?.email) {
      dispatch({ field: "firstName", value: cart?.firstName });
      dispatch({ field: "lastName", value: cart?.lastName });
      dispatch({ field: "phone", value: cart?.phone });
      dispatch({ field: "email", value: cart?.email });
    }
  }, [cart?.firstName, cart?.lastName, cart?.phone, cart?.email]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isSharedLink = slug && params.get("shared");

    if (isSharedLink) {
      store.cartStore?.getCartItems(slug, true);
    }
  }, [location.search, slug]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isSharedLink = slug && params.get("shared");

    if (isSharedLink) {
      dispatch({
        field: "tip",
        value: cart?.tipPercentage == 0 ? "Other" : cart?.tipPercentage,
      });

      dispatch({
        field: "customTip",
        value: cart?.tipPercentage == 0 ? cart?.tipAmount : 0,
      });

      dispatch({
        field: "otherTip",
        value: cart?.tipPercentage == 0 ? cart?.tipAmount : 0,
      });
    }
  }, [location.search, cart?.tipPercentage, cart?.tipAmount]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("shared")) {
      dispatch({
        field: "internalAddress",
        value: {
          suite: cart?.suite,
          streetNumber: cart?.streetNumber,
          streetName: cart?.streetName,
          city: cart?.city,
          province: cart?.province,
          postalCode: cart?.postalCode,
        },
      });
    }
  }, [
    location.search,
    cart?.suite,
    cart?.streetNumber,
    cart?.streetName,
    cart?.city,
    cart?.province,
    cart?.postalCode,
  ]);

  useEffect(() => {
    if (currentUser) {
      dispatch({
        field: "firstName",
        value: currentUser?.attributes?.first_name,
      });
      dispatch({
        field: "lastName",
        value: currentUser?.attributes?.last_name,
      });
      dispatch({ field: "phone", value: currentUser?.attributes?.phone });
      dispatch({ field: "email", value: currentUser?.attributes?.email });
    }
  }, [currentUser !== null]);

  useEffect(() => {
    if (cart?.comment) dispatch({ field: "comment", value: cart?.comment });
  }, [cart?.comment]);

  useEffect(() => {
    dispatch({ field: "defaultCard", value: defaultPaymentMethodId });
  }, [defaultPaymentMethodId]);

  useEffect(() => {
    if (defaultCard) setPaymentMethodAdded(true);
  }, [defaultCard]);

  const getAddress = () => {
    return `${
      completeAddress?.attributes.suite
        ? `${completeAddress?.attributes?.suite} - `
        : ""
    }${completeAddress?.attributes?.streetNumber ?? ""} ${
      completeAddress?.attributes?.streetName ?? ""
    } ${completeAddress?.attributes?.city ?? ""}, ${
      completeAddress?.attributes?.province ?? ""
    }, ${completeAddress?.attributes?.postalCode ?? ""}`;
  };

  useEffect(() => {
    let addressPayload = selectedAddress
      ? selectedAddress?.label
      : getAddress();

    if (
      addressPayload?.replace(/[ ,]/g, "") &&
      cart?.restaurant?.attributes?.restaurant_id
    ) {
      store.userStore?.setLoader(true);
      store.cartStore
        ?.getDeliveryFee(cart?.restaurant?.attributes?.restaurant_id, {
          order_amount: cart?.data?.attributes?.grand_total,
          order_subtotal: cart?.data?.attributes?.subtotal,
          order_address: addressPayload,
          order_id: unCheckedOutOrder?.id,
        })
        .then((response) => {
          if (!response?.valid) {
            dispatch({
              field: "isInvalidAddress",
              value: true,
            });
            if (
              response?.message == "Address out of range." ||
              response?.message == "Address not found."
            )
              setAddressValidationAlert(true);
            else
              store.userStore?.setErrorAlert(true, {
                title: response?.message,
              });
          } else {
            dispatch({
              field: "isInvalidAddress",
              value: false,
            });

            dispatch({
              field: "taxAmount",
              value: response?.tax_amount,
            });

            dispatch({
              field: "deliveryFee",
              value: response?.message || 0,
            });

            dispatch({
              field: "deliveryFee",
              value: response?.message || 0,
            });
          }

          store.userStore?.setLoader(false);
        });
    }
  }, [cart?.data?.attributes?.subtotal]);

  tipAmount = useMemo(() => {
    if (tip !== "Other") return (tip / 100) * cart?.data?.attributes?.subtotal;
    else return customTip || 0;
  }, [cart?.data?.attributes?.subtotal, tip, customTip]);

  grandTotalAmount = useMemo(() => {
    return parseFloat(
      parseFloat(cart?.data?.attributes?.subtotal) +
        parseFloat(taxAmount) +
        parseFloat(tipAmount) +
        parseFloat(cart?.data?.attributes?.service_fee) +
        parseFloat(deliveryFee)
    ).toFixed(2);
  }, [cart?.data?.attributes?.subtotal, deliveryFee, taxAmount, tipAmount]);

  const createCheckOutSession = async (shipmentId, employeeId) => {
    const stripe = await stripePromise;

    const tip = parseFloat(tipAmount);
    const cutlery = cart?.cutlery;

    const orderPayload = {
      uncheckedout_order_id: unCheckedOutOrder?.id,
      shipment_id: shipmentId,
      employee_id: employeeId,
      tip: tip,
      cutlery: cutlery,
      user_agent: window.navigator.userAgent,
    };

    if (deliveryInstructionsLevel == "no") {
      orderPayload.permanent_instructions = comment;
    }

    if (newCard && !paidByInvoice) {
      store.paymentInfoStore
        .createLocationPaymentIntent({
          location_id: defaultLocation?.organizationId,
          uncheckedout_order_id: unCheckedOutOrder?.id,
        })
        .then((response) => {
          store.userStore?.setLoader(false);
          const intentId = response?.id;
          const client_secret = response?.client_secret;

          const { error, paymentIntent } = stripe
            .confirmCardPayment(client_secret, {
              payment_method: {
                card: cardElement,
              },
            })
            .then(function (result) {
              if (result.error) {
                console.error("Error:", result.error.message);
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  orderPayload.stripe_payment_intent_id = intentId;

                  store.userStore
                    ?.createUserOrder(orderPayload)
                    .then((response) => {
                      const slug = response?.slug;

                      if (slug) {
                        store.cartStore?.resetCart();

                        setNavigationResponse(response);
                      } else {
                        store.userStore?.setErrorAlert(true, {
                          title: "Something went wrong",
                        });
                      }
                    })
                    .catch(() => store.userStore?.setLoader(false));
                }
              }
            });
        })
        .catch(() => store.userStore.setLoader(false));
    } else if (paidByInvoice && !newCard) {
      orderPayload.paid_by_invoice = paidByInvoice;

      store.userStore
        ?.createUserOrder(orderPayload)
        .then((response) => {
          const slug = response?.slug;

          if (slug) {
            store.cartStore?.resetCart();
            setNavigationResponse(response);
          } else {
            store.userStore?.setErrorAlert(true, {
              title: "Something went wrong",
            });
          }
        })
        .catch(() => store.userStore?.setLoader(false));
    } else {
      store.paymentInfoStore
        .createLocationPaymentIntent({
          location_id: defaultLocation.organizationId,
          uncheckedout_order_id: unCheckedOutOrder?.id,
          employee_id: employeeId,
          shipment_id: shipmentId,
          tip: tip,
          cutlery: cutlery,
          card_id: defaultCard,
          customer_id: defaultSelectedCard?.customer_id,
        })
        .then((response) => {
          store.userStore?.setLoader(false);

          const intentId = response?.id;
          const client_secret = response?.client_secret;
          const payment_method_id = response?.payment_method;

          stripe
            .confirmCardPayment(client_secret, {
              payment_method: payment_method_id,
            })
            .then(function (result) {
              if (result.error) {
                console.error("Error:", result.error.message);
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  orderPayload.stripe_payment_intent_id = intentId;

                  store.userStore
                    ?.createUserOrder(orderPayload)
                    .then((response) => {
                      const slug = response?.slug;

                      if (slug) {
                        store.cartStore?.resetCart();

                        setNavigationResponse(response);
                      } else {
                        store.userStore?.setErrorAlert(true, {
                          title: "Something went wrong",
                        });
                      }
                    })
                    .catch(() => store.userStore?.setLoader(false));
                }
              }
            });
        })

        .catch(() => store.userStore.setLoader(false));
    }
  };

  const isAbleToProceedToPayment = () => {
    const isValidPhone = phone && isValidPhoneNumber(provider.phone);
    const isValidEmail = email && validateEmail(email);
    const userInfoAvailable =
      firstName && lastName && isValidPhone && isValidEmail;
    return userInfoAvailable;
  };

  const updateUncheckedOutOrder = (copyLinkToClipboard) => {
    if (copyLinkToClipboard) {
      const currentURL = new URL(window.location.href);

      currentURL.searchParams.append("shared", "true");

      const updatedURL = currentURL.href;

      navigator.clipboard.writeText(updatedURL);

      showCopiedAlert(true);
      setTimeout(() => showCopiedAlert(false), 2000);
    } else {
      store.userStore.setLoader(true);
    }

    if (unCheckedOutOrder?.id) {
      const unCheckedOutOrderPayload = {
        ...unCheckedOutOrder,
        suite: completeAddress.suite,
        street_name: completeAddress.street_name,
        street_number: completeAddress.street_number,
        city: completeAddress.city,
        province: completeAddress.province,
        postal_code: completeAddress.postal_code,
        billable_as: "individual",
        company_name: "",
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        status: "order_summary",
        menu_id: cart?.restaurant?.attributes?.menu_id,
        restaurant_id: cart?.restaurant?.attributes?.restaurant_id,
        order_date: moment(cart?.cartDate).format("DD-MM-YYYY"),
        order_time: cart?.cartTime,
        cutlery_required: cart?.cutlery,
        delivery_instructions: comment,
        tip_in_percentage: tip,
        tip_in_fixed_amount: tip !== "Other" ? tipAmount : customTip,
      };

      if (currentUser) unCheckedOutOrderPayload.user_id = currentUser.id;

      return store?.cartStore
        ?.updateUncheckedOutOrder(
          unCheckedOutOrder?.id,
          unCheckedOutOrderPayload
        )
        .then((response) => {
          return response;
        })
        .catch(() => {
          store.userStore.setLoader(false);
        });
    }
  };

  const proceedToPayment = async (e) => {
    e.preventDefault();

    const selectedDateTime = moment(
      `${moment(cart?.cartDate).format("YYYY-MM-DD")} ${cart?.cartTime}`,
      "YYYY-MM-DD h:mm a"
    );

    const result = await updateUncheckedOutOrder();

    if (isPresent(result?.response?.data?.errors)) {
      store?.userStore?.setErrorAlert(true, {
        title:
          "Uncheckedout order not found. Please continue with a new order.",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!selectedDateTime.isAfter(moment())) {
      store?.userStore?.setErrorAlert(true, {
        title:
          "You have selected a past date/time, please recheck the date to continue",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!paymentMethodAdded) {
      store?.userStore?.setErrorAlert(true, {
        title: "Payment method is required",
      });
      store.userStore?.setLoader(false);
      return;
    }

    store.cartStore?.saveCartDetails({
      firstName,
      lastName,
      email,
      phone,
      company,
      billAs,
      comment,
      tipAmount,
      grandTotal: grandTotalAmount,
    });

    sendProceedPaymentRequest();
  };

  const sendProceedPaymentRequest = (bypassDifferentAddress = false) => {
    let employeePayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone ? phone : "",
      delivery_date: moment(cart?.cartDate).format("YYYY-MM-DD"),
      target_time: cart?.cartTime,
      menu_id: cart?.restaurant?.attributes?.menu_id,
      source_of_business: "hungerhub",
      billable_as: "individual",
      company_name: "",
      uncheckedout_order_id: unCheckedOutOrder?.id,
      address_id: selectedAddress?.value,
      location_id: defaultLocation?.organizationId,
      contact_number: accountPhone,
    };

    if (bypassDifferentAddress) {
      employeePayload.override_location_check = true;
    }

    if (deliveryInstructionsLevel == "yes") {
      employeePayload.delivery_instructions = comment;
    }

    store.userStore?.setLoader(true);
    store.cartStore
      ?.createEmployee(employeePayload)
      .then((response) => {
        if (response?.employee_id && response?.shipment_id) {
          store.cartStore?.saveCartDetails({
            employeeId: response?.employee_id,
            shipmentId: response?.shipment_id,
          });

          createCheckOutSession(response?.shipment_id, response?.employee_id);
        } else if (
          !response?.success &&
          response?.address_fields &&
          !bypassDifferentAddress
        ) {
          store.userStore?.setLoader(false);
          const address = response?.address_fields;
          setBypassPlaceOrder(getByPassLocationCheckText(address));
        } else store.userStore?.setLoader(false);
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const getTotalQuantity = (items) => {
    let quantity = 0;
    items.forEach((item) => {
      quantity += item?.attributes?.quantity;
    });

    return quantity;
  };

  const computeOrder = () => {
    let order = "";

    cart?.items?.forEach((item, index) => {
      order += `${index >= 1 ? "+" : ""} ${index + 1}.Item Name= ${
        item?.attributes?.menu_item?.attributes?.title
      } Quantity=${item?.attributes?.quantity},`;

      if (item?.attributes?.order_item_options.length > 0) {
        order += `options=`;
        item?.attributes?.order_item_options?.forEach(
          (option) =>
            (order += `${option?.attributes?.option_group_display_name}: ${option?.attributes?.option_display_name},`)
        );
      }

      order += `instructions= ${item?.attributes?.special_instructions},`;
    });

    return order;
  };

  const handleDefaultStripeCard = (paymentID) => {
    store.paymentInfoStore.updateDefaultLocationCreditCards(paymentID, {
      location_id: defaultLocation?.organizationId,
      default: true,
    });
  };

  const handleTemporaryCard = (card) => {
    dispatch({ field: "temporaryCard", value: card });
    dispatch({ field: "showTemporaryCard", value: true });
    dispatch({ field: "temporaryCardSelected", value: true });
    setPaymentMethodAdded(true);
  };

  const isCardExpired = (expMonth, expYear) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    if (expYear < currentYear) {
      return true;
    }

    if (expYear === currentYear && expMonth < currentMonth) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (navigationResponse?.slug) {
      navigate(`/order-confirmation/${navigationResponse?.slug}`);
    }
  }, [navigationResponse]);

  return (
    <>
      <div
        className="flex justify-center w-full border-border-gray bg-light-gray min-h-screen mx-auto"
        // style={{ maxWidth: "1440px" }}
      >
        {cart?.items?.length > 0 ? (
          <div
            className="flex flex-col w-full mx-auto my-5 px-5"
            style={{ maxWidth: "1200px" }}
          >
            <div className="flex items-center">
              <span className="font-inter-medium text-[24px] md:text-[32px] mr-3">
                Order Details
              </span>

              <FontAwesomeIcon
                onClick={() => updateUncheckedOutOrder(true)}
                icon={faShareSquare}
                size="1x"
                className="icon cursor-pointer"
              />
            </div>

            <div className="flex flex-col md:flex-row items-start mt-7">
              <div className="flex flex-col p-5 bg-white rounded-3xl w-full md:w-3/5 md:mr-8 static md:sticky md:top-0">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-1">
                  <div className="flex flex-col">
                    <span className="font-inter-medium text-md2 text-black-light">
                      {cart?.restaurant?.attributes?.name}
                    </span>

                    <span className="text-md text-secondary-text">
                      Total items:{" "}
                      {cart?.items?.reduce((acc, item) => {
                        return acc + item?.attributes?.quantity;
                      }, 0)}
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      store.userStore.setLoader(true);
                      navigate(-1);
                    }}
                    className="flex mt-3 sm:mt-0 btn-style items-center justify-center sm:justify-start text-sm border-hh-accent-dark border p-2 px-3 rounded-lg text-hh-accent-dark"
                  >
                    <span className="text-sm ml-2">Edit order</span>
                  </button>
                </div>

                <div className="border border-zinc-200 h-10 p-2 rounded-[20px] mt-4 items-center flex w-full">
                  <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer w-full">
                    <span className="text-[#2d3958] ml-1 font-inter-medium text-sm w-44">
                      Request cutlery
                    </span>

                    <input
                      autoComplete="off"
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) =>
                        store.cartStore.updateCutlery(e.target.checked)
                      }
                      checked={cart?.cutlery}
                    />
                  </label>
                </div>

                <div className="mt-4">
                  {Object.values(
                    groupBy(
                      cart?.items,
                      (item) => item?.attributes?.menu_category_id
                    )
                  )?.map((entry, index) => (
                    <div key={`${index}-cartItem`}>
                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                        <span className="text-md font-inter-medium text-primary-black">
                          <EmbedHTML
                            text={
                              entry[0]?.attributes
                                ?.category_display_name_with_html ||
                              entry[0]?.attributes?.category
                            }
                            className="text-primary-black"
                          />
                        </span>

                        <span className="text-sm">
                          Total Qty: {getTotalQuantity(entry)}
                        </span>
                      </div>

                      {entry?.map((cartItem) => (
                        <div className="px-3">
                          <CartItem
                            checkout
                            cartItem={cartItem}
                            key={cartItem.id}
                            moreThanOneItemInCategory={entry.length > 1}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              <form onSubmit={proceedToPayment} autoComplete="off">
                <div className="flex flex-col p-5 bg-white rounded-3xl">
                  <span className="text-[20px] font-inter-medium text-[#2d3958]">
                    Delivery Details
                  </span>

                  <div className="flex flex-col w-full mt-4">
                    <span className="text-sm mb-1 text-[#2d3958]">
                      Delivery address
                    </span>
                    <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex font-inter-medium">
                      {selectedAddress?.label}
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row w-full justify-between mt-5">
                    <div className="flex flex-col w-full sm:w-1/2">
                      <span className="text-sm mb-1 text-[#2d3958]">
                        Delivery date
                      </span>

                      <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2 font-inter-medium">
                        <img src={GreyCalendarIcon} alt="calendar-icon" />
                        {moment(cart?.cartDate).format("MMMM Do, YYYY")}
                      </div>
                    </div>

                    <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
                      <span className="text-sm mb-1 text-[#2d3958]">Time</span>

                      <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2 font-inter-medium">
                        <img src={GreyClockIcon} alt="clock-icon" />
                        {cart?.cartTime}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mt-5">
                    <span className="text-sm mb-1 text-[#2d3958]">
                      Delivery instructions (optional)
                    </span>

                    <input
                      className="input-gray-bg text-sm bg-white border border-zinc text-[#2d3958] font-inter-medium"
                      placeholder="Ex. Leave at door"
                      value={provider.comment}
                      onChange={(e) =>
                        dispatch({ field: "comment", value: e.target.value })
                      }
                    />
                  </div>

                  {provider.comment && (
                    <fieldset>
                      <p className="text-md font-inter-semibold mt-[16px] text-[#2d3958]">
                        Should we apply these instructions to all deliveries
                        with this address?
                      </p>

                      <div className="mt-[12px] space-y-2">
                        {notificationMethods?.map((notificationMethod) => (
                          <div
                            key={notificationMethod.id}
                            className="flex items-center"
                          >
                            <div
                              className="flex cursor-pointer"
                              onClick={(e) => {
                                dispatch({
                                  field: "deliveryInstructionsLevel",
                                  value: notificationMethod.id,
                                });
                              }}
                            >
                              <img
                                alt="radio-button"
                                src={
                                  deliveryInstructionsLevel ==
                                  notificationMethod.id
                                    ? RadioActive
                                    : RadioInActive
                                }
                              />
                              <div className="d-row items-center ml-3">
                                {notificationMethod.title}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  )}
                </div>

                <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl gap-3">
                  <span className="text-[20px] font-inter-medium text-[#2d3958]">
                    Billing Info
                  </span>

                  <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                    {provider?.firstName}
                  </div>

                  <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                    {provider?.lastName}
                  </div>

                  <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                    {provider.phone ? provider.phone : accountPhone}
                  </div>

                  <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                    {provider?.email}
                  </div>
                </div>

                <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl gap-3 w-full">
                  <span className="text-[20px] font-inter-semibold">
                    Payment method
                  </span>

                  <div className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer w-full pr-2">
                    <span className="font-inter-semibold text-lg w-44">
                      Credit card
                    </span>

                    {stripeCreditCards?.payment_methods?.data?.length > 0 && (
                      <button
                        type="button"
                        className={classNames(
                          "btn-light btn-style py-3 px-5 border-indigo-900 text-indigo-900 rounded-md"
                        )}
                        onClick={() => {
                          setAddNewCard(true);
                          dispatch({ field: "temporaryCard", value: null });
                          dispatch({
                            field: "showTemporaryCard",
                            value: false,
                          });
                          dispatch({
                            field: "temporaryCardSelected",
                            value: false,
                          });
                        }}
                      >
                        Add a new card
                      </button>
                    )}
                  </div>

                  {stripeCreditCards?.payment_methods?.data?.length < 1 && (
                    <button
                      className="btn-purple w-full mt-4 py-5 h-4 justify-center items-center flex font-inter-semibold"
                      onClick={() => {
                        setAddNewCard(true);
                        dispatch({ field: "temporaryCard", value: null });
                        dispatch({ field: "showTemporaryCard", value: false });
                        dispatch({
                          field: "temporaryCardSelected",
                          value: false,
                        });
                      }}
                    >
                      Add a credit card
                    </button>
                  )}

                  {stripeCreditCards?.payment_methods?.data
                    ?.filter((data) => {
                      const expMonth = data?.card?.exp_month;
                      const expYear = data?.card?.exp_year;
                      return !isCardExpired(expMonth, expYear);
                    })
                    ?.map((data, index) => (
                      <div
                        className="bg-white border border-zinc-200 h-16 rounded-lg mt-[12px] px-[12px] py-[16px] d-row justify-between"
                        key={index}
                      >
                        <div key={"photo"} className="flex items-center">
                          <div
                            className="flex cursor-pointer"
                            onClick={() => {
                              handleDefaultStripeCard(data?.id);
                              dispatch({
                                field: "paidByInvoice",
                                value: false,
                              });
                              dispatch({
                                field: "defaultCard",
                                value: data?.id,
                              });
                              dispatch({
                                field: "temporaryCardSelected",
                                value: false,
                              });
                              setPaymentMethodAdded(true);
                            }}
                          >
                            <img
                              alt="radio-button"
                              src={
                                data?.id == defaultCard &&
                                !paidByInvoice &&
                                !temporaryCardSelected
                                  ? RadioActive
                                  : RadioInActive
                              }
                            />
                            <div className="d-row items-center ml-3">
                              <img
                                className="relative w-[40px] h-[40px]"
                                alt="Frame"
                                src={
                                  data?.card?.display_brand == "visa"
                                    ? VisaCardIcon
                                    : MasterCardIcon
                                }
                              />

                              <div className="d-col ml-3">
                                <small className="text-sm text-slate-700 font-inter-medium">
                                  **** {data?.card?.last4}
                                </small>

                                <small className="text-sm text-slate-500 font-inter-normal">
                                  Expires{" "}
                                  {`${
                                    data?.card?.exp_month
                                  }/${data?.card?.exp_year
                                    .toString()
                                    .slice(-2)}`}
                                </small>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    ))}

                  {showTemporaryCard && (
                    <div className="bg-white border border-zinc-200 h-16 rounded-lg mt-[12px] px-[12px] py-[16px] d-row justify-between">
                      <div key={"photo"} className="flex items-center">
                        <div
                          className="flex cursor-pointer"
                          onClick={() => {
                            dispatch({
                              field: "paidByInvoice",
                              value: false,
                            });

                            dispatch({
                              field: "temporaryCardSelected",
                              value: true,
                            });
                            setPaymentMethodAdded(true);
                          }}
                        >
                          <img
                            alt="radio-button"
                            src={
                              temporaryCard && temporaryCardSelected
                                ? RadioActive
                                : RadioInActive
                            }
                          />
                          <div className="d-row items-center ml-3">
                            <img
                              className="relative w-[40px] h-[40px]"
                              alt="Frame"
                              src={
                                temporaryCard?.display_brand == "visa"
                                  ? VisaCardIcon
                                  : MasterCardIcon
                              }
                            />

                            <div className="d-col ml-3">
                              <small className="text-sm text-slate-700 font-inter-medium">
                                **** {temporaryCard?.last4}
                              </small>

                              <small className="text-sm text-slate-500 font-inter-normal">
                                Expires{" "}
                                {`${
                                  temporaryCard?.exp_month
                                }/${temporaryCard?.exp_year
                                  .toString()
                                  .slice(-2)}`}
                              </small>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  )}

                  {invoiceExist && (
                    <>
                      <div className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer w-full pr-2">
                        <span className="font-inter-semibold text-lg w-44">
                          Pay with invoice
                        </span>
                      </div>

                      <div className="bg-white border border-zinc-200 rounded-lg px-[12px] py-[16px] d-row justify-between">
                        <div key={"photo"} className="flex items-center">
                          <div
                            className="flex cursor-pointer"
                            onClick={() => {
                              dispatch({ field: "paidByInvoice", value: true });
                              dispatch({
                                field: "temporaryCardSelected",
                                value: false,
                              });

                              store.paymentInfoStore.setIsNewCard(false);
                              setPaymentMethodAdded(true);
                            }}
                          >
                            <img
                              alt="radio-button"
                              src={paidByInvoice ? RadioActive : RadioInActive}
                            />
                            <div className="d-row items-center ml-3">
                              <img
                                className="relative w-[40px] h-[40px]"
                                alt="Frame"
                                src={FileInputIcon}
                              />

                              <div className="d-col ml-3">
                                <small className="text-sm text-slate-700 font-inter-medium">
                                  Invoice details
                                </small>

                                <small className="text-sm text-slate-500 font-inter-normal">
                                  {defaultLocation?.accountBillingName} <br />
                                  {defaultLocation?.accountBillingAddress}{" "}
                                  <br />
                                  {defaultLocation?.accountBillingEmails?.map(
                                    (email, index) => {
                                      return <p>{email}</p>;
                                    }
                                  )}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl">
                  <span className="text-[20px] font-inter-medium text-[#2d3958]">
                    Summary
                  </span>

                  <div className="mt-5">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-[#2d3958] text-base font-inter-normal">
                        Items
                      </span>
                      <span className="text-base text-[#2d3958] font-inter-medium">
                        {priceFormatter(cart?.data?.attributes?.subtotal)}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <div className="flex items-center">
                        <span className="text-[#2d3958] text-base font-inter-normal">
                          Delivery
                        </span>

                        <Tooltip
                          grayIcon
                          tooltipText={
                            "Your delivery cost is dependent on restaurant and proximity to your delivery address"
                          }
                          id="delivery-tooltip"
                          backgroundColor={"white"}
                          textColor="#2d3958"
                          place={"bottom"}
                        />
                      </div>

                      <span className="text-base text-[#2d3958] font-inter-medium">
                        {priceFormatter(deliveryFee)}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <div className="flex items-center">
                        <span className="text-[#2d3958] text-base font-inter-normal">
                          Service Fee
                        </span>

                        <Tooltip
                          grayIcon
                          tooltipText={
                            "A service fee of seven percent of food total charges is inclusive of all hungerhub fees"
                          }
                          id="service-tooltip"
                          backgroundColor={"white"}
                          textColor="#2d3958"
                          place={"bottom"}
                        />
                      </div>

                      <span className="text-base text-[#2d3958] font-inter-medium">
                        {priceFormatter(cart?.data?.attributes?.service_fee)}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <span className="text-[#2d3958] text-base font-inter-normal">
                        Tax
                      </span>

                      <span className="text-base text-[#2d3958] font-inter-medium">
                        {priceFormatter(taxAmount)}
                      </span>
                    </div>

                    <div className="border-t border-b border-border-gray my-4 py-5">
                      <div className="flex w-full items-center justify-between">
                        <span className="text-[#2d3958] text-base leading-tight font-inter-semibold">
                          Tip
                        </span>

                        <span className="text-base text-[#2d3958] font-inter-medium">
                          {priceFormatter(tipAmount)}
                        </span>
                      </div>

                      <div className="hidden sm:flex w-full flex-wrap items-center justify-between">
                        {TIP_VALUES_DESKTOP?.map((tipValue) => (
                          <TipButton
                            key={`${tipValue}-tip`}
                            onClick={() =>
                              dispatch({
                                field: "tip",
                                value: tipValue,
                              })
                            }
                            tipValue={tipValue}
                            tip={provider?.tip}
                          />
                        ))}
                      </div>

                      <div className="flex sm:hidden w-full flex-wrap items-center justify-between">
                        {TIP_VALUES_MOBILE?.map((tipValue) => (
                          <TipButton
                            key={`${tipValue}-tip-mob`}
                            onClick={() =>
                              dispatch({
                                field: "tip",
                                value: tipValue,
                              })
                            }
                            tipValue={tipValue}
                            tip={provider?.tip}
                          />
                        ))}
                      </div>

                      {provider.tip == "Other" && (
                        <div className="flex mt-5 space-x-5">
                          <div className="flex items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12 border border-border-gray">
                            <span className="mr-1">$</span>

                            <input
                              className="text-sm bg-background h-[46px] w-full focus:outline-none"
                              value={otherTip}
                              type="number"
                              min="0"
                              step=".01"
                              onKeyDown={preventMinus}
                              onPaste={preventPasteNegative}
                              onChange={(e) =>
                                dispatch({
                                  field: "otherTip",
                                  value:
                                    e.target.value !== ""
                                      ? Math.abs(e.target.value)
                                      : e.target.value,
                                })
                              }
                            />
                          </div>

                          <button
                            type="button"
                            onClick={() =>
                              dispatch({
                                field: "customTip",
                                value: otherTip,
                              })
                            }
                            className="btn-purple w-28 text-sm"
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="flex w-full items-center justify-between mt-5 mb-16 md:mb-0">
                      <span className="text-[#2d3958] text-xl font-inter-semibold">
                        Total
                      </span>

                      <span className="text-xl text-[#2d3958] font-inter-semibold">
                        {priceFormatter(grandTotalAmount)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex mt-5 md:mt-0 bg-white md:bg-transparent p-5 md:static w-full">
                  <button
                    className="btn-purple w-full mt-4 py-5 h-[80px]"
                    type="submit"
                  >
                    <span
                      className={`text-sm font-inter-medium ${
                        isAbleToProceedToPayment()
                          ? "text-white"
                          : "text-secondary-gray"
                      }`}
                    >
                      Place order
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            <div className="text-md p-5 btn-style justify-center items-center flex">
              <a href="/">
                You cannot checkout before adding items to your cart, click here
                to go to home.
              </a>
            </div>
          </>
        )}

        {bypassPlaceOrder && (
          <ConfirmationModal
            avoidClose
            visible={bypassPlaceOrder}
            setModal={setBypassPlaceOrder}
            body={bypassPlaceOrder}
            rightButtonText="Place Order"
            onClickRightButton={() => {
              sendProceedPaymentRequest(true);
            }}
          />
        )}
        {copiedAlert && <ConfirmationBadge text={"Copied to clipboard!"} />}

        <AddCard
          avoidClose
          visible={addNewCard}
          setModal={setAddNewCard}
          handleTemporaryCard={handleTemporaryCard}
          handlePaymentMethod={() => {
            dispatch({
              field: "paidByInvoice",
              value: false,
            });
            setPaymentMethodAdded(true);
          }}
          setPaymentMethodAdded={setPaymentMethodAdded}
          isCheckout={true}
        />
      </div>
    </>
  );
};

CheckoutPage.checkoutLayout = true;

export default observer(CheckoutPage);
