import pluralize from "pluralize";
import dateTime from "./dateTime";
import { toTitleCase } from "./string";

export const getOperatingHours = (menu) => {
  let hours = "Opening hours:<br/>";

  menu?.attributes?.operating_hours?.forEach((hour) => {
    hours = hours.concat(
      `${toTitleCase(hour?.attributes?.day?.substring(0, 3))}
      ${
        hour?.attributes?.active
          ? `${dateTime.formatTimeWithFormat(
              hour?.attributes?.from,
              "h:mma",
              menu?.attributes?.market?.attributes?.tzinfo
            )} - ${dateTime.formatTimeWithFormat(
              hour?.attributes?.to,
              "h:mma",
              menu?.attributes?.market?.attributes?.tzinfo
            )}`
          : `Closed`
      }<br/>`
    );
  });

  return hours;
};

export const getPreferences = (menu) => {
  let preferences = "Deadline to order:<br/>";

  menu?.attributes?.minimum_order_preferences?.forEach((pref) => {
    preferences = preferences.concat(
      `$${pref.minimum_amount} =
      ${`${pref.required_notice_time} ${pluralize(
        "hour",
        pref.required_notice_time
      )} in advance`}<br/>`
    );
  });

  return preferences;
};
export const isSoldOut = (item) => {
  return (
    item?.attributes?.availability === "in_active" ||
    item?.attributes?.availability === "sold_out"
  );
};

export const allMenuItemBadges = () => {
  return [
    "HALAL",
    "KOSHER",
    "GLUTEN FREE",
    "VEGETARIAN",
    "VEGAN",
    "DAIRY FREE",
    "SPICY",
    "NUT FREE",
    "CONTAINS NUTS",
    "REUSABLE PACKAGING",
    "BIODEGRADABLE PACKAGING",
    "EXCLUSIVE",
  ];
};

export const returnUnmatchedCount = (cart, restaurant) => {
  if (cart?.items?.length && restaurant) {
    let notSameRestautantCount = 0;
    cart.items.forEach((item) => {
      const restaurantInCart = item?.attributes?.restaurant_name;

      if (restaurantInCart?.toLowerCase() !== restaurant?.toLowerCase())
        notSameRestautantCount += 1;
    });

    return notSameRestautantCount;
  }
  return 0;
};

export const debounce = (f, ms) => {
  let timer = null;

  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
};

export const changeHyphensToSpaces = (string) => {
  return string?.replace(/-/g, " ");
};

export const changeSpacesToHyphens = (string) => {
  return string?.trim()?.replace(/\s/g, "-");
};

export const getETA = (order) => {
  return `${dateTime.localWithUTCoffset(
    order?.attributes?.dropoff_task_eta,
    order?.attributes?.tzinfo,
    "hh:mm a"
  )} ${dateTime.checkAndGetTimezone(order.attributes.tzinfo, true)}`;
};

export const isCateringOrder = (type) => {
  return type == "order";
};

export const getETAString = (order) => {
  if (order?.attributes?.dropoff_task_eta) {
    return `ETA ${getETA(order)}`;
  }
};
