import React, { useContext, useEffect, useState } from "react";
import { SearchBar } from "components";
import ConfirmationModal from "components/ConfirmationModal";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import {
  ChangeSubsidy,
  AssignTeam,
  AddUsersToMeal,
  TeamRemove,
  BulkUserRemove,
} from "alerts";
import { useManagePlan } from "contexts/ManagePlanContext";
import { MobXProviderContext } from "mobx-react";
import { isPresent } from "utils/helpers/array";
import { toJS } from "mobx";
import { priceFormatter } from "utils/helpers/validation";
import userStore from "mobXstore/userStore";

function ManageUsers() {
  const {
    changeSubsidy,
    setChangeSubsidy,
    removeUser,
    setRemoveUser,
    assignTeam,
    setAssignTeam,
    addUsersToMeal,
    setAddUsersToMeal,
    confirmTeamRemove,
    setConfirmTeamRemove,
  } = useManagePlan();

  const { mealManagementStore } = useContext(MobXProviderContext);

  const [bulkUsers, setBulkUsers] = useState([]);
  const [subsidySelect, setSubsidySelect] = useState(null);

  const mealPlans = mealManagementStore.mealPlans;
  const mealPlan = mealManagementStore.mealPlan;
  const teams = mealManagementStore.teams;
  const locationEmployees = mealManagementStore.locationEmployees;
  const [locEmployees, setLocationEmployees] = useState(
    mealPlan?.attributes?.location_employees
  );
  const locationID = userStore.locationID;

  useEffect(() => {
    setBulkUsers([]);

    if (subsidySelect?.value === "weekly") {
      let locEmployeesCopy = mealPlan?.attributes?.location_employees;
      locEmployeesCopy = locEmployeesCopy?.filter(
        (user) => user?.attributes?.subsidy_type === "weekly"
      );
      setLocationEmployees(locEmployeesCopy);
    } else if (subsidySelect?.value === "daily") {
      let locEmployeesCopy = mealPlan?.attributes?.location_employees;
      locEmployeesCopy = locEmployeesCopy?.filter(
        (user) => user?.attributes?.subsidy_type === "daily"
      );
      setLocationEmployees(locEmployeesCopy);
    } else if (!subsidySelect) {
      setLocationEmployees(mealPlan?.attributes?.location_employees);
    }
  }, [subsidySelect, mealPlan]);

  useEffect(() => {
    mealManagementStore.fetchTeams(locationID);
    mealManagementStore.fetchEmployees(locationID);
  }, []);

  const onEmployeeChecked = (e, person) => {
    if (e.target.checked) {
      let employeeMealPlans = mealPlan?.attributes?.employee_meal_plans;
      let found = employeeMealPlans?.find(
        (employee) =>
          Number(employee.location_employee_id) === Number(person?.id)
      );

      let isPresent = bulkUsers?.find(
        (user) => Number(user?.employeeId) === Number(person?.id)
      );

      if (isPresent) {
        let filtered = bulkUsers?.filter(
          (user) => Number(user?.employeeId) !== Number(person?.id)
        );
        setBulkUsers(filtered);
      } else {
        setBulkUsers([...bulkUsers, { id: found?.id, employeeId: person.id }]);
      }
    } else {
      let filtered = bulkUsers?.filter(
        (user) => Number(user?.employeeId) !== Number(person?.id)
      );

      setBulkUsers(filtered);
    }
  };

  return (
    <div className="d-col p-6 w-full relative">
      <div className="mb-4">
        <div className="d-row mt-[24px] items-center justify-between">
          <strong className="text-dark-gray text-[24px] font-inter-medium">
            Teams in {mealPlan?.attributes?.name}
          </strong>
          <button
            className="btn-blue-accent"
            onClick={() => {
              setAssignTeam(true);
            }}
          >
            Assign teams
          </button>
        </div>

        <p className="font-inter-regular max-w-[543px] text-[14px] text-dark-gray">
          Users added or removed from the following teams will be automatically
          added or removed from this meal series
        </p>

        <div className="flex items-center space-x-2 my-[24px]">
          {mealPlan?.attributes?.teams?.map((team) => (
            <div className="bg-light-gray px-4 py-[12px] rounded-full flex items-center">
              <span className="text-sm text-gray-700">{team?.name}</span>
              <button
                onClick={() => {
                  setConfirmTeamRemove(team?.id);
                }}
                className="ml-2 text-gray-700 text-xs"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      </div>

      <strong className="text-dark-gray text-[24px] font-inter-medium">
        Users in this meal series
      </strong>

      <span className="font-inter-regular max-w-[543px] text-[14px] text-dark-gray">
        Users number: {mealPlan?.attributes?.location_employees?.length}
      </span>

      <div className="d-row items-center justify-between my-[24px]">
        <div className="d-row items-center">
          <SearchBar placeholderValue="Search" />
          <small className="fonte-inter-regular tex-sm mx-[10px]">
            Filter by:
          </small>

          <Select
            styles={customStylesSubsidy}
            aria-labelledby="sort-by"
            aria-label="sort-by"
            placeholder="Subsidy type"
            closeMenuOnSelect
            isClearable
            onChange={(option) => setSubsidySelect(option)}
            options={[
              { label: "Weekly", value: "weekly" },
              { label: "Daily", value: "daily" },
            ]}
            className="w-[195px]"
          />
        </div>

        <button
          className="btn-blue-accent"
          onClick={() => {
            setAddUsersToMeal(true);
          }}
        >
          Add users to this meal
        </button>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-light-gray">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  >
                    First name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Last name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Team
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Subsidy
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Subsidy Value
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {locEmployees?.map((person) => (
                  <tr key={person?.id} className="even:bg-light-gray">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox text-blue-600 h-4 w-4"
                          onChange={(e) => {
                            onEmployeeChecked(e, person);
                          }}
                        />
                      </label>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                      {person?.attributes?.first_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      {person?.attributes?.last_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      {person?.attributes?.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                      {person?.attributes?.team?.name ?? "-"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                      {person?.attributes?.subsidy_type ?? "-"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      {person?.attributes?.subsidy_type === "weekly" ? (
                        person?.attributes?.subsidy
                      ) : (
                        <div className="grid grid-cols-3 gap-0">
                          {person.attributes?.daily_subsidy &&
                            Object.keys(person?.attributes?.daily_subsidy)?.map(
                              (day) => (
                                <div className="capitalize d-col items-center">
                                  <small className="font-inter-medium text-dark-gray text-[9px]">
                                    {day.slice(0, 3)}
                                  </small>
                                  <small className="font-inter-medium bg-white px-[7px] py-[3px] rounded-xl text-dark-gray text-[9px]">
                                    {priceFormatter(
                                      person.attributes?.daily_subsidy[day]
                                    )}
                                  </small>
                                </div>
                              )
                            )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isPresent(bulkUsers) && (
        <div class="absolute bottom-0 right-0 d-row items-center justify-center left-0 ">
          <div className="btn-blue-accent rounded-3xl px-[32px] py-[16px] d-row items-center hover:bg-catering h-[48px] ">
            <small className="font-inter-medium text-[16px]">
              {bulkUsers?.length} user selected
            </small>
            <button
              className="font-inter-medium text-white px-[12px] underline text-[16px] py-0"
              onClick={() => setChangeSubsidy(true)}
            >
              Update subsidy
            </button>
            <button
              className="font-inter-medium text-white underline text-[16px] py-0"
              onClick={() => setRemoveUser(true)}
            >
              Remove user from this meal
            </button>
          </div>
        </div>
      )}

      {changeSubsidy && (
        <ChangeSubsidy
          visible={changeSubsidy}
          setModal={setChangeSubsidy}
          mealPlanId={mealPlan?.id}
          bulkUsers={bulkUsers}
          bulkUserUpdate={true}
        />
      )}

      {assignTeam && (
        <AssignTeam
          visible={assignTeam}
          setModal={setAssignTeam}
          teams={teams}
          mealPlanId={mealPlan?.id}
        />
      )}

      {addUsersToMeal && (
        <AddUsersToMeal
          visible={addUsersToMeal}
          setModal={setAddUsersToMeal}
          users={locationEmployees}
          mealPlanId={mealPlan?.id}
        />
      )}

      {confirmTeamRemove && (
        <TeamRemove
          visible={confirmTeamRemove}
          setModal={setConfirmTeamRemove}
          mealPlanId={mealPlan?.id}
        />
      )}

      {removeUser && (
        <BulkUserRemove
          visible={removeUser}
          setModal={setRemoveUser}
          mealPlanId={mealPlan?.id}
          bulkUsers={bulkUsers}
        />
      )}
    </div>
  );
}

export default ManageUsers;
