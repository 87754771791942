import { makeAutoObservable, observable, action, toJS } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import deliveryInfoStore from "./deliveryInfoStore";

class OnboardingStore {
  delivery_address = "";
  unit_suite = "";
  selectedMealArray = {};
  selectedCuisines = {};
  isNotSureSelected = false;
  selectedCuisinesOnFe = [];
  error = false;
  errorMessage = "";
  isAlreadyRegistered = false;
  default_date = "";
  meal_allowance_type = "";
  meal_allowance_value = "";
  enable_meal_delivery = "";
  recieving_days = [];
  recieving_dates = [];
  isSuccessStep = false;
  cuisineTypes = [];
  dietaryPreferences = [];
  expectedBag = "";
  name = "";
  targetTime = "";
  updateExpectedBagsAutomatically = "";
  onboardingCuisines = {};
  additionalNotes = "";
  preferences = "";
  startDate = "";
  days = [];
  datesToBeAdded = [];
  defaultSubsidy = "";
  defaultSubsidyType = "";
  defaultDailySubsidy = {};
  selectedAddress = "";
  newAddress = {};
  isNewAddress = false;
  mealPlan = {};
  onboarding_cuisines = {};
  onboarding_dietary_preferences;

  constructor() {
    makeAutoObservable(this, {
      expectedBag: observable,
      user: observable,
      location: observable,
      newAddress: observable,
      handleExpectedBagData: action,
      handleMealSeriesData: action,
      handleMealSeriesAndSubsidiesData: action,
      handlePreferencesData: action,
      handleUserDetailsData: action,
    });
  }

  async fetchCuisineTypesData(params) {
    let paramString = qs.stringify(params);

    try {
      const response = await client().get(
        `${api.adminPanelDietaryPreference()}?${paramString}`
      );

      this.cuisineTypes = response?.data?.cuisines;
      this.dietaryPreferences = response?.data?.dietary_preferences;
    } catch (error) {
      throw error;
    }
  }

  calculateExpectedBags(value) {
    if (value == "<20") {
      return 20;
    } else if (value == "20-39") {
      return 20;
    } else if (value == "40-59") {
      return 40;
    } else if (value == "60-79") {
      return 60;
    } else if (value == "80-89") {
      return 80;
    } else {
      return 100;
    }
  }

  formatTime(date) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  calculateNextMondayDate() {
    const today = new Date();
    const daysUntilMonday = 1 + ((1 - today.getDay() + 6) % 7);
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilMonday);

    nextMonday.setHours(0, 0, 0, 0);

    const formattedDate = `${nextMonday.getDate()}-${
      nextMonday.getMonth() + 1
    }-${nextMonday.getFullYear()}`;

    return formattedDate;
  }

  handleExpectedBagData(expectedBags) {
    this.expectedBag = this.calculateExpectedBags(expectedBags);
  }

  handleMealSeriesData(selectedMeals) {
    this.selectedMealArray = selectedMeals;

    const [hoursMinutes, amPm] = (selectedMeals?.target_time ?? "").split(" ");
    const [hours, minutes] = (hoursMinutes ?? "").split(":");

    const targetTime = new Date();
    targetTime?.setHours(
      parseInt(hours, 10) +
        ((amPm?.toLowerCase() === "pm" && hours !== "12") ||
        (hours === "12" && amPm?.toLowerCase() === "am")
          ? 12
          : 0)
    );
    targetTime?.setMinutes(parseInt(minutes, 10));

    const cutoffTime = new Date(targetTime?.getTime() - 2 * 60 * 60 * 1000);
    let cutoffTimeString = this.formatTime(cutoffTime);

    const [cutOffHour, cutOffAmPm] = cutoffTimeString?.split(":");

    if (cutOffHour == "00") {
      let cutOffHours = "12";
      cutoffTimeString = cutOffHours + ":" + cutOffAmPm;
    }

    this.name = selectedMeals.meal_name;
    this.targetTime = selectedMeals.target_time;
    this.cutoff = cutoffTimeString;
    this.updateExpectedBagsAutomatically = true;
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  handleMealSeriesAndSubsidiesData(
    days,
    start_date,
    dates_to_add,
    default_subsidy_type,
    default_subsidy,
    meal_delivery_option
  ) {
    this.isNotSureSelected = start_date == "I am not sure yet" ? true : false;
    this.default_date = start_date ? this.formatDate(start_date) : "";
    this.meal_allowance_type = default_subsidy_type;
    this.meal_allowance_value = default_subsidy;
    this.enable_meal_delivery = meal_delivery_option;
    this.recieving_days = days;

    const currentDate = new Date();
    const currentDateString = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;

    const filteredArray = dates_to_add?.map((dateObj) => {
      if (dateObj) {
        for (const key in dateObj) {
          const dates = dateObj[key];
          if (Array.isArray(dates) && dates.includes(currentDateString)) {
            dateObj[key] = dates.filter((date) => date !== currentDateString);
          }
        }
      }
      return dateObj;
    });

    this.startDate =
      start_date === "I am not sure yet"
        ? this.calculateNextMondayDate()
        : start_date?.toString();
    this.days = days;
    this.datesToBeAdded = dates_to_add?.flatMap((month) => {
      if (month) {
        const keys = Object.keys(month);
        if (keys.length > 0) {
          return month[keys[0]];
        }
      }
      return [];
    });
    this.defaultSubsidy = default_subsidy_type == "daily" ? 0 : default_subsidy;
    this.defaultSubsidyType = default_subsidy_type;
    this.defaultDailySubsidy =
      default_subsidy_type == "daily"
        ? {
            monday: default_subsidy,
            tuesday: default_subsidy,
            wednesday: default_subsidy,
            thursday: default_subsidy,
            friday: default_subsidy,
            saturday: default_subsidy,
            sunday: default_subsidy,
          }
        : {
            monday: "0",
            tuesday: "0",
            wednesday: "0",
            thursday: "0",
            friday: "0",
            saturday: "0",
            sunday: "0",
          };

    this.recieving_dates = filteredArray;
  }

  handlePreferencesData(
    onboarding_cuisines,
    onboarding_dietary_preferences,
    additional_notes,
    formatted_cuisines
  ) {
    this.selectedCuisines = onboarding_cuisines;
    this.additionalNotes = additional_notes;
    this.onboardingCuisines = formatted_cuisines;
    this.preferences = onboarding_dietary_preferences.join(", ");
  }

  setSelectedCuisineOnFe(selectedCuisinesOnFe) {
    this.selectedCuisinesOnFe = selectedCuisinesOnFe;
  }

  setAddress(address) {
    this.selectedAddress = address;
    this.isNewAddress = false;
  }

  setNewAddress(address) {
    this.newAddress = address;
    this.isNewAddress = true;
  }

  async createMealPlan(params, addressId) {
    let paramString = qs.stringify(params);
    let id = this.isNewAddress ? addressId : this.selectedAddress.value;
    let locationAddresses = toJS(deliveryInfoStore.locationAddresses);

    const address = locationAddresses?.find((address) => address.id == id);

    const payload = {
      meal_series: {
        expected_bags: this.expectedBag,
        name: this.name,
        target_time: this.targetTime,
        cutoff: this.cutoff,
        start_date: this.startDate,
        service: "uncatering",
        days: this.days,
        dates_to_add: this.datesToBeAdded,
        subsidy: this.defaultSubsidy,
        subsidy_type: this.defaultSubsidyType,
        daily_subsidy: this.defaultDailySubsidy,
        update_expected_bags_automatically: true,
        delivery_contact_name: address?.attributes.delivery_contact_name,
        delivery_contact_email: address?.attributes.delivery_contact_email,
        delivery_contact_phone: address?.attributes.delivery_contact_phone,
        delivery_instructions: address?.attributes.delivery_instructions,
        meal_plan_preference_attributes: {
          cuisines: this.onboardingCuisines,
          dietary_preferences: this.preferences,
          additional_notes: this.additionalNotes,
        },
        address_id: id,
      },
    };

    try {
      const response = await client().post(
        `${api.mealPlans()}?${paramString}`,
        payload
      );

      let data = response?.data?.data?.attributes;

      this.mealPlan = {
        meal_name: data?.name,
        address: data?.address,
        startDate: data?.start_date,
        deliveryTime: data?.target_time,
        underReview: data?.under_review,
      };
    } catch (error) {
      throw error;
    }
  }
}

const onboardingStore = new OnboardingStore();
export default onboardingStore;
