import React, { useState, useContext, useEffect } from "react";
import { DatePicker } from "components";
import { NotFound } from "components";
import { MobXProviderContext } from "mobx-react";
import dayjs from "dayjs";
import dateTime from "utils/helpers/dateTime";
import { priceFormatter } from "utils/helpers/validation";
import { validateEmail } from "utils/helpers/validation";
import classNames from "classnames";
import ReactPaginate from "react-paginate";
import { toJS } from "mobx";

function UncateringInvoices() {
  const [startDate, setStartDate] = useState();
  const [email, setEmail] = useState();
  const [endDate, setEndDate] = useState();
  const { userStore } = useContext(MobXProviderContext);
  const { locationID, invoices, invoicesPagy } = userStore;
  const isValidEmail = email && validateEmail(email);

  useEffect(() => {
    if (locationID) {
      let params = { location_id: locationID, page: 1, per_page: 8 };
      userStore.getUncateringInvoices(params);
    }
  }, [locationID]);

  const invoiceStatus = (status) => {
    switch (status) {
      case "sent_failed":
        return "Sent Failed";
      case "open":
        return "Open";
      case "sent":
        return "Sent";
      case "paid":
        return "Paid";
      case "overdue":
        return "Overdue";
      case "closed":
        return "Closed";
      default:
        return "";
    }
  };

  const exportPDF = (invoice) => {
    userStore.exportInvoicePDF({
      invoiceId: invoice?.id,
      invoiceNumber: invoice?.attributes?.invoice_number,
      locationId: locationID,
    });
  };

  const exportSalesReport = (invoice) => {
    userStore.exportInvoiceXLS({
      invoiceId: invoice?.id,
      invoiceNumber: invoice?.attributes?.invoice_number,
      locationId: locationID,
      startDate: dateTime.onlyDate(invoice?.attributes?.start_date),
      endDate: dateTime.onlyDate(invoice?.attributes?.end_date),
    });
  };

  const generateCustomReport = (e) => {
    e.preventDefault();

    if (isValidEmail && startDate && endDate) {
      userStore
        .exportInvoiceXLSwithEmail({
          locationId: locationID,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          email: email,
        })
        .then(() => setEmail(""));
    }
  };

  const pageClick = (page) => {
    let params = {
      location_id: locationID,
      page: page.selected + 1,
      per_page: 8,
    };
    userStore.getUncateringInvoices(params);
  };

  useEffect(() => {
    setEndDate("");
  }, [startDate]);

  return (
    <div class="flex-col w-full justify-start items-start py-[24px] px-[32px]">
      <div class="flex-col justify-start items-start mt-[53px] w-full">
        <h1 class="font-inter-semibold text-[32px] text-dark-gray">
          Uncatering Invoices
        </h1>
        <h2 className="font-inter-semibold text-[20px] text-dark-gray mt-[24px]">
          Generate custom period sales report of your Uncatering orders
        </h2>

        <div class="d-row justify-between mt-[24px] w-full">
          <div className="d-row w-4/5">
            <DatePicker
              selectedDate={startDate}
              setSelectedDate={setStartDate}
              placeholderText="Select start date"
              format="MMMM d, yyyy"
              className="flex h-12 bg-light-gray justify-between rounded-lg mr-[20px] pr-2 items-center"
              pickerClassName="font-inter-medium"
              isCart
            />

            <DatePicker
              selectedDate={endDate}
              setSelectedDate={setEndDate}
              minDate={new Date(startDate)}
              format="MMMM d, yyyy"
              placeholderText="Select end date"
              className="flex h-12 bg-light-gray justify-between rounded-lg mr-[20px] pr-2 items-center"
              pickerClassName="font-inter-medium"
              isCart
            />

            <input
              className="input-gray-bg text-sm mr-[20px]"
              type="email"
              onChange={(e) => {
                setEmail(e?.target?.value);
              }}
              placeholder="type in your email"
              value={email}
            />
          </div>

          <button
            onClick={generateCustomReport}
            className={classNames({
              "btn-light-gray text-[16px] w-1/5 text-white bg-catering": true,
              "opacity-50": !isValidEmail,
            })}
            disabled={!isValidEmail}
          >
            Send
          </button>
        </div>

        <h3 className="font-inter-semibold text-[20px] text-dark-gray mt-[24px]">
          Past Uncatering™ Invoices
        </h3>

        <div class="mt-[24px] flex-col items-start gap-6 flex">
          <div className="mt-8 w-full flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr className="bg-light-gray">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                      >
                        Invoice date
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                      >
                        Invoice #
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Period start
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Period end
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Invoice Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Due date
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      ></th>
                    </tr>
                  </thead>

                  <tbody className="bg-white">
                    {invoices?.map((invoice) => (
                      <tr key={invoice?.id} className="even:bg-light-gray">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                          {dateTime.onlyDate(invoice?.attributes?.invoice_date)}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                          {invoice?.attributes?.invoice_number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {dateTime.onlyDate(invoice?.attributes?.start_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {dateTime.onlyDate(invoice?.attributes?.end_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {priceFormatter(invoice?.attributes?.invoice_total) ??
                            "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {dateTime.onlyDate(invoice?.attributes?.due_date) ??
                            "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {invoiceStatus(invoice?.attributes?.status) ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <button
                            onClick={() => {
                              exportSalesReport(invoice);
                            }}
                            class="btn-light-gray w-auto mr-2 pt-0 px-[8px] justify-center items-center text-heading text-[16px]"
                          >
                            Sales report
                          </button>

                          <button
                            onClick={() => {
                              exportPDF(invoice);
                            }}
                            class="btn-light-gray w-auto px-[8px] justify-center items-center text-heading text-[16px]"
                          >
                            Invoice
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <NotFound data={invoices} message="No invoices found" />

                {invoicesPagy && invoicesPagy?.count !== 0 && (
                  <div className="w-full flex items-center justify-center mt-12 text-primary-color">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={invoicesPagy?.pages}
                      onPageChange={pageClick}
                      forcePage={
                        invoicesPagy?.page - 1 > 0 ? invoicesPagy?.page - 1 : 0
                      }
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      pageClassName={"page_number"}
                      activeClassName={"active_page"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UncateringInvoices;
