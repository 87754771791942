import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChangeDeliveryTime,
  ChangeSubsidy,
  ChangeContact,
  ChangeDeliveryInstructions,
} from "alerts";
import { useManagePlan } from "contexts/ManagePlanContext";

function RequestChangesToMeal() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.state?.referrer || -1;

  const {
    managePlan,
    setManagePlan,
    changeDeliveryTime,
    setChangeDeliveryTime,
    changeExpectedOrders,
    setChangeExpectedOrders,
    changeSubsidy,
    setChangeSubsidy,
    setChangeContact,
    changeContact,
    changeDeliveryInstructions,
    setChangeDeliveryInstructions,
  } = useManagePlan();

  return (
    <div className="d-col py-[34px] w-full px-[58px]">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={() => {
          navigate(path);
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-black-light ml-2">
          Back
        </span>
      </button>

      <div className="d-col items-center w-[665px] self-center">
        <span className="font-inter-medium text-dark-gray text-[14px]">
          Delivery Address: 1448 Lawrence Avenue East
        </span>

        <h2 className="font-inter-semibold text-dark-gray text-[24px] mt-[5px]">
          Changes to hungerhub staff happy hour
        </h2>

        <div className="mt-[33px] bg-light-gray px-[10px] py-[13px] rounded-lg">
          <p className="font-inter-medium text-dark-gray text-[14px]">
            All the changes made will only be attached to this specific order.
            For meal plan change go to meal management. Changes can be only made
            12 h prior your scheduled meal delivery. If you want to make changes
            to meal series,
            <button
              onClick={() => navigate("/meal-management?manage-plan=true")}
              className="text-catering ml-1"
            >
              click here
            </button>
          </p>
        </div>

        <>
          <span className="text-dark-gray self-start mt-[24px] text-[18px] font-inter-semibold">
            Choose your date
          </span>
          {/* SEE DESIGN HERE SOMETHING MISSING */}
          <div className="p-[16px] bg-light-gray w-full d-row justify-between items-center mt-[24px]">
            <div className="d-col">
              <small>Delivery time:</small>
              <strong>10:00 am</strong>
            </div>

            <button
              onClick={() => {
                setChangeDeliveryTime(true);
              }}
              className="text-catering font-inter-medium text-sm"
            >
              Request change
            </button>
          </div>

          <div className="p-[16px] bg-light-gray w-full d-row justify-between items-center mt-[24px]">
            <div className="d-col">
              <small>Subsidy:</small>
              <strong>$13.00</strong>
            </div>

            <button
              onClick={() => {
                setChangeSubsidy(true);
              }}
              className="text-catering font-inter-medium text-sm"
            >
              Request change
            </button>
          </div>

          <span className="text-dark-gray self-start mt-[24px] text-[18px] font-inter-semibold">
            Teams & Users
          </span>

          <div className="p-[16px] bg-light-gray w-full d-row justify-between items-center mt-[24px]">
            <div className="d-col">
              <small>Teams assigned to this meal</small>
              <strong>Team 1</strong>
              <strong>Team 1</strong>
            </div>

            <small className="text-dark-gray self-start font-inter-regular text-sm">
              225 users in total
            </small>
          </div>

          <div className="p-[16px] bg-light-gray w-full d-row justify-center items-center mt-[24px]">
            <button
              className="text-catering font-inter-medium text-sm"
              onClick={() => {
                navigate("/home/manage-teams");
              }}
            >
              Manage teams&users
            </button>
          </div>

          <span className="text-dark-gray self-start mt-[24px] text-[18px] font-inter-semibold">
            Point of contact
          </span>

          <button
            onClick={() => {
              setChangeContact(true);
            }}
            className="text-catering self-start font-inter-medium text-sm"
          >
            Edit
          </button>

          <label className="inter-regular text-[14px] self-start text-dark-gray mt-[16px]">
            Name*
          </label>

          <input
            required
            className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
            //   value={getURL()}
            //   disabled={true}
          />

          <label className="inter-regular text-[14px] self-start text-dark-gray mt-[24px]">
            Phone number*
          </label>

          <input
            required
            className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
            //   value={getURL()}
            //   disabled={true}
          />

          <span className="text-dark-gray self-start mt-[24px] text-[18px] font-inter-semibold">
            Delivery instructions
          </span>

          <button
            onClick={() => {
              setChangeDeliveryInstructions(true);
            }}
            className="text-catering self-start font-inter-medium text-sm"
          >
            Edit
          </button>

          <input
            required
            className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
            //   value={getURL()}
            //   disabled={true}
          />
        </>
      </div>

      {changeDeliveryTime && (
        <ChangeDeliveryTime
          visible={changeDeliveryTime}
          setModal={setChangeDeliveryTime}
        />
      )}

      {changeSubsidy && (
        <ChangeSubsidy visible={changeSubsidy} setModal={setChangeSubsidy} />
      )}

      {changeContact && (
        <ChangeContact visible={changeContact} setModal={setChangeContact} />
      )}

      {changeDeliveryInstructions && (
        <ChangeDeliveryInstructions
          visible={changeDeliveryInstructions}
          setModal={setChangeDeliveryInstructions}
        />
      )}
    </div>
  );
}

export default RequestChangesToMeal;
