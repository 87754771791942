import React, { Fragment } from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import EmbedHTML from "components/shared/embedHTML";
import classNames from "classnames";
import { successIcon, dangerAlertIcon, warningIcon } from "assets/img";

function MissingPaymentMethodModal({
  visible,
  setModal,
  title,
  body,
  rightButtonText = "Ok",
  leftButtonText = "Cancel",
  onClickLeftButton,
  onClickRightButton,
  avoidClose,
  groupOrderAlert,
  hideCancel = false,
}) {
  const screenType = Screen.ScreenType();

  const closeModal = () => {
    setModal(null);
  };

  return (
    <Transition appear show={visible !== null} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={avoidClose ? () => {} : closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-5/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all rounded-2xl">
                {!avoidClose && (
                  <div className="flex justify-end items-center">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="btn-style"
                    >
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>
                )}

                <div className="flex flex-col mt-7 justify-center items-center">
                  <FontAwesomeIcon
                    style={{ width: 56, height: 56 }}
                    icon={faTriangleExclamation}
                    size="2x"
                    color="#BBC0C5"
                  />
                  <Dialog.Title
                    as="h3"
                    style={{ fontSize: screenType?.isTabletOrMobile ? 16 : 24 }}
                    className="font-inter-semibold text-center leading-6 text-[#2D3958] mt-[12px]"
                  >
                    Link Copied. Please Add a Payment Method to Complete the
                    Group Order.
                  </Dialog.Title>

                  <EmbedHTML
                    text={
                      "Your group order is now live, and the link has been copied to your clipboard. Please note, the order won’t be processed if a payment method isn’t added, and it will be cancelled once the deadline is reached. "
                    }
                    appendClass="flex"
                    className={classNames({
                      "text-[#2D3958] text-center mt-5": true,
                      "!mt-3": groupOrderAlert,
                    })}
                  />
                </div>

                <div
                  className={classNames({
                    "flex justify-center items-center mt-5": true,
                    "!mt-8": groupOrderAlert,
                  })}
                >
                  <div className="flex">
                    <button
                      className="btn-purple btn-style py-3 px-6 ml-3"
                      onClick={onClickRightButton}
                    >
                      Go to payment info page
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default MissingPaymentMethodModal;
