import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import {
  StartGroupOrderLargeStep1,
  StartGroupOrderLargeStep2,
  StartGroupOrderLargeStep3,
} from "assets/img";

const GroupOrderInstructions = () => {
  return (
    <>
      <div
        className="flex flex-col mx-auto p-5 md:p-[40px] sm:mt-[30px] md:mt-[20px]"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-col">
          <span className="text-[32px] font-inter-semibold">Group orders</span>

          <span className="mt-3 text-md">
            Perfect for meetings and events... Group Orders combine the peace of
            mind of a catered meal with the flexibility allowing invitees to
            select their meals
          </span>

          <Link href="/">
            <button className="btn-style text-md bg-hh-accent-light hover:bg-hh-accent-dark w-[204px] mt-6 text-white rounded-[32px] py-[10px] px-6">
              Start Ordering
            </button>
          </Link>

          <span className="mt-12 text-[24px] font-inter-semibold">
            How does it work?
          </span>

          <span className="text-black text-md mt-3 mb-6">
            You will have full visibility of all orders within a group order and
            can edit it along the way
          </span>

          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col sm:flex-row sm:items-center md:flex-col">
              <img
                src={StartGroupOrderLargeStep1}
                width={288}
                height={186}
                layout="fixed"
                alt="start-step-1"
              />

              <div className="flex flex-col sm:pl-8 md:pl-0">
                <span className="mt-4 text-md font-inter-semibold">Step 1</span>
                <span className="mt-3 text-md max-w-[300px]">
                  The restaurant of your choice - set a date, time, and per
                  person budget
                </span>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center md:flex-col md:mx-12 my-10 md:my-0">
              <img
                src={StartGroupOrderLargeStep2}
                width={288}
                height={186}
                layout="fixed"
                alt="start-step-2"
              />

              <div className="flex flex-col sm:pl-8 md:pl-0">
                <span className="mt-4 text-md font-inter-semibold">Step 2</span>
                <span className="mt-3 text-md max-w-[300px]">
                  Share a unique link with your invitees that allows them to
                  select and customize their items
                </span>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center md:flex-col">
              <img
                src={StartGroupOrderLargeStep3}
                width={288}
                height={186}
                layout="fixed"
                alt="start-step-3"
              />

              <div className="flex flex-col sm:pl-8 md:pl-0">
                <span className="mt-4 text-md font-inter-semibold">Step 3</span>
                <span className="mt-3 text-md max-w-[300px]">
                  Share a unique link with your invitees that allows them to
                  select and customize their items
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(GroupOrderInstructions);
