import { useState, useEffect } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";

const MyCalendar = ({
  showButtons,
  isMultiple,
  handleDatePicker,
  selectedValue,
  handleMultipleDates,
  value,
  disableMonthYearPicker,
  multipleValues,
}) => {
  const weekDaysArray = ["S", "M", "T", "W", "T", "F", "S"];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [date, setDate] = useState();
  const minSelectableDate = new Date();
  minSelectableDate?.setDate(minSelectableDate?.getDate() + 7);

  let initialState = [];

  if (isMultiple) {
    if (multipleValues) {
      const [currentMonth] = Object.keys(multipleValues);
      const selectedDates = multipleValues[currentMonth];

      initialState = selectedDates?.map((dateString) => {
        const [day, month, year] = dateString?.split("-")?.map(Number);
        const newDate = new DateObject();
        newDate?.setYear(year).setMonth(month).setDay(day);
        return newDate;
      });
    } else {
      const currentDate = new DateObject();
      const currentMonth = new Date().getMonth();

      initialState = [currentDate?.setMonth(currentMonth + value)];
    }
  }

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow?.setDate(tomorrow?.getDate() + 6);
    setDate(selectedValue ? new Date(selectedValue) : tomorrow);
  }, []);

  const handleDateChange = (newDate) => {
    isMultiple ? handleMultipleDates(newDate) : handleDatePicker(newDate);
  };

  return (
    <>
      {!isMultiple ? (
        <Calendar
          highlightToday={true}
          value={date}
          onChange={handleDateChange}
          buttons={showButtons}
          weekDays={weekDaysArray}
          months={months}
          numberOfMonths={3}
          disableYearPicker={disableMonthYearPicker}
          disableMonthPicker={disableMonthYearPicker}
          multiple={false}
          monthYearSeparator={" "}
          minDate={minSelectableDate}
        />
      ) : (
        <Calendar
          highlightToday={true}
          value={initialState}
          onChange={handleDateChange}
          buttons={showButtons}
          weekDays={weekDaysArray}
          months={months}
          disableYearPicker={disableMonthYearPicker}
          disableMonthPicker={disableMonthYearPicker}
          multiple={true}
          monthYearSeparator={" "}
          minDate={minSelectableDate}
        />
      )}
    </>
  );
};

export default MyCalendar;
