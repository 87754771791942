import React, { useState, useEffect, useContext } from "react";
import Input from "components/input";
import TimePicker from "components/timepicker";
import MealSeries from "components/mealSeries";
import ActiveStepIndicator from "components/shared/activeStepIndicator";
import {
  mealTimings,
  defaultMealTimePickerValue,
} from "utils/constants/onboardingStep";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import classNames from "classnames";

const Step2 = ({ disableButton }) => {
  const store = useContext(MobXProviderContext);
  const onboardingStore = toJS(store?.onboardingStore);
  const { selectedMealArray } = onboardingStore;

  const [selectedMeal, setSelectedMealArray] = useState(
    selectedMealArray || {}
  );
  const [showTimePicker, setShowTimePicker] = useState(
    selectedMealArray?.isPredefinedTime == false
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    const enable =
      Object.keys(selectedMeal).length > 0 &&
      selectedMeal?.meal_name !== "" &&
      selectedMeal?.meal !== "" &&
      selectedMeal?.target_time !== "";

    disableButton(!enable);

    store.onboardingStore.handleMealSeriesData(selectedMeal);
  }, [selectedMeal]);

  const handleOtherMealName = (name) => {
    const newMealObject = {
      meal_name: name,
      meal: "other",
      target_time: "",
      isPredefinedTime: false,
    };

    setSelectedMealArray(newMealObject);
  };

  const handleMealChange = (meal) => {
    setShowTimePicker(false);
    const newMealObject = {
      meal_name: meal !== "other" ? meal : "",
      meal: meal,
      target_time: "",
      isPredefinedTime: true,
    };

    setSelectedMealArray(newMealObject);
  };

  const handleTimeInput = (time, isPredefined) => {
    // setShowTimePicker(!isPredefined);

    setSelectedMealArray((prevMealArray) => ({
      ...prevMealArray,
      target_time: time,
      isPredefinedTime: isPredefined,
    }));
  };

  return (
    <div className="pb-4 mt-4">
      <ActiveStepIndicator step={2} />

      <div className="flex-col flex gap-6">
        <div className="screen-xs:w-full-235">
          <p className="expected-bag-text font-inter-semibold">
            What meals are you looking to bring to your team?{" "}
          </p>
        </div>
      </div>

      <div className="py-4 gap-6 screen-lg:flex screen-md:flex screen-sm:flex-col">
        <MealSeries
          handleMealChange={handleMealChange}
          mealSelected={selectedMeal}
        />
      </div>

      {selectedMeal?.meal !== "other" &&
        Object.keys(selectedMeal)?.length !== 0 && (
          <>
            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              What is the ideal time for your {selectedMeal?.meal} delivery?
            </div>

            <div className="gap-3 my-6 screen-lg:flex screen-md:flex screen-sm:flex-col space-y-4 screen-lg:space-y-0 screen-md:space-y-0">
              {mealTimings[selectedMeal?.meal].map((time, index) => (
                <div
                  key={index}
                  className={classNames(
                    "w-full screen-lg:w-24 screen-md:w-24 h-[52px] rounded-md justify-center items-center flex hover:cursor-pointer font-inter-medium leading-tight",
                    {
                      "bg-indigo-500 text-white":
                        selectedMeal?.target_time == time &&
                        selectedMeal?.isPredefinedTime,
                      "text-slate-700 bg-slate-50 hover:bg-indigo-500 hover:text-white":
                        !(
                          selectedMeal?.target_time == time &&
                          selectedMeal?.isPredefinedTime
                        ),
                    }
                  )}
                  onClick={() => {
                    handleTimeInput(time, true);
                    setShowTimePicker(false);
                  }}
                >
                  {time}
                </div>
              ))}

              <div
                className="w-full screen-lg:w-36 screen-md:w-36 h-[52px] bg-slate-50 rounded-md justify-center items-center font-inter-medium text-indigo-500 flex hover:cursor-pointer"
                onClick={() => {
                  setShowTimePicker(true);
                  setSelectedMealArray((prevMealArray) => ({
                    ...prevMealArray,
                    isPredefinedTime: false,
                  }));
                }}
              >
                {showTimePicker ? (
                  <TimePicker
                    ideal_time={selectedMeal?.target_time}
                    datetime={handleTimeInput}
                    meal_name={selectedMeal?.meal}
                    handleShowModal={true}
                  />
                ) : (
                  "Select Your Time"
                )}
              </div>
            </div>
          </>
        )}

      {selectedMeal?.meal === "other" && (
        <div className="w-full mb-6">
          <Input
            text={`What would you like to name this meal?*`}
            placeholder={"ex. Town Hall Dinner"}
            otherMealName={handleOtherMealName}
            mealName={selectedMeal?.meal_name}
            isCompulsary={true}
          />
        </div>
      )}

      {selectedMeal?.meal_name !== "" && selectedMeal?.meal === "other" && (
        <>
          <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
            What is the ideal time for your {selectedMeal?.meal_name} delivery?
          </div>

          <div className="w-full screen-lg:w-32 screen-md:w-32 my-6 flex bg-slate-50 rounded-md font-inter-medium">
            <TimePicker
              ideal_time={selectedMeal?.target_time}
              datetime={handleTimeInput}
              meal_name="other"
              handleShowModal={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Step2;
