import React, { useState, useEffect } from "react";
import Picker from "react-scrollable-picker";
import Modal from "react-modal";

const IosPicker = ({
  openModal,
  timeValue,
  onTimeSelected,
  mealName,
  handleDivClick,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [time, amPmVal] = timeValue.split(" ");
  const [hour, minute] = time?.split(":");

  const [valueGroups, setValueGroups] = useState({
    hour: hour || "09",
    minute: minute || "15",
    amPm: amPmVal || "am",
  });

  const customStyles = {
    content: {
      width: "100%",
      height: "200px",
      background: "white",
      border: "none",
      padding: "0",
      position: "none",
      overflow: "hidden",
      borderRadius: "0px",
      fontColor: "#16191C",
    },
    overlay: {
      backgroundColor: "#16161678",
    },
  };

  const hours = Array.from(Array(12), (_, index) => String(index + 1));
  const minutes = Array.from(Array(59), (_, index) => String(index));
  const amPm = ["am", "pm"];

  const optionGroups = {
    hour: hours.map((hour) => ({ value: hour, label: hour })),
    minute: minutes.map((minute) => ({ value: minute, label: minute })),
    amPm: amPm.map((period) => ({
      value: period,
      label: period.toUpperCase(),
    })),
  };

  const handleChange = (name, value) => {
    setValueGroups((prevValueGroups) => ({
      ...prevValueGroups,
      [name]: value,
    }));
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  useEffect(() => {
    const timeVal = `${valueGroups.hour}:${
      valueGroups.minute
    } ${valueGroups.amPm.toLowerCase()}`;

    onTimeSelected(timeVal, mealName);
  }, [valueGroups]);

  const handleClick = () => {
    setShowModal(true);
    handleDivClick(true);
  };

  return (
    <>
      <div className="flex justify-between w-full" onClick={handleClick}>
        <div className="w-2/3 pl-6 text-slate-700 bg-transparent flex items-center ">
          {`${
            valueGroups.hour < 10 && valueGroups.hour !== "00"
              ? `0${valueGroups.hour}`
              : valueGroups.hour
          } : ${
            valueGroups.minute < 10 && valueGroups.minute !== "00"
              ? `0${valueGroups.minute}`
              : valueGroups.minute
          }`}{" "}
        </div>

        <div
          className={`w-[49px] h-[52px] bg-indigo-500 flex items-center justify-center rounded-r-md`}
        >
          <span className="text-white font-inter-medium">
            {amPmVal.toUpperCase()}
          </span>
        </div>
      </div>

      {showModal && (
        <Modal style={customStyles} isOpen={openModal}>
          <Picker
            optionGroups={optionGroups}
            valueGroups={valueGroups}
            onChange={handleChange}
          />
        </Modal>
      )}
    </>
  );
};

export default IosPicker;
