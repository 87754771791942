import dayjs from "dayjs";
import "dayjs/locale/en"; // import locale if needed
dayjs.locale("en"); // use locale

// Function to generate days for calendar view
export const generateCalendarDays = (year, month) => {
  const startOfMonth = dayjs(new Date(year, month, 1));
  const endOfMonth = startOfMonth.endOf("month");
  const today = dayjs(); // Get today's date for comparison

  let days = [];
  // Calculate days needed from previous month to start the calendar week
  const daysFromPrevMonth = startOfMonth.day(); // Sunday as 0, Saturday as 6

  // Add days from previous month
  for (let i = daysFromPrevMonth - 1; i >= 0; i--) {
    const day = startOfMonth.subtract(i + 1, "day");
    days.push({ date: day.format("YYYY-MM-DD"), events: [] });
  }

  // Add days of current month and check for today
  for (
    let day = startOfMonth;
    day.isBefore(endOfMonth) || day.isSame(endOfMonth, "day");
    day = day.add(1, "day")
  ) {
    const dayObj = {
      date: day.format("YYYY-MM-DD"),
      isCurrentMonth: true,
      events: [
        {
          id: 2,
          name: "Sales meeting",
          time: "2PM",
          datetime: "2022-01-03T14:00",
          href: "#",
        },
      ],
    };
    // Check if this day is 'today'
    if (day.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
      dayObj.isToday = true;
    }
    days.push(dayObj);
  }

  // Calculate days needed for next month to complete the calendar view
  let daysForNextMonth = 6 - endOfMonth.day(); // To complete the week, Sunday as 0, Saturday as 6

  // Add days for next month
  for (let i = 1; i <= daysForNextMonth; i++) {
    const day = endOfMonth.add(i, "day");
    days.push({ date: day.format("YYYY-MM-DD") });
  }

  return days;
};

// // Example: Generate days for January 2022
// const calendarDays = generateCalendarDays(2022, 0); // Month is 0-indexed (0 for January, 1 for February, etc.)
// console.log(calendarDays);
